<h5 mat-dialog-title class="pb-16">{{'Cancel'|translate}} {{payloadData.type}}</h5>
<div *ngIf="checkFlag; else shipmentCancellationMessage" class="flexDisplay flexColumn flexWrap mt-24">
    <h6 *ngIf="!eligiblityFlag" style="color: red; font-size: small; font-weight: bolder;">{{'Not Eligible'}}</h6>
    <h6 *ngIf="eligiblityFlag" style="color: green; font-size: small; font-weight: bolder;">{{'Eligible'}}</h6>
    <span *ngIf="!eligiblityFlag" style="color: red; font-size: small;">{{eligibilityMsg}}</span>
    <span *ngIf="eligiblityFlag">{{'CancelShipmentMessage' | translate}}</span>
    <ul>
        <li *ngFor="let item of payloadData.data">
            {{item}}
        </li>
    </ul>
 
</div>


<div mat-dialog-actions class="mt-24">
    <button mat-button (click)="onNoClick()">Close</button>
    <button *ngIf="checkFlag && payloadData.type =='shipment'" mat-button (click)="cancelShipment()" cdkFocusInitial>{{!eligiblityFlag ? 'Cancel Anyways' : 'Cancel'}}</button>
    <button *ngIf="checkFlag && payloadData.type =='workOrder'" mat-button (click)="cancelWorkOrder()" cdkFocusInitial>{{!eligiblityFlag ? 'Cancel Anyways' : 'Cancel'}}</button>

</div>


<ng-template #shipmentCancellationMessage>
    <span>Checking Cancellation eligibility...</span>
</ng-template>