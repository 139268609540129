import { Component, OnDestroy, OnInit } from "@angular/core";
import { HttpService } from "../http.service";
import { NotifierService } from "angular-notifier";
import { NgxSpinnerService } from "ngx-spinner";
import { MatSnackBar } from "@angular/material/snack-bar";
import { Subscription } from "rxjs";

interface printCart {
  value: string;
  viewValue: string;
}

@Component({
  selector: "app-custom-goods",
  templateUrl: "./custom-goods.component.html",
  styleUrls: ["./custom-goods.component.scss"],
})
export class CustomGoodsComponent implements OnInit, OnDestroy {
  shipments: printCart[] = [
    { value: "1", viewValue: "1" },
    { value: "2", viewValue: "2" },
    { value: "3", viewValue: "3" },
    { value: "4", viewValue: "4" },
    { value: "5", viewValue: "5" },
    { value: "6", viewValue: "6" },
    { value: "7", viewValue: "7" },
    { value: "8", viewValue: "8" },
    { value: "9", viewValue: "9" },
    { value: "10", viewValue: "10" },
  ];

  count = 0;
  selectedPrinter: string = "";
  selectedCart: string = "";
  printers: any = [];
  displayedColumns: string[] = [
    "title",
    "shipment",
    "workOrder",
    "clubsQueued",
    "bending",
    "lowQty",
    "stress",
  ];
  dataSource: any = [];
  printData: any;
  isLoading = true;
  isPrinting: boolean;
  printSubscription: Subscription;

  private readonly notifier: NotifierService;

  constructor(
    private _http: HttpService,
    notifierService: NotifierService,
    private _snackBar: MatSnackBar,
    private SpinnerService: NgxSpinnerService
  ) {
    this.notifier = notifierService;
    this.printData = {};
  }

  // @HostListener("window:beforeunload", ["$event"])
  // beforeunloadHandler(evt: BeforeUnloadEvent) {
  //   if (this.isPrinting) {
  //     const msg = "Do you really want to close?";
  //     evt.preventDefault();
  //     evt.returnValue = msg;
  //     return (evt.returnValue = msg);
  //   }
  // }

  ngOnInit() {
    this.notifier.hideNewest();
    this.SpinnerService.show();
    this.getPrinters();

    this._http.getPrintTable().subscribe(
      (data) => {
        this.printData = JSON.parse(JSON.stringify(data));
        this.dataSource = this.printData.list;
        this.SpinnerService.hide();
        if (
          this.printData.woNotPrinted > 0.51 * this.printData.optimalValue &&
          0.75 * this.printData.optimalValue > this.printData.woNotPrinted
        ) {
          this.openWarningMessage();
        } else if (
          this.printData.woNotPrinted <
          0.5 * this.printData.optimalValue
        ) {
          this.openCriticalMessage();
        }
      },
      (err) => {
        this.SpinnerService.hide();
        this.notifier.notify(
          "error",
          "There was a server error. Please try later!"
        );
      }
    );
  }

  getPrinters() {
    this._http.getPrintersDropdownData().subscribe(
      (results) => {
        this.printers = results;
      },
      (err) => {
        this.notifier.notify(
          "error",
          "There was a server error. Please try later!"
        );
      }
    );
  }

  hideNotification() {
    this.notifier.hideNewest();
  }

  printSingleShipment() {
    if (this.selectedPrinter === "") {
      this.notifier.notify("warning", "Please select a Printer");
    } else {
      let payload = {
        quantity: 1,
        viaWarePrinter: this.selectedPrinter,
      };

      this.SpinnerService.show();
      this._http.printSingleShipment(payload).subscribe(
        (results) => {
          let obj = JSON.parse(JSON.stringify(results));
          if (obj.message === "Success") {
            this.SpinnerService.hide();
            this.notifier.notify(
              "success",
              "Cart has been successfully printed!"
            );
          } else {
            this.SpinnerService.hide();
            this.notifier.notify("error", obj.message);
          }

          this._http.getPrintTable().subscribe(
            (data) => {
              this.printData = [];
              this.printData = JSON.parse(JSON.stringify(data));
              this.dataSource = [];
              this.dataSource = this.printData.list;
            },
            (err) => {
              this.notifier.notify(
                "error",
                "There was a server error. Please try later!"
              );
            }
          );
        },
        (err) => {
          this.SpinnerService.hide();
          this.notifier.notify(
            "error",
            "There was a server error. Please try later!"
          );
        }
      );

      this._http.getPrintTable().subscribe(
        (data) => {
          this.printData = [];
          this.printData = JSON.parse(JSON.stringify(data));
          this.dataSource = [];
          this.dataSource = this.printData.list;
        },
        (err) => {
          this.notifier.notify(
            "error",
            "There was a server error. Please try later!"
          );
        }
      );
    }
  }

  printCart() {
    if (this.selectedCart === "" || this.selectedPrinter === "") {
      this.notifier.notify("warning", "Please select Printer and Print Cart");
    } else {
      let payload = {
        quantity: parseInt(this.selectedCart),
        viaWarePrinter: this.selectedPrinter,
      };
      this.setIsPrinting(true);
      this.SpinnerService.show();
      this.printSubscription = this._http.printCart(payload).subscribe(
        (results) => {
          this.SpinnerService.hide();
          this.setIsPrinting(false);
          let obj = JSON.parse(JSON.stringify(results));
          if (obj.message === "Success") {
            this.notifier.notify(
              "success",
              "Cart has been successfully printed!"
            );

            this._http.getPrintTable().subscribe(
              (data) => {
                this.printData = [];
                this.printData = JSON.parse(JSON.stringify(data));
                this.dataSource = [];
                this.dataSource = this.printData.list;
              },
              (err) => {
                this.notifier.notify(
                  "error",
                  "There was a server error. Please try later!"
                );
              }
            );
          } else {
            this.notifier.notify("error", obj.message);
            this._http.getPrintTable().subscribe(
              (data) => {
                this.printData = [];
                this.printData = JSON.parse(JSON.stringify(data));
                this.dataSource = [];
                this.dataSource = this.printData.list;
              },
              (err) => {
                this.notifier.notify(
                  "error",
                  "There was a server error. Please try later!"
                );
              }
            );
          }
        },
        (err) => {
          this.SpinnerService.hide();
          this.setIsPrinting(false);
          this.notifier.notify(
            "error",
            "There was a server error. Please try later!"
          );
        }
      );

      //refresh table data on success
      this._http.getPrintTable().subscribe(
        (data) => {
          this.printData = [];
          this.printData = JSON.parse(JSON.stringify(data));
          this.dataSource = [];
          this.dataSource = this.printData.list;
        },
        (err) => {
          this.notifier.notify(
            "error",
            "There was a server error. Please try later!"
          );
        }
      );
    }
  }

  private setIsPrinting(val: boolean) {
    this.isPrinting = val;
  }

  totalClubs(element) {
    return element.clubsQueued + element.clubsInCell;
  }

  calculateBendingRatio(element) {
    return (element.clubsBending / this.totalClubs(element)) * 100;
  }

  calculateStressLevel(element) {
    return element.clubsQueued;
  }

  openWarningMessage() {
    this._snackBar.open(
      "The Queue is Running Low... Please Release More Work !!!",
      "Close",
      {
        duration: 5000,
        horizontalPosition: "center",
        verticalPosition: "bottom",
        panelClass: ["warning-message"],
      }
    );
  }

  openCriticalMessage() {
    this._snackBar.open(
      "The Queue is Running Low... Please Release More Work !!!",
      "Close",
      {
        horizontalPosition: "center",
        verticalPosition: "bottom",
        panelClass: ["critical-message"],
      }
    );
  }

  onCloseSpinner() {
    this.exeDesPrintSession();
    if (this.printSubscription) {
      this.printSubscription.unsubscribe();
    }
    this.SpinnerService.hide();
    if (this.isPrinting) {
      this.setIsPrinting(false);
    }
  }

  private exeDesPrintSession() {
    this._http.desActivatePrintSession().subscribe();
  }

  ngOnDestroy(): void {
    if (this.isPrinting) {
      this.exeDesPrintSession();
    }
    if (this.printSubscription) {
      this.printSubscription.unsubscribe();
    }
  }
}
