
<div class="flexDisplay loginPage">
    
    <form [formGroup] = "loginForm">
    <mat-card class="flexDisplay flexColumn p-24 custom-light-popup loginCard">
        <img src="../../assets/images/tm.png" width="288">
        <div class="mt-32" style="color: #9e9e9e">{{'LoginMessage'| translate}}</div>
        <mat-form-field class="example-full-width mt-24">
            <mat-label>{{'Email'|translate}}</mat-label>
            <input matInput formControlName="emailFormControl" [errorStateMatcher]="matcher"
                placeholder="Ex. pat@example.com">
            <mat-error *ngIf="emailFormControl.hasError('email') && !emailFormControl.hasError('required')">
                {{'EmailError'|translate}}
            </mat-error>
            <mat-error *ngIf="emailFormControl.hasError('required')">
                Email is <strong>required</strong>
            </mat-error>
        </mat-form-field>

        <mat-form-field class="example-full-width mt-24">
            <mat-label>{{'Password'|translate}}</mat-label>
            <input matInput formControlName="passwordFormControl" type="password">
        </mat-form-field>

        <button class="mt-16 alignSelfCenter fr" color="primary" mat-flat-button (click)="login()">
            {{'Login'|translate}}
        </button>
    </mat-card>
</form>
</div>