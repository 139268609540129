import { Component, OnInit } from '@angular/core';
import { HttpService } from '../../http.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-public-layout',
  template: `
    <router-outlet></router-outlet>  
  `,
  styles: []
})
export class PublicLayoutComponent implements OnInit {

  constructor(
    private _http: HttpService,
    private router: Router
  ) {
    let isLoggedIn = this._http.isLoggedIn;
    if(isLoggedIn.value){
      this.router.navigate(['/line-management'])
    }

  }

  ngOnInit(): void {
  }

}
