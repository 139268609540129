import { Component, OnInit } from '@angular/core';
import { HttpService } from '../http.service';
import { NotifierService } from "angular-notifier";
import { NgxSpinnerService } from "ngx-spinner";
import { ActivatedRoute } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';


import { MatSnackBar } from '@angular/material/snack-bar';

interface printCart {
  value: string;
  viewValue: string;
}

@Component({
  selector: 'app-finished-goods-v2',
  templateUrl: './finished-goods-v2.component.html',
  styleUrls: ['./finished-goods-v2.component.scss']
})
export class FinishedGoodsV2Component implements OnInit {

  pickZone: any = [];
  zoneNumbers: any = [];

  containers: printCart[] = [
    { value: '1', viewValue: '1' },
    { value: '5', viewValue: '5' },
    { value: '25', viewValue: '25' },
    { value: '50', viewValue: '50' },
    { value: '100', viewValue: '100' },

  ];

  count = 0;
  selectedPrinter: string = '';
  selectedZone: string = 'All';
  selectedZoneNumber: any = [];
  selectedContainer: string = '';
  printers: any = [];
  displayedColumns: string[] = ['title', 'FinishedGoods'];
  dataSource: any = [];
  printData: any;
  isAutoRelease = false;
  isLoading = true;
  selectedLang: string = '';


  private readonly notifier: NotifierService;

  constructor(
    private _http: HttpService,
    notifierService: NotifierService,
    private SpinnerService: NgxSpinnerService,
    public translate: TranslateService,
    private _snackBar: MatSnackBar,
    private route: ActivatedRoute,
  ) {
    this.notifier = notifierService;
    this.printData = {};
    translate.addLangs(['en', 'es', 'ja']);
    translate.setDefaultLang('en');

  }

  ngOnInit() {

    this.selectedLang = this.route.snapshot.queryParams['lang'];
    // console.log(this.route.snapshot.queryParams['lang']);
    
    if(this.selectedLang !== undefined){
      this.translate.use(this.selectedLang)
    }

    this.notifier.hideNewest();
    this.SpinnerService.show();
    this.getPrinters();
    this.getPickZones();
    this.getZoneNumbers();

    
    this._http.getFinishedGoods().subscribe(
      (data) => {
        this.printData = JSON.parse(JSON.stringify(data));
        this.dataSource = this.printData.cells;
        this.SpinnerService.hide();
        if(this.printData.notPrintedCount > 0.51 * this.printData.wipLimit && 0.75 * this.printData.wipLimit > 0.75 * this.printData.notPrintedCount){
          this.openWarningMessage();
        }
        else if(this.printData.notPrintedCount < 0.50 * this.printData.wipLimit){
          this.openCriticalMessage();
        }
      },
      err=>{
        this.SpinnerService.hide();
        this.notifier.notify('error', 'There was a server error. Please try later!')
      },
    );
  }

  getPrinters() {
    this._http.getPrintersDropdownData().subscribe((results) => {
      this.printers = results;
    },
    err=>{
      this.notifier.notify('error', 'There was a server error. Please try later!')
    },
    );
  }

  hideNotification(){
    this.notifier.hideNewest();
  }

  getPickZones(){
    this._http.getPickZones().subscribe((results)=>{
      this.pickZone = results;
    })
  }

  getZoneNumbers(){
    this._http.getPickZoneNumbers().subscribe((results)=>{
      this.zoneNumbers =  results;
      console.log(results);
    })
  }

  printFGCart() {

    if(this.selectedZone === 'All')
    {
      this.selectedZoneNumber = [];
    }

    if(this.selectedZone === 'All' && (this.selectedPrinter === '' || this.selectedContainer === ''))
    {
      this.notifier.notify("warning", "Please select all required values");
    }else if(this.selectedZone !== 'All' && (this.selectedPrinter === '' || this.selectedZoneNumber.length === 0 || this.selectedContainer === ''))
    {
      this.notifier.notify("warning", "Please select all required values");
    }else {

      let payload = {
        typeZone: this.selectedZone,
        zoneNumber: this.selectedZoneNumber,
        viaWarePrinter: this.selectedPrinter,
        containers: parseInt(this.selectedContainer)
      }

      this.SpinnerService.show();
      this._http.printFinishedGoods(payload).subscribe((results) => {
        let obj = JSON.parse(JSON.stringify(results));

        if (obj.message === 'Success') {
          this.SpinnerService.hide();
          this.notifier.notify("success", "Cart has been successfully printed!");
        }

        else {
          this.SpinnerService.hide();
          this.notifier.notify("error", obj.message);
        }

        this._http.getFinishedGoods().subscribe(
          (data) => {
            this.printData = JSON.parse(JSON.stringify(data));
            this.dataSource = this.printData.cells;
          },
          err=>{
            this.SpinnerService.hide();
            this.notifier.notify('error', 'There was a server error. Please try later!')
          });
      },
      err=>{
        this.SpinnerService.hide();
        this.notifier.notify('error', 'There was a server error. Please try later!')
      });

      this._http.getFinishedGoods().subscribe(
        (data) => {
          this.printData = JSON.parse(JSON.stringify(data));
          this.dataSource = this.printData.cells;
        },
        err=>{
          this.SpinnerService.hide();
          this.notifier.notify('error', 'There was a server error. Please try later!')
        });

    }
  }


  printSingleShipment(){
    if(this.selectedZone === 'All')
    {
      this.selectedZoneNumber = [];
    }
    if(this.selectedZone === 'All' && (this.selectedPrinter === '' || this.selectedContainer === ''))
    {
      this.notifier.notify("warning", "Please select all required values");
    }else if(this.selectedZone !== 'All' && (this.selectedPrinter === '' || this.selectedZoneNumber.length === 0 || this.selectedContainer === ''))
    {
      this.notifier.notify("warning", "Please select all required values");
    }else{
      let payload = {
        typeZone: this.selectedZone,
        zoneNumber: this.selectedZoneNumber,
        viaWarePrinter: this.selectedPrinter,
        containers: parseInt("1")
      }
      this.SpinnerService.show();
      this._http.printFinishedGoods(payload).subscribe((results) => {
        let obj = JSON.parse(JSON.stringify(results));

        if (obj.message === 'Success') {
          this.SpinnerService.hide();
          this.notifier.notify("success", "Cart has been successfully printed!");
        }

        else {
          this.SpinnerService.hide();
          this.notifier.notify("error", obj.message);
        }

        this._http.getFinishedGoods().subscribe(
          (data) => {
            this.printData = JSON.parse(JSON.stringify(data));
            this.dataSource = this.printData.cells;
          },
          err=>{
            this.SpinnerService.hide();
            this.notifier.notify('error', 'There was a server error. Please try later!')
          });
      },
      err=>{
        this.SpinnerService.hide();
        this.notifier.notify('error', 'There was a server error. Please try later!')
      });

      this._http.getFinishedGoods().subscribe(
        (data) => {
          this.printData = JSON.parse(JSON.stringify(data));
          this.dataSource = this.printData.cells;
        },
        err=>{
          this.SpinnerService.hide();
          this.notifier.notify('error', 'There was a server error. Please try later!')
        });
    }
  }

  openWarningMessage() {
    this._snackBar.open('The Queue is Running Low... Please Release More Work !!!', 'Close', {
      duration: 5000,
      horizontalPosition: 'center',
      verticalPosition: 'bottom',
      panelClass: ['warning-message']
    });
  }

  openCriticalMessage() {
    this._snackBar.open('The Queue is Running Low... Please Release More Work !!!', 'Close', {
      
      horizontalPosition: 'center',
      verticalPosition: 'bottom',
      panelClass: ['critical-message']
    });
  }

}
