import { Component, OnInit, Inject } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { FormBuilder, FormArray, FormGroup } from '@angular/forms';
import { HttpService } from '../http.service';
import { Overlay } from '@angular/cdk/overlay';
import { NotifierService } from "angular-notifier";
import { isNull } from '@angular/compiler/src/output/output_ast';
import { NgxSpinnerService } from 'ngx-spinner';
import { stringify } from '@angular/compiler/src/util';

@Component({
  selector: 'app-account-rule-create-popup',
  templateUrl: './account-rule-create-popup.component.html',
  styleUrls: ['./account-rule-create-popup.component.scss']
})
export class AccountRuleCreatePopupComponent implements OnInit {

  private readonly notifier: NotifierService;
  parsedData: any;
  shipTo: string = '';
  labelType: string = '';
  storeName: string = '';
  storeCode: string = '';
  labelFormat: string = '';
  specComment: string = '';
  labelFormatFG: string = '';
  labelFormatHeader: string = '';
  labelFormatReg: string = '';
  obj: any;



 
  constructor(private fb: FormBuilder,
    private _http: HttpService,
    notifierService: NotifierService,
    private SpinnerService: NgxSpinnerService,
    public dialogRef: MatDialogRef<AccountRuleCreatePopupComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any) { 
      this.notifier = notifierService;
      this.parsedData = data;
  }

  ngOnInit(): void {
  }
  saveLineSetupValues(){

    let payload = {
      "shiP_TO": this.shipTo,
      "labeL_TYPE": this.labelType,
      "storE_NAME": this.storeName,
      "storE_CODE": this.storeCode,
      "labeL_FORMAT": this.labelFormat,
      "specificatioN_CMT": this.specComment,
      "labeL_FORMAT_FG": this.labelFormatFG,
      "labeL_FORMAT_HEADER": this.labelFormatHeader,
      "labeL_FORMAT_REG": this.labelFormatReg
    }
    


      if (this.shipTo !== "" &&
          this.labelType !== "" &&
          this.storeName !== "" &&
          this.storeCode !== "" &&
          this.labelFormat !== "" &&
          this.specComment !== "" &&
          this.labelFormatFG !== "" &&
          this.labelFormatHeader !== "" &&
          this.labelFormatReg !== ""
      ){
        this.SpinnerService.show();
        this._http.createAccountRule(payload).subscribe((results)=>{
          let obj = JSON.parse(JSON.stringify(results));
      if(obj.message == "The record was saved succesfully"){
         this.notifier.notify('success', 'Account Rule has been created successfully!');
         this.SpinnerService.hide();
         this.dialogRef.close;
        }else if (obj.message == "The record already exists"){
          this.notifier.notify('warning', 'Account Rule already exists!');
         this.SpinnerService.hide();
        }
      },
      err => {
        this.SpinnerService.hide();
        this.notifier.notify('error', 'There was a server error. Please try later!')
        this.dialogRef.close;

      });
    }
    else{
      this.notifier.notify('warning', `Account rule can't be left empty!`);
    } 
  }

  onNoClick(): void {
    this.dialogRef.close();
  }

}
