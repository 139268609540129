import { Component, OnInit, Inject } from '@angular/core';
import { HttpService } from '../http.service';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-specification-popup',
  templateUrl: './specification-popup.component.html',
  styleUrls: ['./specification-popup.component.scss']
})
export class SpecificationPopupComponent implements OnInit {

  specifications:any = [];
  constructor(
    private _http: HttpService,
    public dialogRef: MatDialogRef<SpecificationPopupComponent>,
    @Inject(MAT_DIALOG_DATA) public workorder: any) {
      this.getWorkOrderSpecifications(workorder)
    }
   

  ngOnInit(): void {
  }

  onNoClick(): void {
    this.dialogRef.close();
  }

  getWorkOrderSpecifications(workorder){
    this._http.getWOSpecifications(workorder).subscribe((results)=>{
      this.specifications = results;
    })

  }

}
