<div class="mt-32">
  <div class="boldFont fs-app-18">
    {{ "FinishedGoods" | translate }}
  </div>
  <div class="flexDisplay flexRow mt-24">
    <mat-form-field class="mr-24">
      <mat-label>{{ "Optimal WIP" | translate }}</mat-label>
      <input
        type="number"
        matInput
        placeholder="Ex. 1"
        [(ngModel)]="fgOptimalValue"
      />
    </mat-form-field>
    <mat-form-field class="mr-24">
      <mat-label>{{ "Daily Release Qty" | translate }}</mat-label>
      <input
        type="number"
        matInput
        placeholder="Ex. 1"
        [(ngModel)]="fgReleaseQty"
      />
    </mat-form-field>

    <mat-form-field class="mr-24">
      <mat-label>{{ "Daily Buffer" | translate }}</mat-label>
      <input
        type="number"
        matInput
        placeholder="Ex. 1"
        [(ngModel)]="fgBuffer"
      />
    </mat-form-field>

    <mat-form-field class="mr-24">
      <mat-label>{{ "Daily Overflow" | translate }}</mat-label>
      <input
        type="number"
        matInput
        placeholder="Ex. 1"
        [(ngModel)]="fgOverflow"
      />
    </mat-form-field>

    <mat-form-field class="mr-24">
      <mat-label>{{ "Daily Low Qty Value" | translate }}</mat-label>
      <input
        type="number"
        matInput
        placeholder="Ex. 1"
        [(ngModel)]="fgLowQty"
      />
    </mat-form-field>
  </div>

  <div class="fs-app-18 mt-40 boldFont">
    {{ "Custom" | translate }} {{ "Goods" | translate }}
  </div>
  <div class="flexDisplay flexRow mt-24">
    <mat-form-field class="mr-24">
      <mat-label>{{ "Optimal" | translate }} WIP</mat-label>
      <input
        type="number"
        matInput
        placeholder="Ex. 1"
        [(ngModel)]="customOptimalValue"
      />
    </mat-form-field>
    <mat-form-field class="mr-24">
      <mat-label
        >{{ "Daily" | translate }} {{ "Release" | translate }} Qty.</mat-label
      >
      <input
        type="number"
        matInput
        placeholder="Ex. 1"
        [(ngModel)]="customReleaseQty"
      />
    </mat-form-field>
    <mat-form-field class="mr-24">
      <mat-label
        >{{ "Daily" | translate }} {{ "Buffer" | translate }}</mat-label
      >
      <input
        type="number"
        matInput
        placeholder="Ex. 1"
        [(ngModel)]="customBuffer"
      />
    </mat-form-field>

    <mat-form-field class="mr-24">
      <mat-label
        >{{ "Daily" | translate }} {{ "Overflow" | translate }}</mat-label
      >
      <input
        type="number"
        matInput
        placeholder="Ex. 1"
        [(ngModel)]="customOverflow"
      />
    </mat-form-field>
    <mat-form-field class="mr-24">
      <mat-label
        >{{ "Daily" | translate }} {{ "Low" | translate }} Qty.
        {{ "Value" | translate }}</mat-label
      >
      <input
        type="number"
        matInput
        placeholder="Ex. 1"
        [(ngModel)]="customLowQty"
      />
    </mat-form-field>
  </div>
  <br />
  <div class="boldFont mt-24 fs-app-18">
    {{ "Release" | translate }} {{ "Sequence" | translate }}
  </div>
  <div class="flexDisplay flexRow mt-24">
    <div class="w-20-p">
      <span id="example-radio-group-label" class="labelSettings"
        >{{ "Release" | translate }} {{ "Settings" | translate }}</span
      >
      <br />
      <mat-radio-group [(ngModel)]="releaseSettings">
        <mat-radio-button [value]="true">{{
          "Alternate" | translate
        }}</mat-radio-button>
        <mat-radio-button class="ml-16" [value]="false">{{
          "Fixed" | translate
        }}</mat-radio-button>
      </mat-radio-group>
    </div>

    <div class="w-20-p ml-24">
      <span id="example-radio-group-label" class="labelSettings">{{
        "Sort Only" | translate
      }}</span>
      <br />
      <mat-radio-group [(ngModel)]="removeAlternateLogic">
        <mat-radio-button [value]="true">{{
          "Yes" | translate
        }}</mat-radio-button>
        <mat-radio-button class="ml-16" [value]="false">{{
          "No" | translate
        }}</mat-radio-button>
      </mat-radio-group>
    </div>

    <div class="w-20-p ml-24">
      <span id="example-radio-group-label" class="labelSettings">{{
        "Ignore Bending" | translate
      }}</span>
      <br />
      <mat-radio-group [(ngModel)]="ignoreBending">
        <mat-radio-button [value]="true">{{
          "Yes" | translate
        }}</mat-radio-button>
        <mat-radio-button class="ml-16" [value]="false">{{
          "No" | translate
        }}</mat-radio-button>
      </mat-radio-group>
    </div>

    <div class="w-20-p FGRelease ml-24">
      <br />
      <mat-form-field style="width: 210px">
        <mat-label>{{ "FG Release Sequence" | translate }}</mat-label>
        <mat-select disableOptionCentering [(value)]="fgReleaseSequence">
          <mat-option value="container">Container</mat-option>
          <mat-option value="delivery">Delivery</mat-option>
        </mat-select>
      </mat-form-field>
    </div>
  </div>
  <!-- PLATINUM -->
  <div class="boldFont mt-24 fs-app-18">
    {{ "Platinum" | translate }}
  </div>
  <div *ngIf="platinumOnOrOff" class="fst-italic fs-app-13">
    {{ "PrinterAndLineSelectionAreMandatory" | translate }}
  </div>
  <div class="flexDisplay flexRow mt-8">
    <div class="mw-app-210">
      <span class="labelSettings">
        {{ "PlatinumActive" | translate }}
      </span>
      <br />
      <mat-radio-group [(ngModel)]="platinumOnOrOff">
        <mat-radio-button [value]="true">{{
          "On" | translate
        }}</mat-radio-button>
        <mat-radio-button class="ml-16" [value]="false">{{
          "Off" | translate
        }}</mat-radio-button>
      </mat-radio-group>
    </div>
    <div class="FGRelease ml-24">
      <br />
      <mat-form-field class="mw-app-210">
        <mat-label>{{ "PrinterSelection" | translate }}</mat-label>
        <mat-select [disabled]="!platinumOnOrOff" [(value)]="platinumPrinter">
          <mat-option *ngFor="let item of printers" [value]="item.id">
            {{ item.desc }}
          </mat-option>
        </mat-select>
      </mat-form-field>
    </div>
    <div class="FGRelease ml-24">
      <br />
      <mat-form-field class="mw-app-210">
        <mat-label>{{ "LineSelection" | translate }}</mat-label>
        <mat-select [disabled]="!platinumOnOrOff" [(value)]="platinumLine">
          <mat-option *ngFor="let item of lines" [value]="item.id">
            {{ item.title }}
          </mat-option>
        </mat-select>
      </mat-form-field>
    </div>
  </div>

  <div class="flexDisplay flexRow mt-24">
    <div>
      <br />
      <mat-form-field style="width: 210px">
        <mat-label>{{ "Sort" | translate }}</mat-label>
        <mat-select disableOptionCentering [(value)]="printSortId">
          <mat-option *ngFor="let item of printSort" [value]="item.id">
            {{ item.title }}
          </mat-option>
        </mat-select>
      </mat-form-field>
    </div>
    <div class="ml-24 mt-16">
      <span id="example-radio-group-label" class="labelSettings">{{
        "Order" | translate
      }}</span>
      <br />
      <mat-radio-group [(ngModel)]="sortOrder">
        <mat-radio-button [value]="true">{{
          "Ascending" | translate
        }}</mat-radio-button>
        <mat-radio-button class="ml-16" [value]="false">{{
          "Descending" | translate
        }}</mat-radio-button>
      </mat-radio-group>
    </div>
  </div>

  <div class="boldFont mt-24 fs-app-18">
    {{ "Configuration" | translate }} {{ "Options" | translate }}
  </div>
  <div class="flexDisplay flexRow mt-16">
    <div class="mr-8 w-35-p">
      <span id="example-radio-group-label" class="labelSettings"
        >{{ "wrapCompensation" | translate }} <br />{{
          "wrapInstructions" | translate
        }}</span
      >
      <br /><br />
      <mat-radio-group [(ngModel)]="swCalculation">
        <mat-radio-button [value]="true">{{
          "Yes" | translate
        }}</mat-radio-button>
        <mat-radio-button class="ml-16" [value]="false">{{
          "No" | translate
        }}</mat-radio-button>
      </mat-radio-group>
    </div>
    <div class="ml-30 mr-8 w-30-p">
      <span id="example-radio-group-label" class="labelSettings"
        >{{ "CuringTimerCheck" | translate }} <br />
        {{ "CuringTimes" | translate }}</span
      >
      <br /><br />
      <mat-radio-group [(ngModel)]="curingTimer">
        <mat-radio-button [value]="true">{{
          "Yes" | translate
        }}</mat-radio-button>
        <mat-radio-button class="ml-16" [value]="false">{{
          "No" | translate
        }}</mat-radio-button>
      </mat-radio-group>
    </div>
    <div class="ml-30 mr-8 w-30-p">
      <span id="example-radio-group-label" class="labelSettings">{{
        "qcCriteriaFull" | translate
      }}</span>
      <br /><br />
      <mat-radio-group [(ngModel)]="qcCriteriaFull">
        <mat-radio-button [value]="true">{{
          "Full" | translate
        }}</mat-radio-button>
        <mat-radio-button class="ml-16" [value]="false">{{
          "Reference" | translate
        }}</mat-radio-button>
      </mat-radio-group>
    </div>
  </div>
  <br />
  <div class="flexDisplay flexRow mt-16">
    <div class="mr-8 w-35-p">
      <span id="example-radio-group-label" class="labelSettings">
        {{ "wrapInstructionsqcCriteriaFull" | translate }} <br />
        {{ "wrapIns" | translate }}
      </span>
      <br /><br />
      <mat-radio-group [(ngModel)]="doneButton">
        <mat-radio-button [value]="false">{{
          "Yes" | translate
        }}</mat-radio-button>
        <mat-radio-button class="ml-16" [value]="true">{{
          "No" | translate
        }}</mat-radio-button>
      </mat-radio-group>
    </div>

    <div class="ml-30 mr-8 w-30-p">
      <span id="example-radio-group-label" class="labelSettings">
        {{ "displayPeriodicMaintenanceMessage" | translate }} <br />
        {{ "wrapIns" | translate }}
      </span>
      <br /><br />
      <mat-radio-group [(ngModel)]="maintenanceMessages">
        <mat-radio-button [value]="true">{{
          "Yes" | translate
        }}</mat-radio-button>
        <mat-radio-button class="ml-16" [value]="false">{{
          "No" | translate
        }}</mat-radio-button>
      </mat-radio-group>
    </div>

    <div class="ml-30 mr-8 w-30-p">
      <span id="example-radio-group-label" class="labelSettings">
        {{ "curringTimerStartingPoint" | translate }}
      </span>
      <br /><br />
      <mat-radio-group [(ngModel)]="isGlueStartingPoint">
        <mat-radio-button [value]="true">{{
          "glue" | translate
        }}</mat-radio-button>
        <mat-radio-button class="ml-16" [value]="false">{{
          "curing" | translate
        }}</mat-radio-button>
      </mat-radio-group>
    </div>
  </div>
  <br />

  <div class="boldFont mt-24 fs-app-18">
    {{ "AQL" | translate }}
  </div>
  <div class="flexDisplay flexRow">
    <mat-form-field class="mr-24 mt-8" style="width: 210px">
      <mat-label>{{ "General Inspection Levels" | translate }}</mat-label>
      <mat-select disableOptionCentering [(value)]="GInspectionLevel">
        <mat-option value="1">I</mat-option>
        <mat-option value="2">II</mat-option>
        <mat-option value="3">III</mat-option>
        <mat-option value="off">OFF</mat-option>
      </mat-select>
    </mat-form-field>
    <mat-form-field class="mr-24 mt-8" style="width: 210px">
      <mat-label>{{ "Acceptance Quality Limits" | translate }}</mat-label>
      <mat-select disableOptionCentering [(value)]="aql">
        <mat-option value="0.015">0.015</mat-option>
        <mat-option value="0.025">0.025</mat-option>
        <mat-option value="0.04">0.040</mat-option>
        <mat-option value="0.065">0.065</mat-option>
        <mat-option value="0.1">0.10</mat-option>
        <mat-option value="0.15">0.15</mat-option>
        <mat-option value="0.25">0.25</mat-option>
        <mat-option value="0.4">0.40</mat-option>
        <mat-option value="0.65">0.65</mat-option>
        <mat-option value="1">1.0</mat-option>
        <mat-option value="1.5">1.5</mat-option>
        <mat-option value="2.5">2.5</mat-option>
        <mat-option value="4">4.0</mat-option>
        <mat-option value="6.5">6.5</mat-option>
        <mat-option value="10">10</mat-option>
        <mat-option value="15">15</mat-option>
        <mat-option value="25">25</mat-option>
      </mat-select>
    </mat-form-field>
  </div>

  <div class="mt-40 boldFont fs-app-18">
    {{ "Site" | translate }} {{ "Settings" | translate }}
  </div>
  <div class="flexDisplay flexRow mt-24">
    <mat-form-field class="mr-24">
      <mat-label
        >{{ "Cart" | translate }} {{ "Quantity" | translate }}</mat-label
      >
      <input
        type="number"
        matInput
        placeholder="Ex. 1"
        [(ngModel)]="cartQuantity"
      />
    </mat-form-field>
    <mat-form-field class="mr-24">
      <mat-label
        >{{ "Sensor" | translate }} {{ "Retain" | translate }}
        {{ "Days" | translate }}</mat-label
      >
      <input
        type="number"
        matInput
        placeholder="Ex. 1"
        [(ngModel)]="sensorRetainDays"
      />
    </mat-form-field>
    <mat-form-field class="mr-24">
      <mat-label
        >{{ "Site" | translate }} {{ "Lead" | translate }}
        {{ "Time" | translate }}</mat-label
      >
      <input
        type="number"
        matInput
        placeholder="Ex. 1"
        [(ngModel)]="SiteLeadTime"
        max="30"
        min="1"
      />
    </mat-form-field>
  </div>

  <br />

  <div class="flexDisplay flexRow mt-24">
    <mat-form-field class="mr-24">
      <mat-label>{{ "Club  Qty Logic" | translate }}</mat-label>
      <mat-select disableOptionCentering [(value)]="clubQtyValue">
        <mat-option *ngFor="let item of clubQtyLogic" [value]="item.value">{{
          item.viewValue
        }}</mat-option>
      </mat-select>
    </mat-form-field>
    <mat-form-field class="mr-24">
      <mat-label
        >{{ "CLubs" | translate }} {{ "Max" | translate }}
        {{ "Qty" | translate }}</mat-label
      >
      <input
        type="number"
        matInput
        placeholder="Ex. 1"
        [(ngModel)]="clubsMaxQty"
      />
    </mat-form-field>
  </div>

  <br />

  <button
    class="mt-16 mb-24"
    mat-raised-button
    color="accent"
    (click)="saveSettings()"
  >
    {{ "Save" | translate }}
  </button>
</div>
