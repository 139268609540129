<div class="flexDisplay flexColumn alignCenter" style="margin-left: 300px;">

       <!--
           <div class="flexDisplay rowDisplay pt-8 pl-16 globalStats">
          <div>
            <div  class="clubKPI">{{printData.notPrintedCount}}</div>
            <div class="mt-16" style="color: #FFCC80">{{'NotPrinted'|translate}}</div>
          </div>
          <div style="color: darkorange;" class="flexDisplay flexColumn ml-16 pt-8 pr-16">
            <div style="align-items: baseline; place-content: space-between;" class="flexDisplay flexRow">
              <span class="label">{{'Optimal'|translate}} WIP</span>
              <div>
                <span class="ml-24 boldFont">{{printData.wipLimit}}</span>
              </div>
            </div>
    
            <div style="align-items: baseline; place-content: space-between;" class="flexDisplay flexRow mt-4">
              <span class="label">{{'HardGoods'|translate}}</span>
              <div>
                <span class="ml-24 boldFont">{{printData.totalHardGoods | number:'1.0-0' }}</span>
                <span class="ml-8 boldFont">{{(printData.totalHardGoods/printData.notPrintedCount)*100 | number:'1.0-0'}}%</span>
              </div>
            </div>
    
            <div style="align-items: baseline;place-content: space-between;" class="flexDisplay flexRow mt-4">
              <span class="label">{{'Samples'|translate}}</span>
              <div>
                <span class="ml-24 boldFont">{{printData.totalSamples | number:'1.0-0'}}</span>
                <span class="ml-8 boldFont">{{(printData.totalSamples/printData.notPrintedCount)*100 | number:'1.0-0'}}%</span>
              </div>
            </div>
            
    
          </div>
        </div>
       -->

        <!--<div class="flexDisplay flexColumn pt-8 ml-16 pl-16 globalStats" style="place-content: baseline; ">
          <span class="required">{{'RequiredContainers'|translate}}</span>
          
            <div style="color: darkorange; flex-wrap: wrap; width: 268px; font-size: 14px;" class="flexDisplay flexRow pt-8 pr-16">
              <div style="align-items: baseline; place-content: space-between;" class="flexDisplay flexRow mr-8">
                <span class="label">IBOX</span>
                <div>
                  <span class="ml-8 boldFont">{{printData.ibox}}</span>
                </div>
              </div>
              <div style="align-items: baseline; place-content: space-between;" class="flexDisplay flexRow mr-8">
                <span class="label">MBOX</span>
                <div>
                  <span class="ml-8 boldFont">{{printData.mbox}}</span>
                </div>
              </div>
              <div style="align-items: baseline; place-content: space-between;" class="flexDisplay flexRow mr-8">
                <span class="label">BTOT</span>
                <div>
                  <span class="ml-8 boldFont">{{printData.btot}}</span>
                </div>
              </div>
              
              <div style="align-items: baseline; place-content: space-between;" class="flexDisplay flexRow mr-8">
                <span class="label">2BOX</span>
                <div>
                  <span class="ml-8 boldFont">{{printData.twoBOX}}</span>
                </div>
              </div>
              <div style="align-items: baseline; place-content: space-between;" class="flexDisplay flexRow mr-8">
                <span class="label">XXDB</span>
                <div>
                  <span class="ml-8 boldFont">{{printData.xxdb}}</span>
                </div>
              </div>
      
              
  
          </div>
           
        </div>
      </div>-->
      
      <div class="flexDisplay flexColumn alignCenter">

        <div class="printCart"><h4 class="mt-32 title">{{'FinishedGoods'|translate}}</h4></div>
        
        <div>
            <mat-form-field class="printCart">
              <mat-label>{{'PickZones'|translate}}</mat-label>
              <mat-select disableOptionCentering [(value)]="selectedZone" (selectionChange)="hideNotification()">
                <mat-option *ngFor="let zone of pickZone" [value]="zone.name">
                  {{zone.name}}
                </mat-option>
              </mat-select>
            </mat-form-field>
            </div>

            <div>
              <mat-form-field class="printCart">
                <mat-label>{{'ZoneNumber'|translate}}</mat-label>
                <mat-select disableOptionCentering multiple [(value)]="selectedZoneNumber" (selectionChange)="hideNotification()" [disabled] = "selectedZone === 'All'">
                  <mat-option *ngFor="let zoneNum of zoneNumbers" [value]="zoneNum.number">
                    {{zoneNum.number}}
                  </mat-option>
                </mat-select>
              </mat-form-field>
              </div>

        <div>
          <mat-form-field class="printCart" >
            <mat-label>{{'Printers'|translate}}</mat-label>
            <mat-select disableOptionCentering [(value)]="selectedPrinter" (selectionChange)="hideNotification()">
              <mat-option *ngFor="let printer of printers" [value]="printer.id">
                {{printer.desc}}
              </mat-option>
            </mat-select>
          </mat-form-field>
          </div>
          
          <div>
          <mat-form-field class="printCart">
            <mat-label>{{'Containers'|translate}}</mat-label>
            <mat-select disableOptionCentering [(value)]="selectedContainer" (selectionChange)="hideNotification()">
              <mat-option *ngFor="let container of containers" [value]="container.value">
                {{container.viewValue}}
              </mat-option>
            </mat-select>
          </mat-form-field>          
          <div class="flexDisplay rowDisplay">
            <div class="pt-8  printBlocks">
                 <div (click)="printFGCart()"><mat-icon>printer</mat-icon>
                    <div [ngStyle]="{'padding-left': selectedLang === 'ja' ? '47px' : '31px', 'font-size' :'18px', 'color':'black','font-weight':'bolder' }">{{'Multiple' | translate}}</div>
                </div>
           </div>
          <div class="flexDisplay rowDisplay pt-8 pl-16 globalStats">
            <div>
              <div  class="clubKPI">{{printData.notPrintedCount}}</div>
              <div class="mt-16" style="color: #FFCC80">{{'NotPrinted'|translate}}</div>
            </div>
            <div style="color: darkorange;" class="flexDisplay flexColumn ml-16 pt-8 pr-16">
              <div style="align-items: baseline; place-content: space-between;" class="flexDisplay flexRow">
                <span class="label">{{'Optimal'|translate}} WIP</span>
                <div>
                  <span class="ml-24 boldFont">{{printData.wipLimit}}</span>
                </div>
              </div>
      
              <div style="align-items: baseline; place-content: space-between;" class="flexDisplay flexRow mt-4">
                <span class="label">{{'HardGoods'|translate}}</span>
                <div>
                  <span class="ml-24 boldFont">{{printData.totalHardGoods | number:'1.0-0' }}</span>
                  <span class="ml-8 boldFont">{{(printData.totalHardGoods/printData.notPrintedCount)*100 | number:'1.0-0'}}%</span>
                </div>
              </div>
      
              <div style="align-items: baseline;place-content: space-between;" class="flexDisplay flexRow mt-4">
                <span class="label">{{'Samples'|translate}}</span>
                <div>
                  <span class="ml-24 boldFont">{{printData.totalSamples | number:'1.0-0'}}</span>
                  <span class="ml-8 boldFont">{{(printData.totalSamples/printData.notPrintedCount)*100 | number:'1.0-0'}}%</span>
                </div>
              </div>
            </div>
          </div>
          <div class="pt-8  printBlocks">
            <div (click)="printSingleShipment()"><mat-icon>printer</mat-icon>
               <div [ngStyle]="{'padding-left': selectedLang === 'ja' ? '32px' : '37px', 'font-size' :'18px', 'color':'black','font-weight':'bolder' }">{{'Single' | translate}}</div>
           </div>
      </div>
          
          


          </div>


        </div>

            <ng-container *ngIf="dataSource !== null; else noData">
                <table mat-table [dataSource]="dataSource" class="mt-32 noBackground">
              
                  <ng-container matColumnDef="title">
                    <th mat-header-cell *matHeaderCellDef>{{'Line'|translate}}</th>
                    <td mat-cell *matCellDef="let element"> {{element.line}} </td>
                  </ng-container>
            
                  <ng-container matColumnDef="FinishedGoods">
                    <th mat-header-cell class="right-items" *matHeaderCellDef>{{'Clubs in Progress'|translate }}</th>
                    <td class="lineClubKPI" mat-cell *matCellDef="let element"> {{element.finishedGoods}} </td>
                  </ng-container>
              
              
                 
              
                  <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                  <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
                </table>
              </ng-container>

      <!--  <div class="mt-16">
          <mat-slide-toggle [(ngModel)]="isAutoRelease">{{'AutoRelease'|translate}}</mat-slide-toggle>
          <button class="ml-16" (click)="printSingleShipment()" mat-stroked-button>{{'SingleShipment'|translate}}</button>
        </div>-->
        
      </div>
      


</div>




  <ng-template #noData>
    <div>
      Error
    </div>
  </ng-template>
  
  
  
  <ngx-spinner bdColor="rgba(51, 51, 51, 0.8)" size="default" type="ball-spin-clockwise">  
    <p style="color: white">Please Wait. </p>  
  </ngx-spinner> 
  