
<h4 class="mt-32 title">{{'Line Setup'|translate}}</h4>

<mat-form-field class="mt-32 mr-16" style="width: 232px;">
    <mat-label>{{'Line Setup'|translate}}</mat-label>
    <mat-select  [(value)]="selectedLineSetup">
      <mat-option *ngFor="let item of lineSetup" [value]="item.id">
        {{item.title}}
      </mat-option>
    </mat-select>
  </mat-form-field>
  <button class="mt-32 mr-8" mat-raised-button color="accent" (click)="filterResults()">{{'Filter'|translate}}</button>
  <button class="mr-8" mat-stroked-button (click)="clearFilter()" >{{'Clear'|translate}}</button>
  
<button class="mr-16 alignSelfCenter fr mt-32" (click)="filterResults()" mat-stroked-button>
    <span class="material-icons mr-8"> refresh</span>
    {{'Refresh'|translate}}</button>
    
    <button class="mr-16 alignSelfCenter fr mt-32 primaryColor" (click)="CreateLineSetup()" mat-raised-button>
        <span class="material-icons">add</span>
        {{'Create'|translate}}</button>
    <br class="mt-16">
<br class="mt-32">
<ng-container *ngIf="dataSource.length !== 0; else emptyState ">
    <table mat-table [dataSource]="dataSource" class="mt-32 noBackground mb-24">
        <ng-container matColumnDef="ID">
            <th mat-header-cell *matHeaderCellDef>{{'ID'|translate}}</th>
            <td mat-cell *matCellDef="let element"> 
                {{element.id}} </td>
        </ng-container>


        <ng-container matColumnDef="title">
            <th mat-header-cell *matHeaderCellDef>{{'Title'|translate}}</th>
            <td mat-cell *matCellDef="let element"> 
                {{element.title}} </td>
        </ng-container>

        <ng-container matColumnDef="keyName">
            <th mat-header-cell *matHeaderCellDef>{{'Key Name'|translate}}</th>
            <td mat-cell *matCellDef="let element"> 
                {{element.keyName }} </td>
        </ng-container>
    
    
        <ng-container matColumnDef="keyNameFG">
            <th mat-header-cell *matHeaderCellDef> {{'Key Name FG'|translate}} </th>
            <td mat-cell *matCellDef="let element">
                {{element.keyNameFG ? element.keyNameFG : '- - -'}}
            </td>
        </ng-container>

        <ng-container matColumnDef="status">
            <th mat-header-cell *matHeaderCellDef> {{'Status'|translate}} </th>
            <td mat-cell *matCellDef="let element">
                <div *ngIf="element.active===true; else inactiveMessage">
                    {{'Active'|translate}}
                </div>
                
            </td>
        </ng-container>
    
        <ng-container matColumnDef="action">
            <th mat-header-cell *matHeaderCellDef> {{'Action'|translate}} </th>
            <td style="width: 30%;" mat-cell *matCellDef="let element;">
                <button  mat-raised-button color="accent"  class="mr-8" (click)="openLineSetupupdateDialog(element)">{{'Update'|translate}}</button>
                <button [disabled]="isProcessing" mat-stroked-button class="mr-8" *ngIf="element.active===true" (click)="changeStatus(false , element.id)">{{'Deactivate'|translate}}</button>
                <button [disabled]="isProcessing" mat-raised-button class="successColor mr-8" *ngIf="element.active!==true" (click)="changeStatus(true , element.id)" style="width: 98px;">{{'Activate'|translate}}</button>

                <button  mat-raised-button color="warn" (click)= "deleteLineSetupValue(element.id)" >{{'Delete'|translate}}</button>
            </td>
        </ng-container>
    
        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
    </table>
    <!--<mat-paginator [pageSizeOptions]="[15, 20, 30]" showFirstLastButtons></mat-paginator>-->
</ng-container>
<ng-template #emptyState>
    <div class="mt-32 w-100-p">
    <!--No Data found-->
    </div>
</ng-template>

<ng-template #inactiveMessage>
   {{'Inactive'|translate}}
</ng-template>

<ngx-spinner bdColor="rgba(51, 51, 51, 0.8)" size="default" type="ball-spin-clockwise">  
    <p style="color: white">Please Wait. </p>  
    </ngx-spinner>
