<h5 mat-dialog-title>{{'Print'|translate}} {{'Selection'|translate}}</h5>
<form>
<div mat-dialog-content class="rowStyling mt-32" >
    <div class="flexDisplay w-100-p">
    <span style="color: #757575;">Please select the print options by clicking on button below</span>
    </div>
    <div class="flexDisplay flexRow alignCenter mt-32 justifyCenter">
      <button mat-flat-button color="primary" [mat-dialog-close]="" [routerLink]="'/finished-goods'">{{'FinishedGoods'|translate}}</button>
        <span class="pl-16 pr-16 " style="color: #9e9e9e;">OR</span>
      <button [mat-dialog-close]="" [routerLink]="'/custom-goods'" mat-stroked-button color="primary">{{'CustomBuild'|translate}}</button>
    </div>
    
</div>

</form>

<div mat-dialog-actions style="float: right;">

</div>