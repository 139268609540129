<h5 mat-dialog-title>{{'Update'|translate}} {{'Line'|translate}} {{'Setup'|translate}} {{'Value'|translate}}</h5>


  <div mat-dialog-content class="columnStyling" style="max-height: 350px;">
    <mat-form-field class="mr-24">
        <mat-label>{{'Title'|translate}}</mat-label>
        <input type="text" matInput placeholder="Line Setup title" [(ngModel)]="parsedData.title">
    </mat-form-field>
    <mat-form-field class="mr-24">
      <mat-label>{{'Key'|translate}} {{'Name'|translate}}</mat-label>
      <input type="text" matInput placeholder="Key Name" [(ngModel)]="parsedData.keyName">
  </mat-form-field>
  <mat-form-field class="mr-24">
    <mat-label>{{'Key'|translate}} {{'Name'|translate}} FG </mat-label>
    <input type="text" matInput placeholder="Key Name FG" [(ngModel)]="parsedData.keyNameFG">
</mat-form-field>
  </div>

<div mat-dialog-actions>
  <button mat-button (click)="onNoClick()">{{'Cancel'|translate}}</button>
  <button mat-button [mat-dialog-close]="" cdkFocusInitial (click)="saveLineSetupValues()">{{'Save'|translate}}</button>
</div>
