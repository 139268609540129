<h5 mat-dialog-title>{{'Create'|translate}} {{'Account'|translate}} {{'Rule'|translate}}</h5>


  <div mat-dialog-content class="columnStyling" style="max-height: 350px;">
    <mat-form-field class="mr-24" style="width: 200px;">
        <mat-label>{{'Ship To'|translate}}</mat-label>
        <input type="text" matInput [(ngModel)]="shipTo">
    </mat-form-field>
    <mat-form-field class="mr-24" style="width: 200px;">
      <mat-label>{{'Label'|translate}} {{'Type'|translate}}</mat-label>
      <input type="text" matInput  [(ngModel)]="labelType">
  </mat-form-field>
  <mat-form-field class="mr-24" style="width: 200px;">
    <mat-label>{{'Store'|translate}} {{'Name'|translate}} </mat-label>
    <input type="text" matInput [(ngModel)]="storeName">
</mat-form-field>
<mat-form-field class="mr-24" style="width: 200px;">
    <mat-label>{{'Store'|translate}} {{'Code'|translate}} </mat-label>
    <input type="text" matInput [(ngModel)]="storeCode">
</mat-form-field>
<mat-form-field class="mr-24" style="width: 200px;">
    <mat-label>{{'Label'|translate}} {{'Format'|translate}} </mat-label>
    <input type="text" matInput [(ngModel)]="labelFormat">
</mat-form-field>
<mat-form-field class="mr-24" style="width: 200px;">
    <mat-label>{{'Specification'|translate}} {{'Comment'|translate}}</mat-label>
    <input type="text" matInput [(ngModel)]="specComment">
</mat-form-field>
<mat-form-field class="mr-24" style="width: 200px;">
    <mat-label>{{'Label'|translate}} {{'Format'|translate}} FG</mat-label>
    <input type="text" matInput [(ngModel)]="labelFormatFG">
</mat-form-field>
<mat-form-field class="mr-24" style="width: 200px;">
    <mat-label>{{'Label'|translate}} {{'Format'|translate}} {{'Header'|translate }} </mat-label>
    <input type="text" matInput [(ngModel)]="labelFormatHeader">
</mat-form-field>
<mat-form-field class="mr-24" style="width: 200px;">
    <mat-label>{{'Label'|translate}} {{'Format'|translate}} REG </mat-label>
    <input type="text" matInput [(ngModel)]="labelFormatReg">
</mat-form-field>
  </div>

<div mat-dialog-actions>
  <button mat-button (click)="onNoClick()">{{'Cancel'|translate}}</button>
  <button mat-button [mat-dialog-close]="" cdkFocusInitial (click)="saveLineSetupValues()">{{'Save'|translate}}</button>
</div>
