import { Component, OnInit, Inject } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { FormBuilder, FormArray, FormGroup } from '@angular/forms';
import { HttpService } from '../http.service';
import { Overlay } from '@angular/cdk/overlay';
import { NotifierService } from "angular-notifier";
import { isNull } from '@angular/compiler/src/output/output_ast';
import { NgxSpinnerService } from 'ngx-spinner';
import { error } from '@angular/compiler/src/util';

@Component({
  selector: 'app-account-rule-delete-popup',
  templateUrl: './account-rule-delete-popup.component.html',
  styleUrls: ['./account-rule-delete-popup.component.scss']
})
export class AccountRuleDeletePopupComponent implements OnInit {

  private readonly notifier: NotifierService;
  parsedID: any;

 
  constructor(private fb: FormBuilder,
    private _http: HttpService,
    notifierService: NotifierService,
    private SpinnerService: NgxSpinnerService,
    public dialogRef: MatDialogRef<AccountRuleDeletePopupComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any) { 
      this.notifier = notifierService;
      this.parsedID = data;
  }

  ngOnInit(): void {
  
  }

  confirmDelete(){

      this.SpinnerService.show();
      this._http.deleteAccountRule(this.parsedID).subscribe((results)=>{
        if(results){
          this.notifier.notify('success', 'Account Rule has been deleted successfully!');
          this.SpinnerService.hide();
        }
      },
      err => {
        this.SpinnerService.hide();
        this.notifier.notify('error', 'There was a server error. Please try later!')
      })
    }      

  onNoClick(): void {
    this.dialogRef.close();
  }

}
