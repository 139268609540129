import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-private-layout',
  template: `
  <div>
    <app-header></app-header>
    <app-menu-bar></app-menu-bar>
  </div>  

  <div class="container">
    <router-outlet></router-outlet>  
  </div> 
  `,
  styles: []
})
export class PrivateLayoutComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
