import { Component, OnInit, Inject } from "@angular/core";
import {
  MatDialog,
  MatDialogRef,
  MAT_DIALOG_DATA,
} from "@angular/material/dialog";
import { FormBuilder, FormArray, FormGroup, FormControl } from "@angular/forms";
import { HttpService } from "../http.service";
import { NotifierService } from "angular-notifier";

@Component({
  selector: "app-capacity-popup",
  templateUrl: "./capacity-popup.component.html",
  styleUrls: ["./capacity-popup.component.scss"],
})
export class CapacityPopupComponent implements OnInit {
  capacitySettingData: any = {};
  maxCapacity: boolean = true;
  line: any;
  manufacturingType: string = "";
  lowQtyRatio: boolean;
  sblNulls: boolean;
  tw: boolean;
  weightChange: boolean;

  public formGroup = new FormGroup({
    cellId: new FormControl(null, []),
    maxCapacity: new FormControl(null, []),
    outputTarget: new FormControl(null, []),
    bendingRatio: new FormControl(null, []),
    qualityRatio: new FormControl(null, []),
  });

  public formGroup1 = new FormGroup({});
  private readonly notifier: NotifierService;

  constructor(
    private fb: FormBuilder,
    private _http: HttpService,
    notifierService: NotifierService,
    public dialogRef: MatDialogRef<CapacityPopupComponent>,
    @Inject(MAT_DIALOG_DATA) public obj: any
  ) {
    this.getCapacitySettings(obj.id);
    this.line = obj.line;
    this.manufacturingType = obj.manufacturingType;

    this.notifier = notifierService;
    this.formGroup.controls["maxCapacity"].setValue(0);
    this.formGroup.controls["outputTarget"].setValue(0);
    this.formGroup.controls["bendingRatio"].setValue(0);
    this.formGroup.controls["qualityRatio"].setValue(0);
  }

  ngOnInit(): void {}

  getCapacitySettings(id) {
    this._http.getCapacitySettings(id).subscribe((results) => {
      this.capacitySettingData = results;
      this.formGroup.controls["cellId"].setValue(
        this.capacitySettingData.cellId
      );
      this.formGroup.controls["maxCapacity"].setValue(
        this.capacitySettingData.maxCapacity
      );
      this.formGroup.controls["outputTarget"].setValue(
        this.capacitySettingData.outputTarget
      );
      this.formGroup.controls["bendingRatio"].setValue(
        this.capacitySettingData.bendingRatio
      );
      this.formGroup.controls["qualityRatio"].setValue(
        this.capacitySettingData.qualityRatio
      );
      this.sblNulls = this.capacitySettingData.sblNulls;
      this.tw = this.capacitySettingData.tw;
      this.weightChange = this.capacitySettingData.weightChange;

      if (this.capacitySettingData.qualityRatio === 100)
        this.lowQtyRatio = true;
      else this.lowQtyRatio = false;
    });
  }

  updateFinishedGoods() {
    if (this.lowQtyRatio === true) this.formGroup.value.qualityRatio = 100;
    else this.formGroup.value.qualityRatio = 0;

    this.formGroup.value.bendingRatio = 0;
  }

  updateCapacitySettings() {
    let payload = {
      cellId: this.formGroup.value.cellId,
      maxCapacity: this.formGroup.value.maxCapacity,
      outputTarget: this.formGroup.value.outputTarget,
      bendingRatio: this.formGroup.value.bendingRatio,
      qualityRatio: this.formGroup.value.qualityRatio,
      sblNulls: this.sblNulls,
      tw: this.tw,
      weightChange: this.weightChange,
    };

    this._http.updateCapacitySettings(payload).subscribe((results) => {
      if (results)
        this.notifier.notify(
          "success",
          "Capacity settings have been updated successfully!"
        );
    });
  }

  increaseCapacity() {
    if (this.formGroup.controls["maxCapacity"].value + 10 < 9999) {
      this.formGroup.controls["maxCapacity"].setValue(
        this.formGroup.controls["maxCapacity"].value + 10
      );
    } else {
      this.formGroup.controls["maxCapacity"].setValue(9999);
    }
  }

  decreaseCapacity() {
    if (this.formGroup.controls["maxCapacity"].value - 10 > 0)
      this.formGroup.controls["maxCapacity"].setValue(
        this.formGroup.controls["maxCapacity"].value - 10
      );
    else this.formGroup.controls["maxCapacity"].setValue(1);
  }

  increaseTarget() {
    if (this.formGroup.controls["outputTarget"].value + 10 < 9999) {
      this.formGroup.controls["outputTarget"].setValue(
        this.formGroup.controls["outputTarget"].value + 10
      );
    } else {
      this.formGroup.controls["outputTarget"].setValue(9999);
    }
  }

  decreaseTarget() {
    if (this.formGroup.controls["outputTarget"].value - 10 > 0) {
      this.formGroup.controls["outputTarget"].setValue(
        this.formGroup.controls["outputTarget"].value - 10
      );
    } else {
      this.formGroup.controls["outputTarget"].setValue(1);
    }
  }

  onNoClick(): void {
    this.dialogRef.close();
  }

  prevent(event) {
    if (event.keyCode === 13) {
      event.preventDefault();
    }
  }
}
