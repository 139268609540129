import { Component, OnInit, Inject, ɵ_sanitizeHtml  } from '@angular/core';
import { HttpService } from '../http.service';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-line-history-popup',
  templateUrl: './line-history-popup.component.html',
  styleUrls: ['./line-history-popup.component.scss']
})

export class LineHistoryPopupComponent implements OnInit {

  displayedColumns: string[] = ['station', 'start', 'end', 'user'];  
  dataSource :any= []

  constructor(
    private _http: HttpService,
    public dialogRef: MatDialogRef<LineHistoryPopupComponent>,
    @Inject(MAT_DIALOG_DATA) public workorder: any) {
      this.getLineHistory(workorder);
    }

  ngOnInit(): void {
  
  }


  getLineHistory(workorder){
    this._http.getWOLineHistory(workorder).subscribe((results)=>{
      this.dataSource = results;

      console.log(this.dataSource);
    })
  }

  onNoClick(): void {
    this.dialogRef.close();
  }

}
