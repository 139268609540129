import { Component, OnInit, Inject } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { FormBuilder, FormArray, FormGroup } from '@angular/forms';
import { HttpService } from '../http.service';
import { Overlay } from '@angular/cdk/overlay';
import { NotifierService } from "angular-notifier";
import { isNull } from '@angular/compiler/src/output/output_ast';
import { NgxSpinnerService } from 'ngx-spinner';
import { stringify } from '@angular/compiler/src/util';

@Component({
  selector: 'app-line-setup-create-popup',
  templateUrl: './line-setup-create-popup.component.html',
  styleUrls: ['./line-setup-create-popup.component.scss']
})
export class LineSetupCreatePopupComponent implements OnInit {

  private readonly notifier: NotifierService;
  parsedData: any;
  selectedLineSetup: string = '';
  lineSetup: any = [];
  title: string = '';
  keyName: string = '';
  keyNameFG: string = '';



 
  constructor(private fb: FormBuilder,
    private _http: HttpService,
    notifierService: NotifierService,
    private SpinnerService: NgxSpinnerService,
    public dialogRef: MatDialogRef<LineSetupCreatePopupComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any) { 
      this.notifier = notifierService;
      this.parsedData = data;
  }

  ngOnInit(): void {
   this.getLineSetup();
  }

  getLineSetup() {
    this._http.getLineSetupData().subscribe((results) => {
     this.lineSetup = results;
      
    },
      err => {
        this.SpinnerService.hide();
        this.notifier.notify('error', 'There was a server error. Please try later!')
      });
  }

  saveLineSetupValues(){

    let payload = {
      "title": this.title,
      "lineSetupID": this.selectedLineSetup,
      "keyName": this.keyName,
      "keyNameFG": this.keyNameFG,
      "Active": true
    }
    


      if (this.keyName !== "" && this.title !== "" && this.selectedLineSetup !== ""){
        this.SpinnerService.show();
        this._http.createLineSetupValue(payload).subscribe((results)=>{
      if(results){
         this.notifier.notify('success', 'Line Setup have been created successfully!');
         this.SpinnerService.hide();
         this.dialogRef.close;
        }
      },
      err => {
        this.SpinnerService.hide();
        this.notifier.notify('error', 'There was a server error. Please try later!')
        this.dialogRef.close;

      });
    }
    else{
      this.notifier.notify('warning', `Line Setup can't be left empty!`);
    } 
  }

  onNoClick(): void {
    this.dialogRef.close();
  }
}
