import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { HttpService } from '../http.service';

@Component({
  selector: 'app-wo-scanned-serial-popup',
  templateUrl: './wo-scanned-serial-popup.component.html',
  styleUrls: ['./wo-scanned-serial-popup.component.scss']
})
export class WoScannedSerialPopupComponent implements OnInit {

  dataSource :any= []

  constructor(
    private _http: HttpService,
    public dialogRef: MatDialogRef<WoScannedSerialPopupComponent>,
    @Inject(MAT_DIALOG_DATA) public workorder: any) {
      this.getScannedSerials(workorder);
    }

  ngOnInit(): void {
  
  }


  getScannedSerials(workorder){
    this._http.getWOScannedSerials(workorder).subscribe((results)=>{
      this.dataSource = results;
    })
  }

  onNoClick(): void {
    this.dialogRef.close();
  }
}
