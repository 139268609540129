import { Component, OnInit,Inject } from '@angular/core';
import { MatDialogRef,MAT_DIALOG_DATA } from '@angular/material/dialog';
import { HttpService } from '../http.service';
import { NotifierService } from "angular-notifier";
import { Overlay } from '@angular/cdk/overlay';
import { NgxSpinnerService } from "ngx-spinner";

@Component({
  selector: 'app-close-order-popup',
  templateUrl: './close-order-popup.component.html',
  styleUrls: ['./close-order-popup.component.scss']
})
export class CloseOrderPopupComponent implements OnInit {

  checkFlag : boolean = false;
  eligiblityFlag : boolean = true;
  eligibilityMsg : any;


  private readonly notifier: NotifierService;
  constructor(
    public dialogRef: MatDialogRef<CloseOrderPopupComponent>,
    @Inject(MAT_DIALOG_DATA) public payloadData: any,
    private _http: HttpService,
    public overlay: Overlay,
    private SpinnerService: NgxSpinnerService,
    notifierService: NotifierService,
  ) {
    this.notifier = notifierService;
  }

  ngOnInit(): void {
    this.closeEligibility();
  }

  onNoClick(): void {
    this.dialogRef.close({event: 'Close', data: false});
  }

  closeShipment(){
    //eligible for close
    this._http.closeShipmentList(this.payloadData.data).subscribe((results)=>{
      this.SpinnerService.show();
      let objs = JSON.parse(JSON.stringify(results));
      if(objs.message === "success" || objs.message === "ok"){

        this.notifier.notify("success", "Shipment/s has been closed successfully!!");
        this.dialogRef.close({event:'Closed',data:true});
      }
      else{
        this.notifier.notify("error", "Unable to close shipment/s !!");
        this.notifier.notify("error", objs.message);

        this.dialogRef.close({event:'Closed',data:true});
      
      }
      this.SpinnerService.hide();
    });
  }

  closeWorkOrder(){
    this._http.closeWorkOrderList(this.payloadData.data).subscribe((results)=>{
      this.SpinnerService.show();
      let objw = JSON.parse(JSON.stringify(results));
      if(objw.message === "success" || objw.message === "ok"){

        this.notifier.notify("success", "Work Order/s has been closed successfully!!");
        this.dialogRef.close({event:'Closed',data:true});
      }
      else{
        this.notifier.notify("error", "Unable to close Work Order/s !!");
        this.notifier.notify("error", objw.message);
        this.dialogRef.close({event:'Closed',data:true});      
      }
      this.SpinnerService.hide();

    });
  }

  closeEligibility(){

    if(this.payloadData != null){

      if(this.payloadData.type == 'shipment'){
        
        this._http.closeCheckShipmentList(this.payloadData.data).subscribe((results)=>{
          let obj = JSON.parse(JSON.stringify(results));
          if(obj.message === "ok"){
            this.checkFlag = true;
          }
          else{
            //this.notifier.notify("error", obj.message);
            this.checkFlag = true;
            this.eligiblityFlag = false;
            this.eligibilityMsg = obj.message;          
          }
        })

      }else if(this.payloadData.type == 'workOrder'){
        
        this._http.closeCheckWorkOrderList(this.payloadData.data).subscribe((results)=>{
          let obj = JSON.parse(JSON.stringify(results));
          if(obj.message === "ok"){
              this.checkFlag = true;
          }
          else{
            //this.notifier.notify("error", obj.message);
            this.checkFlag = true;
            this.eligiblityFlag = false;
            this.eligibilityMsg = obj.message;            
          }
        });

      }
    }   
    
  }

}
