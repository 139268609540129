import { Component, OnInit, Inject } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { HttpService } from '../http.service';
import { NotifierService } from "angular-notifier";
import { NgxSpinnerService } from 'ngx-spinner';

@Component({
  selector: 'app-reprint-info-component',
  templateUrl: './reprint-info-component.component.html',
  styleUrls: ['./reprint-info-component.component.scss']
})
export class ReprintInfoComponentComponent implements OnInit {

  rePrintData: any = [];
  private readonly notifier: NotifierService;

  constructor(
   
    private _http: HttpService,
    notifierService: NotifierService,
    private SpinnerService: NgxSpinnerService,
    public dialogRef: MatDialogRef<ReprintInfoComponentComponent>,

    @Inject(MAT_DIALOG_DATA) public data: any) {
      this.notifier = notifierService;
      this.rePrintData = data;
  }


  ngOnInit(): void {
  }

  onNoClick(): void {
    this.dialogRef.close();
  }

  rePrintshipment(){
      this._http.reprintMultipleShipments(this.rePrintData).subscribe((results) => {
        let obj = JSON.parse(JSON.stringify(results));
        if (obj.message === 'Success') {
          this.notifier.notify("success", "Reprint request has been sent successfully!");
        }else{
          this.notifier.notify("error", "Something went wrong with re-printing. Please try again!");
        }
      },
      err=>{
        this.notifier.notify("error", "Something went wrong with re-printing. Please try again!");
      })
  }

}
