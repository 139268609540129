

  <div>
    <app-header></app-header>
    <div [ngClass]="{'disabled':menuDisabled}">
    <app-menu-bar *ngIf="showMenuBar"></app-menu-bar>
  </div>
    <app-menubarv2 *ngIf="showMenuBarV2"></app-menubarv2>
  </div>  

  <div class="container">
    <router-outlet></router-outlet>  
  </div>

  <!-- uncomment to get login functionality -->
  <!-- <router-outlet></router-outlet>  -->
  <notifier-container></notifier-container>