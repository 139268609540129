<h5 mat-dialog-title>{{'Database'|translate}} {{'Settings'|translate}}</h5>
<form>
<div mat-dialog-content class="rowStyling mt-32" >
   
    <div class="flexDisplay flexRow alignCenter mt-32" style="justify-content: space-around;">
      <button mat-raised-button color="accent" [mat-dialog-close]="" [routerLink]="'/line-setup-settings'">
        <span class="material-icons">
            timeline
            </span>
        <span> {{'Line Setup'|translate}}</span>
    </button>

    <button mat-raised-button color="accent" [mat-dialog-close]="" [routerLink]="'/account-rule-settings'">
        <span class="material-icons">
            rule
            </span>
        {{'Account Rules'|translate}}
    </button>
    
    </div>
</div>

</form>

<div mat-dialog-actions style="float: right;">

</div>