<h4 class="mt-32 title">{{'Account Rules'|translate}}</h4>

  
<button class="mr-16 alignSelfCenter fr mt-32" mat-stroked-button (click)="getAllAccountRules()">
    <span class="material-icons mr-8"> refresh</span>
    {{'Refresh'|translate}}</button>
    
    <button class="mr-16 alignSelfCenter fr mt-32 primaryColor" mat-raised-button (click)="CreateAccountRule()">
        <span class="material-icons">add</span>
        {{'Create'|translate}}</button>
    <br class="mt-16">
<br class="mt-32">
<ng-container *ngIf="dataSource.length !== 0; else emptyState ">
    <table mat-table [dataSource]="dataSource" class="mt-32 noBackground mb-24">
        <ng-container matColumnDef="Ship To">
            <th mat-header-cell *matHeaderCellDef>{{'Ship To'|translate}}</th>
            <td mat-cell *matCellDef="let element"> 
                {{element.shiP_TO}} </td>
        </ng-container>


        <ng-container matColumnDef="Store Name">
            <th mat-header-cell *matHeaderCellDef>{{'Store Name'|translate}}</th>
            <td mat-cell *matCellDef="let element"> 
                {{element.storE_NAME ? element.storE_NAME : '---'}} </td>
        </ng-container>

        <ng-container matColumnDef="Store Code">
            <th mat-header-cell *matHeaderCellDef>{{'Store Code'|translate}}</th>
            <td mat-cell *matCellDef="let element"> 
                {{element.storE_CODE ? element.storE_CODE : '---'}} </td>
        </ng-container>
    
    
        <ng-container matColumnDef="Type">
            <th mat-header-cell *matHeaderCellDef> {{'Type'|translate}} </th>
            <td mat-cell *matCellDef="let element">
                {{element.labeL_TYPE ? element.labeL_TYPE : '---'}}
            </td>
        </ng-container>

        <ng-container matColumnDef="Format">
            <th mat-header-cell *matHeaderCellDef> {{'Format'|translate}} </th>
            <td mat-cell *matCellDef="let element">
                {{element.labeL_FORMAT ? element.labeL_FORMAT : '---' }}
                
            </td>
        </ng-container>

        <ng-container matColumnDef="Specification">
            <th mat-header-cell *matHeaderCellDef> {{'Specification'|translate}} </th>
            <td style="width: 22% !important;" mat-cell *matCellDef="let element">
                {{element.specificatioN_CMT ? element.specificatioN_CMT : '---'}}
            </td>
        </ng-container>

        <ng-container matColumnDef="Format FG">
            <th mat-header-cell *matHeaderCellDef> {{'Format FG'|translate}} </th>
            <td mat-cell *matCellDef="let element">
                {{element.labeL_FORMAT_FG ? element.labeL_FORMAT_FG : '---'}}
            </td>
        </ng-container>

        <ng-container matColumnDef="Format Header">
            <th mat-header-cell *matHeaderCellDef> {{'Format Header'|translate}} </th>
            <td mat-cell *matCellDef="let element">
                {{element.labeL_FORMAT_HEADER ? element.labeL_FORMAT_HEADER : '---'}}
            </td>
        </ng-container>

        <ng-container matColumnDef="Format Reg">
            <th mat-header-cell *matHeaderCellDef> {{'Format Reg'|translate}} </th>
            <td mat-cell *matCellDef="let element">
                {{element.labeL_FORMAT_REG ? element.labeL_FORMAT_REG : '---'}}
            </td>
        </ng-container>
    
        <ng-container matColumnDef="action">
            <th mat-header-cell *matHeaderCellDef> {{'Action'|translate}} </th>
            <td style="width: 30%;" mat-cell *matCellDef="let element;">
                <button  mat-raised-button color="accent"  class="mr-8" (click)="UpdateAccountRule(element)">{{'Update'|translate}}</button>
                <button  mat-raised-button color="warn" (click)= "deleteAccountRule(element.shiP_TO)">{{'Delete'|translate}}</button>
            </td>
        </ng-container>
    
        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
    </table>
    <!--<mat-paginator [pageSizeOptions]="[15, 20, 30]" showFirstLastButtons></mat-paginator>-->
</ng-container>
<ng-template #emptyState>
    <div class="mt-32 w-100-p">
    <!--No Data found-->
    </div>
</ng-template>


<ngx-spinner bdColor="rgba(51, 51, 51, 0.8)" size="default" type="ball-spin-clockwise">  
    <p style="color: white">Please Wait. </p>  
    </ngx-spinner>
