import { BrowserModule } from '@angular/platform-browser';
import { NgModule, NO_ERRORS_SCHEMA, CUSTOM_ELEMENTS_SCHEMA, Inject, APP_INITIALIZER } from '@angular/core';
import { MomentPipe } from './MomentPipe';
import { NotifierModule, NotifierOptions } from "angular-notifier";
import { AppConfig, initConfig } from './app-config';
import { AppRoutingModule } from './app-routing.module';
import { AuthGuard } from '../auth.guard';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { HttpClient, HttpClientModule } from '@angular/common/http';
// import { AppMaterialModule } from '../app-material/app-material.module';

import { AppComponent } from './app.component';
import { HeaderComponent } from './header/header.component';
import { MenuBarComponent } from './menu-bar/menu-bar.component';
import { LineManagementComponent } from './line-management/line-management.component';
import { LineSettingsComponent } from './line-settings/line-settings.component';
import { PrintsComponent } from './prints/prints.component';
import { HomeComponent } from './home/home.component';
import { PrintQueueComponent } from './print-queue/print-queue.component';
import { SearchComponent } from './search/search.component';

import { ReactiveFormsModule, FormsModule  } from '@angular/forms';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { from } from 'rxjs';
import { CustomSettingsPopupComponent } from './custom-settings-popup/custom-settings-popup.component';
import { CapacityPopupComponent } from './capacity-popup/capacity-popup.component';
import { LineHistoryPopupComponent } from './line-history-popup/line-history-popup.component';
import { SpecificationPopupComponent } from './specification-popup/specification-popup.component';
import { LoginComponent } from './login/login.component';
import { OverlayContainer } from '@angular/cdk/overlay';

import {MatRadioModule} from '@angular/material/radio';
import {MatSliderModule} from '@angular/material/slider';
import {MatIconModule} from '@angular/material/icon';
import {MatCardModule} from '@angular/material/card';
import {MatDialogModule} from '@angular/material/dialog';
import {MatSlideToggleModule} from '@angular/material/slide-toggle';
import {MatSelectModule} from '@angular/material/select';
import {MatTableModule } from '@angular/material/table';
import {CdkTableModule} from '@angular/cdk/table';
import {MatInputModule} from '@angular/material/input'; 
import {MatButtonModule} from '@angular/material/button';
import {MatDatepickerModule} from '@angular/material/datepicker';
import {MatNativeDateModule,  MatRippleModule} from '@angular/material/core';
import {MatSnackBarModule} from '@angular/material/snack-bar';


import { PrivateLayoutComponent } from './layouts/private-layout/private-layout.component';
import { PublicLayoutComponent } from './layouts/public-layout/public-layout.component';

import { NgxSpinnerModule } from "ngx-spinner";
import { PrintSelectionComponent } from './print-selection/print-selection.component';
import { FinishedGoodsComponent } from './finished-goods/finished-goods.component';
import { CustomGoodsComponent } from './custom-goods/custom-goods.component';
import { CancelShipmentPopupComponent } from './cancel-shipment-popup/cancel-shipment-popup.component';
import { DatabaseSettingsComponent } from './database-settings/database-settings.component';
import { LineSetupUpddatePopupComponent } from './line-setup-upddate-popup/line-setup-upddate-popup.component';
import { MatPaginatorModule } from '@angular/material/paginator';
import { ReprintInfoComponentComponent } from './reprint-info-component/reprint-info-component.component';
import { FinishedGoodsV2Component } from './finished-goods-v2/finished-goods-v2.component';
import { Menubarv2Component } from './menubarv2/menubarv2.component';
import { LineSetupDeletePopupComponent } from './line-setup-delete-popup/line-setup-delete-popup.component';
import { LineSetupCreatePopupComponent } from './line-setup-create-popup/line-setup-create-popup.component';
import { WoScannedSerialPopupComponent } from './wo-scanned-serial-popup/wo-scanned-serial-popup.component';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatSortModule } from '@angular/material/sort';
import { LineSetupSettingsComponent } from './line-setup-settings/line-setup-settings.component';
import { AccountRuleSettingsComponent } from './account-rule-settings/account-rule-settings.component';
import { AccountRuleCreatePopupComponent } from './account-rule-create-popup/account-rule-create-popup.component';
import { AccountRuleUpdatePopupComponent } from './account-rule-update-popup/account-rule-update-popup.component';
import { AccountRuleDeletePopupComponent } from './account-rule-delete-popup/account-rule-delete-popup.component';
import { CloseOrderPopupComponent } from './close-order-popup/close-order-popup.component';

const customNotifierOptions: NotifierOptions = {
  position: {
    horizontal: {
      position: 'middle',
      // distance: 12
    },
    vertical: {
      position: 'top',
      // distance: 12,
      // gap: 10
    }
  },
  theme: 'material',
  behaviour: {
    autoHide: 5000,
    onClick: 'hide',
    onMouseover: 'pauseAutoHide',
    showDismissButton: true,
    stacking: 4
  },
  animations: {
    enabled: true,
    show: {
      preset: 'slide',
      speed: 0,
      easing: 'ease'
    },
    hide: {
      preset: 'fade',
      speed: 300,
      easing: 'ease',
      offset: 50
    },
    shift: {
      speed: 300,
      easing: 'ease'
    },
    overlap: 150
  }
}


@NgModule({
  exports: [ 
    
  ],
  declarations: [
    AppComponent,
    HeaderComponent,
    MenuBarComponent,
    LineManagementComponent,
    PrintsComponent,
    HomeComponent,
    PrintQueueComponent,
    SearchComponent,
    CustomSettingsPopupComponent,
    CapacityPopupComponent,
    LineHistoryPopupComponent,
    SpecificationPopupComponent,
    LoginComponent,
    PrivateLayoutComponent,
    PublicLayoutComponent,
    MomentPipe,
    LineSettingsComponent,
    PrintSelectionComponent,
    FinishedGoodsComponent,
    CustomGoodsComponent,
    CancelShipmentPopupComponent,
    DatabaseSettingsComponent,
    LineSetupUpddatePopupComponent,
    ReprintInfoComponentComponent,
    FinishedGoodsV2Component,
    Menubarv2Component,
    LineSetupDeletePopupComponent,
    LineSetupCreatePopupComponent,
    WoScannedSerialPopupComponent,
    LineSetupSettingsComponent,
    AccountRuleSettingsComponent,
    AccountRuleCreatePopupComponent,
    AccountRuleUpdatePopupComponent,
    AccountRuleDeletePopupComponent,
    CloseOrderPopupComponent,
  ],

  imports: [
    BrowserModule,
    HttpClientModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    NgxSpinnerModule,
    //MatPaginatorModule,
    BrowserModule,
    BrowserAnimationsModule,
    MatCheckboxModule,
    MatSortModule,
    MatTableModule,

    

    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: httpTranslateLoader,
        deps: [HttpClient]
      }
    }),
    
    NotifierModule.withConfig(customNotifierOptions),
  
    // AppMaterialModule,AppMaterialModule,

    ReactiveFormsModule,
    FormsModule, 
    MatDatepickerModule,
    MatDialogModule,
    CdkTableModule,
    MatTableModule,
    MatCardModule,
    MatIconModule,
    MatRadioModule,
    MatSelectModule,
    MatInputModule,
    MatButtonModule,
    MatSliderModule,
    MatSlideToggleModule,
    MatNativeDateModule,  
    MatRippleModule,
    MatSnackBarModule,
    //MatPaginatorModule,
  ],

  schemas: [
    NO_ERRORS_SCHEMA,
    CUSTOM_ELEMENTS_SCHEMA
  ],
  providers: [
    AuthGuard,
    AppConfig,{
      provide: APP_INITIALIZER,
            useFactory: initConfig,
            deps: [AppConfig],
            multi: true
  },],
  bootstrap: [AppComponent],
  entryComponents:[CustomSettingsPopupComponent, CapacityPopupComponent, ReprintInfoComponentComponent]
})
export class AppModule { 
  //overwriting dark theme for dialogs
  constructor(overlayContainer: OverlayContainer) {
    overlayContainer.getContainerElement().classList.add('custom-light-popup');
  }
}

// AOT compilation support
export function httpTranslateLoader(http: HttpClient) {
  return new TranslateHttpLoader(http);
}
