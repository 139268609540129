import { Component, OnInit, Output, EventEmitter } from '@angular/core';
import { FormControl, FormGroupDirective, NgForm, Validators, FormGroup} from '@angular/forms';
import { ErrorStateMatcher} from '@angular/material/core';
import { HttpService } from '../http.service';
import { Router } from '@angular/router';

export class MyErrorStateMatcher implements ErrorStateMatcher {
  isErrorState(control: FormControl | null, form: FormGroupDirective | NgForm | null): boolean {
    const isSubmitted = form && form.submitted;
    return !!(control && control.invalid && (control.dirty || control.touched || isSubmitted));
  }
}

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {


  emailFormControl = new FormControl('', [
    Validators.required,
    Validators.email,
  ]);

  loginForm = new FormGroup({
    emailFormControl : new FormControl('', [
      Validators.required,
      Validators.email,
    ]),
    passwordFormControl : new FormControl('', [
      Validators.required,
      
    ])
  })

  matcher = new MyErrorStateMatcher();
  
  constructor(
    private _http: HttpService,
    private router: Router
  ) {
    let isLoggedIn = this._http.isLoggedIn;
    if(isLoggedIn.value){
      this.router.navigate(['/line-management'])
    }
  }

  ngOnInit(): void {
  }

  login(){
  
    let isLoggedIn = this._http.authenticateUser(this.loginForm.value);

    if(isLoggedIn){
      this.router.navigate(['/line-management'])
      this._http.setLoggedIn(true);
    }
    else
      window.alert('Authentication Failed');
  }

}
