import { Component, OnInit } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { filter } from 'rxjs/operators';


@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent{

  showMenuBar = true;
  showMenuBarV2 = false;
  title = 'capacity-planning';
  router:any;
  menuDisabled: boolean = false;
 
  constructor(
    public translate: TranslateService,
    private _router: Router

  ) {

    this.router = _router;
    this.router.events.pipe(filter(e => e instanceof NavigationEnd)).subscribe(event => this.modifyMenuBar(event));

    translate.addLangs(['en', 'es', 'ja']);
    translate.setDefaultLang('en');
  }

  modifyMenuBar(location) {
    //console.log(location);
    if (location.url.includes('/finished-goods-v2')) {
        this.showMenuBar = false;
        this.showMenuBarV2 = true;
        this.menuDisabled = false;
    }else if(location.url.includes('?view=Limited'))
    {
        this.menuDisabled = true;
        this.showMenuBar = true;
        this.showMenuBarV2 = false;
    } 
    else {
        this.menuDisabled = false;
        this.showMenuBar = true;
        this.showMenuBarV2 = false;
    }
}


}


