
<div class="w-100 menu-bar">
  
          <nav>
            <ul>
              <!-- <li class="nav-item active">
                <a mat-button [routerLink]="'/'"> Home </a>
              </li> -->

              <li routerLinkActive="activePage">
                <i (click)="openDatabaseSettings()" class="fa fa-database icon-size-xx" id="database-settings"></i>
                <a mat-button (click)="openDatabaseSettings()"> {{'Database'|translate}} {{'Settings'|translate}}</a>
              </li>


              <li routerLinkActive="activePage">
                <i [routerLink]="'/line-management'" class="fa fa-tasks icon-size-xx" id="line-management"></i>
                <a mat-button [routerLink]="'/line-management'"> {{'Line'|translate}} {{'Management'|translate}}</a>
              </li>

              <li routerLinkActive="activePage">
                <i [routerLink]="'/line-settings'" class="fa fa-cog icon-size-xx"  id="line-settings"></i>
                <a mat-button [routerLink]="'/line-settings'"> {{'Line'|translate}} {{'Settings'|translate}}</a>
              </li>

              <li routerLinkActive="activePage">
                
                  <i (click)="openPrintSelection()" class="fa fa-print icon-size-xx" id="print-selection"></i>
                  <a (click)="openPrintSelection()" mat-button > {{'Print'|translate}} </a>
  
              </li>
              <li routerLinkActive="activePage">
                <i [routerLink]="'/print-queue'" class="fa fa-hourglass-start icon-size-xx" id="print-queue"></i>
                <a mat-button [routerLink]="'/print-queue'"> {{'Print'|translate}} {{'Queue'|translate}} </a>
              </li>
              <li routerLinkActive="activePage">
                  <i [routerLink]="'/search'" class="fa fa-search icon-size-xx" id="search"></i>
                  <a mat-button [routerLink]="'/search'"> {{'Search'|translate}}</a>
              </li>
              <li routerLinkActive="activePage">

                <a [href]="closeURL" style="color: white; text-underline-position: under;" class="fa fa-power-off icon-size-xx" id="closeBtn">
                </a>
                <a mat-button [href]="closeURL">{{'Close'|translate}}</a>
            </li>
            </ul>
          
          </nav>  

</div>