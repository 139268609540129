import { Component, OnInit } from '@angular/core';
import { HttpService } from '../http.service';
import { Overlay } from '@angular/cdk/overlay';

@Component({
  selector: 'app-menubarv2',
  templateUrl: './menubarv2.component.html',
  styleUrls: ['./menubarv2.component.scss']
})
export class Menubarv2Component implements OnInit {

  closeURL:string = '';
  
  constructor(
    private _http: HttpService,
    public overlay: Overlay,
  ) { }

  ngOnInit(): void {
    this.closeURL = $ENV.SITE_URL  + '/UnitSelection';
  }

}
