<div class="mt-32 ml-8 flexDisplay flexColumn">

    <div class="flexDisplay flexRow headContainer">
        <div class="flexDisplay flexRow headLeft alignCenter">
            <div class="flexDisplay search">    
            <div class="searchTerm">
                        <input type="text" (keypress)="searchShipmentById($event)" autofocus="true" [(ngModel)]="searchValue"
                            placeholder="Search a shipment or work order">
                        <button mat-button *ngIf="searchValue" matSuffix mat-icon-button aria-label="Clear"
                            (click)="clearResults()">
                            <mat-icon color="warn">close</mat-icon>
                        </button>           
                </div>   
            </div>            
                <div class="ml-64">
                    <mat-form-field>
                    <mat-label>{{'Printers'|translate}}</mat-label>
                    <mat-select disableOptionCentering [(value)]="selectedPrinter" [disabled]="disableView">
                      <mat-option *ngFor="let printer of printers" [value]="printer.id">
                        {{printer.desc}}
                      </mat-option>
                    </mat-select>
                </mat-form-field>
                </div>

                <div class="ml-16">
                    <mat-form-field>
                        <mat-select disableOptionCentering [(value)]="selectedAction" [disabled]="disableView">
                          <mat-option [disabled]="disableOptions"  value="Re-print">Re-print</mat-option>
                          <mat-option  value="Export-Serials">Export Serials</mat-option>
                          <mat-option [disabled]="disableOptions"  value="prioritize-all">Prioritize All</mat-option>
                          <mat-option [disabled]="disableOptions"  value="prioritize-selected">Prioritize Selected</mat-option>
                          <mat-option [disabled]="disableOptions"  value="un-prioritize-all">Un-Prioritize All</mat-option>
                          <mat-option [disabled]="disableOptions"  value="un-prioritize-selected">Un-Prioritize Selected</mat-option>
                          <mat-option [disabled]="disableOptions"  value="cancel-all">Cancel All</mat-option>
                          <mat-option [disabled]="disableOptions"  value="cancel-selected">Cancel Selected</mat-option>
                          <mat-option [disabled]="disableOptions"  value="close-all">Close All</mat-option>
                          <mat-option [disabled]="disableOptions"  value="close-selected">Close Selected</mat-option>

                        </mat-select>
                    </mat-form-field>
                </div>
                
                <button class="ml-16 successColor" mat-raised-button (click)="performAction()" [disabled]="disableView">{{'Go'|translate}}</button>
                
        </div>

        <div class="headRight" >
            <div class="headerTwo">
                <div class="headerTwoItems">
                   
                        <h6>Shipments:
                            <span class="ml-16" style="color:#bdbdbd;">{{searchResults.length}}</span>
                        </h6>
                        <h6>WOs: 
                            <span style="margin-left: 60px; color:#bdbdbd">{{WoCount}}</span>
                        </h6>
               
                </div>
                <div>
                    <h6>Selected:
                        <ng-container *ngIf="selectedShipmentList.length !== 0; else woSelected ">
                            <span class="ml-16" style="color:#bdbdbd">{{selectedShipmentList.length}}</span>
                        </ng-container> 
                        <ng-template #woSelected>
                            <span class="ml-16" style="color:#bdbdbd">{{selectedWorkOrderList.length}}</span>

                        </ng-template>
                    </h6>
                </div>
                
            </div>
        </div>
    </div>

    <div *ngIf="searchValue === ''" class="flexDisplay flexRow headContainer">
        <div class="flexDisplay flexRow infoLeft alignCenter">
                    <div class="w-100-p pb-24 flexDisplay flexColumn">
                        <h6><strong>Enter a reference number in the<br>box above to search by:</strong></h6>
                        <span class="mt-8" style="color: #ff9800;">Shipment</span>
                        <span class="mt-8" style="color: #ff9800;">Delivery Number</span>
                        <span class="mt-8" style="color: #ff9800;">Work Order</span>
                        <span class="mt-8" style="color: #ff9800;">Container</span>
                        <span class="mt-8" style="color: #ff9800;">Wave Name</span>
                        <span class="mt-8" style="color: #ff9800;">Serial Number</span>
                        <span class="mt-8" style="color: #ff9800;">Sales Order</span>                                                              
                    </div>
         </div>
                
        <div class="headRight" >
            
        </div>
    </div>

    <div *ngIf="notFound && searchValue !==''" class="flexDisplay flexRow headContainer">
        <div class="flexDisplay flexRow infoLeft alignCenter">
                    <div class="w-100-p pb-24 flexDisplay flexColumn">
                        <h6 style="color:rgb(192,24,29);"><strong>This shipment has not made it to GAT due to<br/>an issue </strong></h6>
                        <h6 class="mt-16" *ngIf="WMSFlag">{{WMSMsg}} in WMS...</h6>
                        <span class="mt-24"><strong>Please click below to log a ticket:</strong></span>
                        <span class="mt-8"><a href="https://tmgolf.freshservice.com/" target="blank" style="color:#32c7e8 !important">
                            <strong>FreshService Link</strong></a></span>                                                          
                    </div>
         </div>
                
        <div class="headRight" >
            
        </div>
    </div>

    <div class="flexDisplay flexColumn resultHeadContainer">
        <div *ngFor="let shipment of searchResults let i = index" class="flexDisplay flexRow mt-16">
            <div  class="flexDisplay flexColumn alignCenter shipments">
                <div class="w-100-p mr-16 pb-24 shipment">
                    <div class="flexDisplay flexColumn ml-16" style="text-align: left;">
                        <div class="flexDisplay flexRow">
                        <mat-checkbox [disabled] = "wOSelected" (click)="selectAllShipment(shipment)">
                            <h3 style="margin-left: 10px; margin-top: 5px;"><strong>{{'Shipment'|translate}}:</strong> {{shipment.shipment}} </h3>
                        </mat-checkbox>
                        </div>
                            
                            <span style="color:#bdbdbd; margin-left: 34px;">
                                {{'EBS Order Number'|translate}}:{{shipment.ebsOrderNumber}}
                            </span>
                            <span style="color:#bdbdbd; margin-left: 34px; margin-top: 5px;">
                                {{'Printed on'|translate}}:{{shipment.printDate != null ? (shipment.printDate | dateFormat:'MMMM D, yyyy') : '-'}}
                            </span> 
        
                    </div>
                    <div class="cellPill" style="margin-left: 150px;"><strong>{{shipment.cellname}}</strong></div>
                    <div *ngIf="shipment.urgent === true" class="cellPill" style="margin-right: 200px;"><mat-icon>error_outline</mat-icon></div>
    
                <div class="flexDisplay flexColumn" style="text-align: right; font-size: small;">
                    <span><strong>{{shipment.customerShipToNumber != null ? shipment.customerShipToNumber[0] : ' '}}</strong><br><strong>{{shipment.customerShipToNumber != null ? shipment.customerShipToNumber[1] : ' '}}</strong></span>
                        
                        <span style="color:#bdbdbd;">
                            {{shipment.deliverAddress1}}<br>
                            {{shipment.deliverAddress2}}<br>
                            {{shipment.deliverAddress3}}
                        </span>
    
                </div>
    
              </div>
    
                <div class="workorderDetails" *ngFor="let WO of shipment.workorders;">
                    <div *ngIf="WO.result === 'OK' || WO.result === 'Entered' || WO.result === 'Printed'" class="mb-24">
                        
                        <div class="flexDisplay flexRow scannedWO">
                            <div class="flexDisplay flexColumn columnOne">
                                    <mat-checkbox [disabled]="allSelected" (click)="markselectedWO(WO.workorder)">
                                        <h5 style="margin-left: 5px; margin-top: 5px;"> {{WO.workorder}} </h5>
                                    </mat-checkbox>
                                    <h6 style="margin-left: 30px; margin-top: 10px;"> {{WO.fG_SKU_Desc | slice:0:20}} </h6>     
                                <span style="margin-left: 30px;">{{WO.station != null ? WO.station : "- - -"}}  <span class="ml-8">{{WO.on | dateFormat:'DD/MM/YY, h:mm:ss a'}}</span></span> 
                            </div>
                            <div class="flexDisplay flexRow columnTwo">
                                <button class="alignSelfCenter" mat-stroked-button
                                (click)="openSpecificationDialog(WO.workorder)">
                                <span class="material-icons">
                                    description
                                </span>
                                {{'Specification'|translate}}
                            </button>
                            <button class="ml-8 alignSelfCenter" mat-stroked-button
                                (click)="openLineHistoryDialog(WO.workorder)">
                                <span class="material-icons">
                                    history
                                </span>
                                {{'History'|translate}}
                            </button>
                            <button class="ml-8 alignSelfCenter" mat-stroked-button
                                (click)="openWoSerialDialog(WO.workorder)">
                                <span class="material-icons">
                                    history
                                </span>
                            {{'Serials'|translate}}
                            </button>


                            </div>
                            
                        </div>
                    </div>
                    <div *ngIf="WO.result === 'Not Scanned' && shipment.printed == true" class="mb-24">
                        <div class=" w-100-p mb-16">
                            <div class="flexDisplay flexRow alignCenter">
                                <mat-checkbox [disabled]="allSelected" (click)="markselectedWO(WO.workorder)">
                                    <h5 style="margin-left: 5px; margin-top: 10px;">{{(WO.workorder)}} </h5>
                                </mat-checkbox>
                                <div class="tag ml-16">
                                    {{'NotScanned'|translate}}
                                </div>
                            </div>
                            <div>
                                <h6 style="margin-left: 30px; margin-top: 10px;"> {{WO.fG_SKU_Desc | slice:0:20}} </h6>
                            </div>
    
                            <div class="pb-16">
                                <span style="color:#bdbdbd; margin-left: 30px;">
                                    {{'Printed'|translate}} {{'on'|translate}} {{shipment.printDate | dateFormat:'MM/DD/YY, h:mm:ss a'}}
                                </span>
    
                            </div>
                        </div>
                    </div>

                    <div *ngIf="shipment.printed === false && shipment.workorders.length > 0" class="mb-24">
                        <div class=" w-100-p mb-16" *ngIf="WO.result === 'Not Scanned'">
                            <div class="flexDisplay flexRow alignCenter">
                                <mat-checkbox [disabled]="allSelected" (click)="markselectedWO(WO.workorder)">
                                    <h5 style="margin-left: 5px; margin-top: 10px;">{{WO.workorder}} </h5>
                                </mat-checkbox>
                                <div class="tag ml-16" *ngIf="shipment.cancelled === true">
                                    {{'Cancelled'|translate}}
                                </div>
                            </div>
                            <div>
                                <h6 style="margin-left: 30px; margin-top: 10px;"> {{WO.fG_SKU_Desc | slice:0:20}} </h6>
                            </div>
    
                            <div class="pb-16">
                                <span style="color:#bdbdbd; margin-left: 30px;">
                                    {{'Not'|translate}} {{'Printed'|translate}}
                                </span>
    
                            </div>
                        </div>
                    </div>    
                </div>                
            </div>

            <div class="headRight flexDisplay flexColumn">
                    <div class="ml-32 flexDisplay flexRow waveCustom">
                        <div class="flexDisplay flexColumn">
                        <h5><strong>{{'Wave'|translate}}</strong></h5>
                            <span style="color:#bdbdbd">
                                {{shipment.waveId}}
                            </span>
                        </div>

                        <div class="flexDisplay">
                            <h5><strong>{{shipment.orderType}}</strong></h5>
                        </div>
                    </div>
                    <div *ngIf="shipment.searchRegion !== null">
                        <div class="ml-32 flexDisplay flexRow waveCustom">
                            <div class="flexDisplay flexColumn">
                                <h5><strong>{{'Facility'|translate}}</strong></h5>
                            </div>

                            <div class="flexDisplay margin-left= 100px">
                                <h5><strong>{{facility[i]}}</strong></h5>
                            </div>

                        </div>
                        <div class="ml-32 flexDisplay flexRow waveCustom" style="margin-left: 210px !important">

                            <img [src]="urlImg[i]" width="80" height="50">

                        </div>
                    </div>
                    <div *ngFor="let WO of shipment.workorders; let i = index" class="ml-32">
                        <div class="instructions" *ngIf="WO.result === 'Not Scanned' && i < 1">
                            <div *ngIf="shipment.comments === null">
                                <mat-form-field class="w-100-p">
                                    <mat-label>{{'SpecialInstructions'|translate}}</mat-label>
                                    <textarea [(ngModel)]="specialInstructions" style="height: 60px; resize: none;" matInput
                                        placeholder="Enter special instructions" [disabled]="disableView"></textarea>
                                </mat-form-field>
                                <button [disabled]="disableView" color="primary" class="fr alignSelfCenter" (click)="updateComment(shipment.shipment)" mat-flat-button>
                                    {{'Submit'|translate}}
                                </button>
                            </div>  
                            <div class="mb-16" *ngIf="shipment.comments !== null || shipment.comments !== ' '">
                            <h5 style="color:#ff9800;"><strong>{{'SpecialInstructions'|translate}}:</strong></h5>
                            <span style="color:#bdbdbd">
                                {{shipment.comments}}
                            </span>
                        </div>                          
                        </div>
        
                        <div class="instructions_scanned mb-16"
                            *ngIf="(WO.result === 'OK' || WO.result === 'Entered' || WO.result === 'Printed') && i < 1">
                            <h5 style="color:#ff9800;"><strong>{{'SpecialInstructions'|translate}}:</strong></h5>
                            <span style="color:#bdbdbd">
                                {{'NoInstructions'|translate}}
                            </span>
                        </div>
                    </div>
            </div>


        </div>        
    </div>

    <ngx-spinner bdColor="rgba(51, 51, 51, 0.8)" size="default" type="ball-spin-clockwise">  
        <p style="color: white">Please Wait. </p>  
        </ngx-spinner> 








    <!--Old version of Search screen UI-->
    <!--<div class="mt-32 flexDisplay flexColumn alignCenter">

    <div class="wrap mb-24 flexDisplay flexRow">
        <div class="search">
            <div class="searchTerm">
                <input type="text" (keypress)="searchShipmentById($event)" autofocus="true" [(ngModel)]="searchValue"
                    placeholder="Search a shipment or work order">
                <button mat-button *ngIf="searchValue" matSuffix mat-icon-button aria-label="Clear"
                    (click)="clearResults()">
                    <mat-icon color="warn">close</mat-icon>
                </button>
            </div>
            

        </div>

    </div>


    <div *ngIf="searchValue === ''" class="mt-96 flexDisplay flexColumn alignCenter">
        <img src="../../assets/images/file.svg" width="150px">
        <span class="placeHolderText">{{'SearchShipmentWO'|translate}}</span>
    </div>

    <div *ngIf="searchValue === ''" class="mt-96 flexDisplay flexColumn alignCenter">
       <img src="../../assets/images/ExportSerials.svg" width="150px" (click)="exportSerials()">
        <span class="placeHolderText">{{'ExportSerials'|translate}}</span>
    </div>

    <div *ngIf="searchResults.printed === true && searchValue !==''" class="searchResultsContainer mt-40">
        <div class="mr-32 resultsContainer">
            <div class="w-100-p pb-24 shipment">
                <h3><strong>{{'Shipment'|translate}}:</strong> {{searchResults.shipment}} </h3>
                <div class="cellPill"><strong>{{searchResults.cellname}}</strong></div>
                <div>
                    <mat-form-field>
                    <mat-label>{{'Printers'|translate}}</mat-label>
                    <mat-select disableOptionCentering [(value)]="selectedPrinter">
                      <mat-option *ngFor="let printer of printers" [value]="printer.id">
                        {{printer.desc}}
                      </mat-option>
                    </mat-select>
                </mat-form-field>
                </div>
                
                <button class="ml-16" mat-raised-button color="accent" (click)="reprintShipment()">{{'Print'|translate}}</button>
                 <button *ngIf="printedNotScanned" color="warn" class="mr-16 alignSelfCenter" mat-flat-button (click)="cancelShipment()">
                    <span class="material-icons">
                        cancel
                    </span> 
                    {{'Cancel'|translate}} {{'Shipment'|translate}}
                </button>
            </div>

            <div class=" w-100-p mt-16" *ngFor="let WO of searchResults.workorders">
                <div *ngIf="WO.result === 'OK' || WO.result === 'Entered' || WO.result === 'Printed'">
                    <h5><strong>{{'WorkOrder'|translate}}:</strong> {{WO.workorder}} </h5>
                    <table *ngIf="dataSource" mat-table [dataSource]="dataSource" class="mt-16 noBackground">
                         <ng-container matColumnDef="line">
                                <th mat-header-cell *matHeaderCellDef>{{'Line'|translate}}</th>
                                <td mat-cell *matCellDef="let element"> {{searchResults.cellname}} </td>
                            </ng-container>

                        <ng-container matColumnDef="station">
                            <th mat-header-cell *matHeaderCellDef style="width: 12%;"> {{'Station'|translate}} </th>
                            <td mat-cell *matCellDef="let element">
                                {{WO.station != null ? WO.station : "- - -"}}
                            </td>
                        </ng-container>

                        <ng-container matColumnDef="dateTime">
                            <th mat-header-cell *matHeaderCellDef style="width: 15%;"> {{'DateTime'|translate}} </th>
                            <td mat-cell *matCellDef="let element"> {{WO.on | dateFormat:'DD/MM/YY, h:mm:ss a'}} </td>
                        </ng-container>

                        <ng-container matColumnDef="user">
                            <th mat-header-cell *matHeaderCellDef style="width: 10%;"> {{'User'|translate}} </th>
                            <td mat-cell *matCellDef="let element">
                                {{WO.useriD != null ? WO.useriD : "- - -"}}
                            </td>
                        </ng-container>

                        <ng-container matColumnDef="action">
                            <th mat-header-cell *matHeaderCellDef style="width: 36%;"> </th>
                            <td class="actionColumn" mat-cell *matCellDef="let element;">
                                <button class="ml-16 alignSelfCenter" mat-stroked-button
                                    (click)="openSpecificationDialog(WO.workorder)">
                                    <span class="material-icons">
                                        description
                                    </span>
                                    {{'Specification'|translate}}
                                </button>
                                <button class="ml-8 alignSelfCenter" mat-stroked-button
                                    (click)="openLineHistoryDialog(WO.workorder)">
                                    <span class="material-icons">
                                        history
                                    </span>
                                    {{'History'|translate}}
                                </button>
                                <button class="ml-8 alignSelfCenter" mat-stroked-button
                                (click)="openWoSerialDialog(WO.workorder)">
                                <span class="material-icons">
                                    history
                                </span>
                                {{'Serials'|translate}}
                            </button>
                            </td>
                        </ng-container>

                        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                        <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
                    </table>
                </div>
                <div *ngIf="WO.result === 'Not Scanned' && searchResults.printed == true">
                    <div class=" w-100-p mt-24 mb-16" style="border-bottom: 1px solid rgba(255, 255, 255, 0.12)">
                        <div class="flexDisplay flexRow alignCenter">
                            <h5 class="fitContent noMargin"><strong>{{'WorkOrder'|translate}}:</strong> {{WO.workorder}}
                            </h5>
                            <div class="tag ml-16">
                                {{'NotScanned'|translate}}
                            </div>
                        </div>

                        <div class="mt-16 pb-16">
                            <span style="color:#bdbdbd">
                                {{'Printed'|translate}} {{'on'|translate}} {{searchResults.printDate | dateFormat:'MM/DD/YY, h:mm:ss a'}}
                            </span>

                        </div>
                    </div>
                </div>

            </div>
        </div>
        <div class="flexDisplay flexColumn">

        

                <div class="w-100-p pb-24" style="margin-left: 24px;">
                    <h3><strong>{{stName}} <br> {{stNum}}</strong></h3>
                    <span style="color:#bdbdbd">
                        {{searchResults.deliverAddress1}}<br>
                        {{searchResults.deliverAddress2}}<br>
                        {{searchResults.deliverAddress3}}
                    </span>
                </div>


            <div *ngFor="let WO of searchResults.workorders; let i = index">


                <div class="instructions" *ngIf="WO.result === 'Not Scanned' && i < 1">
                    <mat-form-field class="w-100-p">
                        <mat-label>{{'SpecialInstructions'|translate}}</mat-label>
                        <textarea [(ngModel)]="specialInstructions" style="height: 96px; resize: none;" matInput
                            placeholder="Enter special instructions"></textarea>
                    </mat-form-field>
                    <button color="primary" class="fr alignSelfCenter" (click)="updateComment()" mat-flat-button>
                        {{'Submit'|translate}}
                    </button>
                </div>

                <div class="instructions_scanned mb-16"
                    *ngIf="WO.result === 'OK' || WO.result === 'Entered' || WO.result === 'Printed' && i < 1">
                    <span style="color:#bdbdbd">
                        {{'NoInstructions'|translate}}
                    </span>
                </div>
            </div>

        </div>
    </div>


    <div *ngIf="searchResults.printed === false && searchResults.workorders.length === 0 "
        class="mt-96 flexDisplay flexColumn alignCenter">
        <span style="font-size: 150px;" class="material-icons">
            search_off
        </span>
        <span class="placeHolderText">{{'NoResults'|translate}}</span>
    </div>

    <div *ngIf="searchResults.printed === false && searchResults.workorders.length > 0"
        class="mt-96 flexDisplay flexColumn alignCenter">
        <ng-container *ngIf="!shipmentCancelled; else shipmentCancelledMessage">
            <span style="font-size: 150px;" class="material-icons">
                print_disabled
            </span>
            <span class="placeHolderText">{{'ShipmentNotPrinted'|translate}}</span>
        </ng-container>
        <ng-template #shipmentCancelledMessage>
            <span style="font-size: 150px;" class="material-icons">
                cancel
            </span>
            <span class="placeHolderText">{{'ShipmentCancelledMessage'|translate}}</span>
        </ng-template>
        <button [disabled]="shipmentCancelled" *ngIf="printedNotScanned" (click)="markShipmentUrgent()" color="accent"
            class="mr-16 mt-24 alignSelfCenter" mat-flat-button>
            <span class="material-icons">
                report
            </span>
            {{'Mark'|translate}} {{'Urgent'|translate}}
        </button>

        <button [disabled]="shipmentCancelled" *ngIf="printedNotScanned" color="warn" class="mr-16 mt-16 alignSelfCenter" mat-flat-button (click)="openCancelShipment()">
            <span class="material-icons">
                cancel
            </span> 
            {{'Cancel'|translate}} {{'Shipment'|translate}}
        </button>

    </div>




</div>

<ngx-spinner bdColor="rgba(51, 51, 51, 0.8)" size="default" type="ball-spin-clockwise">  
    <p style="color: white">Please Wait. </p>  
    </ngx-spinner> -->
