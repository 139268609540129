import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { BehaviorSubject } from "rxjs";
import { Router } from "@angular/router";

@Injectable({
  providedIn: "root",
})
export class HttpService {
  //private baseURL = $ENV.SITE_URL + ":3000/";
  //private baseURL = $ENV.SITE_URL + "/";
  private baseURL = "https://cap-api.hnl.works/";
  private siteID = $ENV.SITE_ID;

  // private baseURL = "http://dev-tjglobalassembly.tmgolf.biz:3000/";
  // private siteID = 6;

  constructor(private http: HttpClient, private router: Router) {}

  private loggedInStatus = new BehaviorSubject<boolean>(true);

  setLoggedIn(value: boolean) {
    this.loggedInStatus.next(value);
  }

  logout() {
    this.loggedInStatus.next(false);
    this.router.navigate(["/login"]);
  }

  get isLoggedIn() {
    return this.loggedInStatus;
  }

  authenticateUser(userObj) {
    if (
      userObj.emailFormControl === "testuser@test.com" &&
      userObj.passwordFormControl === "test123"
    ) {
      return true;
    } else return false;
  }

  getPrintTable() {
    return this.http.get(this.baseURL + "cells/progress/V2.0");
  }

  getLines(fifoValue) {
    return this.http.get(
      this.baseURL + "cells/bysiteid/" + this.siteID + "/" + fifoValue
    );
  }

  getManufacturingTypeData() {
    return this.http.get(this.baseURL + "manufacturingtype");
  }

  getGlobalSetupValues() {
    return this.http.get(this.baseURL + "globalsetup");
  }

  getGlobalSetupValuesVersion() {
    return this.http.get(this.baseURL + "globalsetup/V14");
  }

  updateLineSettings(data) {
    return this.http.post(
      this.baseURL + "globalsetup/updateGlobalValues",
      data
    );
  }

  updateLineSettingsVersion(data) {
    return this.http.post(
      this.baseURL + "globalsetup/updateGlobalValues/V14",
      data
    );
  }

  getPrintSortValues() {
    return this.http.get(this.baseURL + "printsort", {});
  }

  updateManufacturingType(data) {
    return this.http.post(this.baseURL + "cells/updatemanufacturingtype", data);
  }

  activateLines(id: number, status: boolean) {
    return this.http.post(this.baseURL + "cells/updateactive", {
      id: id,
      isActive: status,
    });
  }

  getCells() {
    return this.http.get(this.baseURL + "cells");
  }

  getCustomSettings(id) {
    return this.http.get(this.baseURL + "cells/customsettings/bylineid/" + id);
  }

  getAllAccountRules() {
    return this.http.get(this.baseURL + "labels");
  }

  createAccountRule(data) {
    return this.http.post(this.baseURL + "labels/createlabel", data);
  }
  deleteAccountRule(id) {
    return this.http.delete(this.baseURL + "labels/" + id);
  }
  updateAccountRule(data) {
    return this.http.post(this.baseURL + "labels/updatelabel", data);
  }

  getLineSetupValue() {
    return this.http.get(this.baseURL + "linesetupvalue");
  }

  getLineSetupData() {
    return this.http.get(this.baseURL + "linesetup");
  }

  getDefaultSettings() {
    return this.http.get(this.baseURL + "linesetup/withvalues");
  }

  getLineSetupValuebyId(id) {
    return this.http.get(this.baseURL + "linesetupvalue/" + id);
  }

  updateLineSetupValue(data) {
    return this.http.post(
      this.baseURL + "linesetupvalue/updatelinesetupvalue",
      data
    );
  }

  createLineSetupValue(data) {
    return this.http.post(this.baseURL + "linesetupvalue", data);
  }

  activateLineSetupValue(id: number, status: boolean) {
    return this.http.post(this.baseURL + "linesetupvalue/updateactive", {
      id: id,
      isActive: status,
    });
  }

  deleteLineSetupValue(id) {
    return this.http.delete(this.baseURL + "linesetupvalue/" + id);
  }

  updateCustomSettings(data) {
    return this.http.post(this.baseURL + "cells/customsettings/", data);
  }

  getCapacitySettings(id) {
    return this.http.get(
      this.baseURL + "cells/capacitysettings/V5/bylineid/" + id
    );
  }

  updateCapacitySettings(data) {
    return this.http.post(this.baseURL + "cells/capacitysettings/V5", data);
  }

  updateMaxCapacity(data) {
    return this.http.post(this.baseURL + "cells/updatemaxcapacity", data);
  }

  getDropdownData(id) {
    return this.http.get(this.baseURL + "linesetupvalue/bylinesetupid/" + id);
  }

  getPrintQueue(params?: any) {
    return this.http.get(this.baseURL + "shipment/printqueue/V3", {
      params: params,
    });
  }

  getPrintersDropdownData() {
    return this.http.get(this.baseURL + "printers");
  }

  markUrgentMultipleShipments(shipmentList) {
    return this.http.post(
      this.baseURL + "shipment/makeurgent/multipleshipments/",
      shipmentList
    );
  }

  marknotUrgentMultipleShipments(shipmentList) {
    return this.http.post(
      this.baseURL + "shipment/makenoturgent/multipleshipments/",
      shipmentList
    );
  }

  markShipmentUrgent(shipmentID) {
    return this.http.post(
      this.baseURL + "shipment/makeurgentbyshipment/V2/" + shipmentID,
      {}
    );
  }

  markWaveUrgent(waveId) {
    return this.http.post(
      this.baseURL + "shipment/makeurgentbywave/" + waveId,
      {}
    );
  }

  markShipmentNonUrgent(shipmentID) {
    return this.http.post(
      this.baseURL + "shipment/makenoturgentbyshipment/V2/" + shipmentID,
      ""
    );
  }

  printSpecificOrder(data) {
    return this.http.post(this.baseURL + "print/specificorder", data);
  }

  reprintShipment(data) {
    return this.http.post(this.baseURL + "print/reprint/V3", data);
  }

  reprintMultipleShipments(shipmentIdList) {
    return this.http.post(
      this.baseURL + "print/multipleorders",
      shipmentIdList
    );
  }

  printCart(data) {
    return this.http.post(this.baseURL + "print/carts/V2", data);
  }

  printSingleShipment(data) {
    return this.http.post(this.baseURL + "print/singleshipment", data);
  }

  searchByShipmentId(shipmentID) {
    return this.http.get(this.baseURL + "shipment/searchby/V7/" + shipmentID);
  }

  searchWMS(shipmentID) {
    return this.http.get(
      this.baseURL + "shipment/checkmissingorders/" + shipmentID
    );
  }

  cancelShipment(shipmentID) {
    return this.http.post(this.baseURL + "cancel/shipment/" + shipmentID, {});
  }

  cancelShipmentList(shipmentList) {
    return this.http.post(this.baseURL + "cancel/shipmentslist/", shipmentList);
  }

  closeShipmentList(shipmentList) {
    return this.http.post(this.baseURL + "close/shipmentslist/", shipmentList);
  }

  cancelCheckShipmentList(shipmentList) {
    return this.http.post(
      this.baseURL + "cancel/check/shipmentslist/",
      shipmentList
    );
  }

  closeCheckShipmentList(shipmentList) {
    return this.http.post(
      this.baseURL + "close/check/shipmentslist/",
      shipmentList
    );
  }

  closeCheckWorkOrderList(workOrderList) {
    return this.http.post(
      this.baseURL + "close/check/workorderslist/",
      workOrderList
    );
  }

  cancelWorkOrderList(workOrderList) {
    return this.http.post(
      this.baseURL + "cancel/workorderslist/",
      workOrderList
    );
  }

  closeWorkOrderList(workOrderList) {
    return this.http.post(
      this.baseURL + "close/workorderslist/",
      workOrderList
    );
  }

  cancelCheckWorkOrderList(workOrderList) {
    return this.http.post(
      this.baseURL + "cancel/check/workorderslist/",
      workOrderList
    );
  }

  cancelChecklWorkOrder(workOrder) {
    return this.http.post(this.baseURL + "cancel/check/workorder/", workOrder);
  }

  cancelChecklSalesOrder(salesOrder) {
    return this.http.post(
      this.baseURL + "cancel/check/salesorder/",
      salesOrder
    );
  }

  getWOSpecifications(workOrder) {
    return this.http.get(
      this.baseURL + "workorder/specifications/" + workOrder
    );
  }

  getWOLineHistory(workOrder) {
    return this.http.get(
      this.baseURL + "workorder/searchlinehistory/" + workOrder
    );
  }

  getWOScannedSerials(workOrder) {
    return this.http.get(this.baseURL + "workorder/serialcodes/" + workOrder);
  }

  getAllSerials() {
    return this.http.get(this.baseURL + "workorder/allserialcodes/");
  }

  updateShipment(data) {
    return this.http.post(this.baseURL + "shipment/updatecomment", data);
  }

  getFinishedGoods() {
    return this.http.get(this.baseURL + "FinishedGoods/getFinishedGoods/V2");
  }

  getDbDateTime() {
    return this.http.get(this.baseURL + "shipment/getCurrentDate");
  }

  getPickZones() {
    return this.http.get(this.baseURL + "FinishedGoods/fetchPickZones");
  }

  getPickZoneNumbers() {
    return this.http.get(this.baseURL + "FinishedGoods/fetchZoneNumbers");
  }

  printFinishedGoods(data) {
    return this.http.post(this.baseURL + "printFG/containers/V3", data);
  }

  desActivatePrintSession() {
    return this.http.get(this.baseURL + "globalsetup/desactivatePrintSession");
  }
}
