import { Component, OnInit } from '@angular/core';
import { HttpService } from '../http.service';
import { PrintSelectionComponent } from '../print-selection/print-selection.component';
import { Overlay } from '@angular/cdk/overlay';
import { MatDialog } from '@angular/material/dialog';
import { DatabaseSettingsComponent } from '../database-settings/database-settings.component';

@Component({
  selector: 'app-menu-bar',
  templateUrl: './menu-bar.component.html',
  styleUrls: ['./menu-bar.component.scss']
})
export class MenuBarComponent implements OnInit {

  closeURL:string = '';
  
  constructor(
    private _http: HttpService,
    public dialog: MatDialog,
    public overlay: Overlay,
  ) { }

  ngOnInit(): void {
    this.closeURL = $ENV.SITE_URL  + '/adminview';
  }

  logout(){
    this._http.logout();
  }

  openPrintSelection(): void {
    
    const dialogRef = this.dialog.open(PrintSelectionComponent, {
      width: '496px',
      scrollStrategy: this.overlay.scrollStrategies.noop(),
      panelClass: 'custom-modal',
    });

    dialogRef.afterClosed().subscribe(result => {
      console.log('The dialog was closed');
    });
  }

  openDatabaseSettings(): void {
    
    const dialogRef = this.dialog.open(DatabaseSettingsComponent, {
      width: '496px',
      scrollStrategy: this.overlay.scrollStrategies.noop(),
      panelClass: 'custom-modal',
    });

    dialogRef.afterClosed().subscribe(result => {
      console.log('The dialog was closed');
    });
  }

}
