import { Component, OnInit,Inject } from '@angular/core';
import { MatDialogRef,MAT_DIALOG_DATA } from '@angular/material/dialog';
import { HttpService } from '../http.service';
import { NotifierService } from "angular-notifier";
import { Overlay } from '@angular/cdk/overlay';
import { NgxSpinnerService } from "ngx-spinner";


@Component({
  selector: 'app-cancel-shipment-popup',
  templateUrl: './cancel-shipment-popup.component.html',
  styleUrls: ['./cancel-shipment-popup.component.scss']
})
export class CancelShipmentPopupComponent implements OnInit {
  
  checkFlag : boolean = false;
  eligiblityFlag : boolean = true;
  eligibilityMsg : any;


  private readonly notifier: NotifierService;
  constructor(
    public dialogRef: MatDialogRef<CancelShipmentPopupComponent>,
    @Inject(MAT_DIALOG_DATA) public payloadData: any,
    private _http: HttpService,
    public overlay: Overlay,
    private SpinnerService: NgxSpinnerService,
    notifierService: NotifierService,
  ) {
    this.notifier = notifierService;
  }

  ngOnInit(): void {
    this.cancelEligibility();
  }

  onNoClick(): void {
    this.dialogRef.close({event: 'Close', data: false});
  }

  cancelShipment(){
    //eligible for cancelation
    this._http.cancelShipmentList(this.payloadData.data).subscribe((results)=>{
      this.SpinnerService.show();
      let objs = JSON.parse(JSON.stringify(results));
      if(objs.message === "Success"){

        this.notifier.notify("success", "Shipment/s has been canceled successfully!!");
        this.dialogRef.close({event:'Cancelled',data:true});
      }
      else{
        this.notifier.notify("error", "Unable to cancel shipment/s !!");      
      }
      this.SpinnerService.hide();
    });
  }

  cancelWorkOrder(){
    this._http.cancelWorkOrderList(this.payloadData.data).subscribe((results)=>{
      this.SpinnerService.show();
      let objw = JSON.parse(JSON.stringify(results));
      if(objw.message === "Success"){

        this.notifier.notify("success", "Work Order/s has been canceled successfully!!");
        this.dialogRef.close({event:'Cancelled',data:true});
      }
      else{
        this.notifier.notify("error", "Unable to cancel Work Order/s !!");      
      }
      this.SpinnerService.hide();

    });
  }

  cancelEligibility(){

    if(this.payloadData != null){

      if(this.payloadData.type == 'shipment'){
        
        this._http.cancelCheckShipmentList(this.payloadData.data).subscribe((results)=>{
          let obj = JSON.parse(JSON.stringify(results));
          if(obj.message === "ok"){
            this.checkFlag = true;
          }
          else{
            //this.notifier.notify("error", obj.message);
            this.checkFlag = true;
            this.eligiblityFlag = false;
            this.eligibilityMsg = obj.message;          
          }
        })



      }else if(this.payloadData.type == 'workOrder'){
        
        this._http.cancelCheckWorkOrderList(this.payloadData.data).subscribe((results)=>{
          let obj = JSON.parse(JSON.stringify(results));
          if(obj.message === "ok"){
              this.checkFlag = true;
          }
          else{
            //this.notifier.notify("error", obj.message);
            this.checkFlag = true;
            this.eligiblityFlag = false;
            this.eligibilityMsg = obj.message;            
          }
        });

      }
    }   
    
  }
}
