import { Component, OnInit } from '@angular/core';
import { HttpService } from '../http.service';
import { NotifierService } from "angular-notifier";
import { NgxSpinnerService } from "ngx-spinner";
import { MatDialog } from '@angular/material/dialog';
import { Overlay } from '@angular/cdk/overlay';
import { LineSetupUpddatePopupComponent } from '../line-setup-upddate-popup/line-setup-upddate-popup.component';
import { HttpParams } from '@angular/common/http';
import * as moment from 'moment';
import { delay, isEmpty } from 'rxjs/operators';
import {AfterViewInit, ViewChild} from '@angular/core';
import {MatPaginator} from '@angular/material/paginator';
import {MatTableDataSource} from '@angular/material/table';
import { AccountRuleCreatePopupComponent } from '../account-rule-create-popup/account-rule-create-popup.component';
import { AccountRuleDeletePopupComponent } from '../account-rule-delete-popup/account-rule-delete-popup.component';
import { AccountRuleUpdatePopupComponent } from '../account-rule-update-popup/account-rule-update-popup.component';
import { ThrowStmt } from '@angular/compiler';


@Component({
  selector: 'app-account-rule-settings',
  templateUrl: './account-rule-settings.component.html',
  styleUrls: ['./account-rule-settings.component.scss']
})
export class AccountRuleSettingsComponent implements OnInit {

  displayedColumns: string[] = ['Ship To','Store Name','Store Code', 'Type', 'Format', 'Specification','Format FG', 'Format Header','Format Reg','action'];

  dataSource: any = [];
  refreshData: boolean = false;
  isProcessing: Boolean = false;
  singleAccountRule: any = [];


  private readonly notifier: NotifierService;
  constructor(
    notifierService: NotifierService,
    public dialog: MatDialog,
    public overlay: Overlay,
    private _http: HttpService,
    private SpinnerService: NgxSpinnerService
  ) {
    this.notifier = notifierService;
  }


  ngOnInit(): void {

    this.notifier.hideNewest();
    this.getAllAccountRules();

    if (this.refreshData) {
      this.getAllAccountRules();
    }
}


  getAllAccountRules() {
    this.SpinnerService.show();
    this._http.getAllAccountRules().subscribe((data) => {
      this.dataSource = [];
      this.dataSource = data;
      this.SpinnerService.hide();
    },
      err => {
        this.SpinnerService.hide();
        this.notifier.notify('error', 'There was a server error. Please try later!')
      }
    );
  }



deleteAccountRule(shipToId){
  const dialogRef = this.dialog.open(AccountRuleDeletePopupComponent, {
    width: '496px',
    scrollStrategy: this.overlay.scrollStrategies.noop(),
    data: shipToId,
    panelClass: 'custom-modal',
  });

  dialogRef.afterClosed().subscribe(result => {
    console.log('The dialog was closed');
    setTimeout(() => {
      this.getAllAccountRules()
    }, 1000);
  });
}

CreateAccountRule(){
  const dialogRef = this.dialog.open(AccountRuleCreatePopupComponent, {
    width: '496px',
    scrollStrategy: this.overlay.scrollStrategies.noop(),
    panelClass: 'custom-modal',
  });

  dialogRef.afterClosed().subscribe(result => {
    console.log('The dialog was closed');
    setTimeout(() => {
      this.getAllAccountRules()
    }, 1000);
  });
}

UpdateAccountRule(element: any) {

  this.singleAccountRule = element;

    const dialogRef = this.dialog.open(AccountRuleUpdatePopupComponent, {
      width: '496px',
      scrollStrategy: this.overlay.scrollStrategies.noop(),
      data: this.singleAccountRule,
      panelClass: 'custom-modal',
    });

    dialogRef.afterClosed().subscribe(result => {
      console.log('The dialog was closed');
      setTimeout(()=>{ this.getAllAccountRules() }, 1000);
    });
  }
}

