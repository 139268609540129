<div>
  <h4 class="mt-32 title">Custom Goods</h4>
  <div class="mt-32 flexDisplay flexRow spaceBetween">
    <div class="flexDisplay rowDisplay pt-8 pl-16 globalStats">
      <div>
        <div  class="clubKPI">{{printData.woNotPrinted}}</div>
        <div class="mt-16" style="color: #FFCC80">{{'NotPrinted'|translate}}</div>
      </div>
      <div style="color: darkorange;" class="flexDisplay flexColumn ml-16 pt-8 pr-16">
        <div style="align-items: baseline; place-content: space-between;" class="flexDisplay flexRow">
          <span class="label">WIP {{'Limit'|translate}}</span>
          <div>
            <span class="ml-24 boldFont">{{printData.optimalValue}}</span>
          </div>
        </div>

        <div style="align-items: baseline; place-content: space-between;" class="flexDisplay flexRow mt-4">
          <span class="label">{{'BendingRatio'|translate}}</span>
          <div>
            <span class="ml-24 boldFont">{{printData.globalBendingClubs | number:'1.0-0' }}</span>
            <span class="ml-8 boldFont">{{(printData.globalBendingClubs/printData.woNotPrinted)*100 | number:'1.0-0'}}%</span>
          </div>
        </div>

        <div style="align-items: baseline;place-content: space-between;" class="flexDisplay flexRow mt-4">
          <span class="label">{{'Low'|translate}} QTY {{'Ratio'|translate}}</span>
          <div>
            <span class="ml-24 boldFont">{{printData.globalLowClubs | number:'1.0-0'}}</span>
            <span class="ml-8 boldFont">{{(printData.globalLowClubs/printData.woNotPrinted)*100 | number:'1.0-0'}}%</span>
          </div>
        </div>

      </div>
    </div>
    <div style="align-items: flex-end;" class="flexDisplay flexColumn">
      <div>
        <mat-form-field class="printCart mr-16" >
          <mat-label>{{'Printers'|translate}}</mat-label>
          <mat-select disableOptionCentering [(value)]="selectedPrinter" (selectionChange)="hideNotification()">
            <mat-option *ngFor="let printer of printers" [value]="printer.id">
              {{printer.desc}}
            </mat-option>
          </mat-select>
        </mat-form-field>
        <mat-form-field class="printCart">
          <mat-label>{{'PrintCart'|translate}}</mat-label>
          <mat-select disableOptionCentering [(value)]="selectedCart" (selectionChange)="hideNotification()">
            <mat-option *ngFor="let shipment of shipments" [value]="shipment.value">
              {{shipment.viewValue}}
            </mat-option>
          </mat-select>
        </mat-form-field>
        <button class="ml-16" mat-raised-button color="accent" (click)="printCart()">{{'Print'|translate}}</button>
        
      </div>
      <div class="mt-16">
        <mat-slide-toggle [(ngModel)]="isAutoRelease">{{'AutoRelease'|translate}}</mat-slide-toggle>
        <button class="ml-16" (click)="printSingleShipment()" mat-stroked-button>{{'SingleShipment'|translate}}</button>
      </div>
      
    </div>
    
    
  </div>
<ng-container *ngIf="dataSource !== null; else noData">
  <table mat-table [dataSource]="dataSource" class="mt-32 noBackground">

    <ng-container matColumnDef="title">
      <th mat-header-cell *matHeaderCellDef>{{'Line'|translate}}</th>
      <td mat-cell *matCellDef="let element"> {{element.title}} </td>
    </ng-container>

    <ng-container matColumnDef="workOrder">
      <th mat-header-cell *matHeaderCellDef>{{'WorkOrder'|translate}}</th>
      <td mat-cell *matCellDef="let element"> {{element.workOrders!=null ? element.workOrders : '- - -'}} </td>
    </ng-container>

    <ng-container matColumnDef="shipment">
      <th mat-header-cell *matHeaderCellDef>{{'shipments'|translate}}</th>
      <td mat-cell *matCellDef="let element"> {{element.shipments != '' ? element.shipments : '- - -'}} </td>
    </ng-container>

      <!-- <ng-container matColumnDef="clubsInCell">
        <th mat-header-cell *matHeaderCellDef>{{'Pending'|translate}}</th>
        <td mat-cell *matCellDef="let element"> {{element.clubsInCell}} </td>
      </ng-container> -->


    <ng-container matColumnDef="clubsQueued">
      <th mat-header-cell *matHeaderCellDef>{{'Clubs' | translate}} {{'InProgress'|translate}}</th>
      <td class="lineClubKPI" mat-cell *matCellDef="let element"> {{element.clubsQueued + element.clubsInCell}} </td>
    </ng-container>


    <!-- <ng-container matColumnDef="clubsCompleted">
      <th mat-header-cell *matHeaderCellDef>{{'Completed'|translate}}</th>
      <td mat-cell *matCellDef="let element"> {{element.clubsCompleted}} </td>
    </ng-container> -->

    <ng-container matColumnDef="bending">
      <th mat-header-cell *matHeaderCellDef>{{'Bending' | translate}}</th>
      <td style="font-size: 18px;" mat-cell *matCellDef="let element">
        <span class="mr-16">{{element.clubsBending}}</span>
        <span class="mr-16">|</span> 
        {{(element.clubsBending/(element.clubsQueued + element.clubsInCell))*100 | number:'1.0-0'}}%
      </td>
    </ng-container>

    <ng-container matColumnDef="lowQty">
      <th mat-header-cell *matHeaderCellDef>{{'Low'|translate}} Qty</th>
      <td style="font-size: 18px;" mat-cell *matCellDef="let element">
        <span class="mr-16">{{element.lowClubOrders}}</span>
        <span class="mr-16">|</span> 
        {{(element.lowClubOrders/(element.clubsQueued + element.clubsInCell))*100 | number:'1.0-0'}}%</td>
    </ng-container>

    <ng-container matColumnDef="stress">
      <th mat-header-cell *matHeaderCellDef>{{'Stress'|translate}}</th>
      <td mat-cell *matCellDef="let element">
        <ng-container *ngIf="((element.lowClubOrders/(element.clubsQueued + element.clubsInCell))*100 + (element.clubsBending/(element.clubsQueued + element.clubsInCell))*100) < 100; else stressAbnormal" >
          
          <span class="stress normal">{{((element.lowClubOrders/(element.clubsQueued + element.clubsInCell))*100 + (element.clubsBending/(element.clubsQueued + element.clubsInCell))*100) | number:'1.0-0'}}%</span>
          
        </ng-container>

        <ng-template #stressAbnormal>
          <ng-container *ngIf="((element.lowClubOrders/(element.clubsQueued + element.clubsInCell))*100 + (element.clubsBending/(element.clubsQueued + element.clubsInCell))*100) < 175; else stressDanger">
            <span class="stress warning">{{((element.lowClubOrders/(element.clubsQueued + element.clubsInCell))*100 + (element.clubsBending/(element.clubsQueued + element.clubsInCell))*100) | number:'1.0-0'}}%</span>
          </ng-container>
        </ng-template>
        <ng-template #stressDanger>
          <span class="stress danger">{{((element.lowClubOrders/(element.clubsQueued + element.clubsInCell))*100 + (element.clubsBending/(element.clubsQueued + element.clubsInCell))*100) | number:'1.0-0'}}%</span>
        </ng-template>
        </td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
  </table>
</ng-container>
<ng-template #noData>
  <div>
    Error
  </div>
</ng-template>
</div>



<ngx-spinner bdColor="rgba(51, 51, 51, 0.8)" size="default" type="ball-spin-clockwise">  
  <p style="color: white">Please Wait. </p>  
</ngx-spinner> 
