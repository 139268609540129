
    <div class="header" role="banner">
    <img
      width="200"
      alt="Angular Logo"
      src="../../assets/images/tm_white_transparent_cropped.png"
    />
    <span>Capacity Planning</span>
    <div class="spacer"></div>
    <nav>
        <ul>

          <!-- <li>
            <a mat-button [routerLink]="''" routerLinkActive="activeLink" >Home</a>
          </li>
          <li>
            <a mat-button [routerLink]="'/line-management'" routerLinkActive="activeLink" >Line Management</a>
          </li>
          <li>
            <a mat-button [routerLink]="'/prints'" routerLinkActive="activeLink">Prints</a>
          </li>
          <li>
            <a mat-button [routerLink]="'/printing-queue'" routerLinkActive="activeLink">Printing Queue</a>
          </li>
          <li>
            <a mat-button [routerLink]="'/kpis'" routerLinkActive="activeLink"> Search</a>
          </li> -->
          <!-- <li>
                 <a class="logout-btn" href="#" routerLink="" routerLinkActive="activeLink">Logout</a>
          </li> -->
        </ul>
    </nav>  
    <span class="form-inline">
      <!-- <select 
          class="form-control" 
          #selectedLang 
          (change)="switchLang(selectedLang.value)">
        <option *ngFor="let language of translate.getLangs()" 
          [value]="language"
          [selected]="language === translate.currentLang">
          {{ language }}
        </option>
      </select> -->
    </span>
  </div>

  