import { Component, OnInit, Inject } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { FormBuilder, FormArray, FormGroup } from '@angular/forms';
import { HttpService } from '../http.service';
import { Overlay } from '@angular/cdk/overlay';
import { NotifierService } from "angular-notifier";
import { isNull } from '@angular/compiler/src/output/output_ast';
import { NgxSpinnerService } from 'ngx-spinner';
import { stringify } from '@angular/compiler/src/util';


@Component({
  selector: 'app-account-rule-update-popup',
  templateUrl: './account-rule-update-popup.component.html',
  styleUrls: ['./account-rule-update-popup.component.scss']
})
export class AccountRuleUpdatePopupComponent implements OnInit {

  private readonly notifier: NotifierService;
  parsedData: any;

 
  constructor(private fb: FormBuilder,
    private _http: HttpService,
    notifierService: NotifierService,
    private SpinnerService: NgxSpinnerService,
    public dialogRef: MatDialogRef<AccountRuleUpdatePopupComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any) { 
      this.notifier = notifierService;
      this.parsedData = data;
  }

  ngOnInit(): void {
  
  }

  saveAccountRules(){

    let payload = {
      "shiP_TO": this.parsedData.shiP_TO,
      "labeL_TYPE": this.parsedData.labeL_TYPE,
      "storE_NAME": this.parsedData.storE_NAME,
      "storE_CODE": this.parsedData.storE_CODE,
      "labeL_FORMAT": this.parsedData.labeL_FORMAT,
      "specificatioN_CMT": this.parsedData.specificatioN_CMT,
      "labeL_FORMAT_FG": this.parsedData.labeL_FORMAT_FG,
      "labeL_FORMAT_HEADER": this.parsedData.labeL_FORMAT_HEADER,
      "labeL_FORMAT_REG": this.parsedData.labeL_FORMAT_REG
    }
    
    if (this.parsedData != null) {

      if (this.parsedData.shiP_TO !== "" &&
          this.parsedData.labeL_TYPE !== "" &&
          this.parsedData.storE_NAME !== "" &&
          this.parsedData.storE_CODE !== "" &&
          this.parsedData.labeL_FORMAT !== "" &&
          this.parsedData.specificatioN_CMT !== "" &&
          this.parsedData.labeL_FORMAT_FG !== "" &&
          this.parsedData.labeL_FORMAT_HEADER !== "" &&
          this.parsedData.labeL_FORMAT_REG !== ""
        ){

       this.SpinnerService.show();
       this._http.updateAccountRule(payload).subscribe((results)=>{
        if(results){
          this.notifier.notify('success', 'Account Rule has been updated successfully!');
          this.SpinnerService.hide();
          this.dialogRef.close;
        }
      },
      err => {
        this.SpinnerService.hide();
        this.notifier.notify('error', 'There was a server error. Please try later!')
      });
    }

    else
      this.notifier.notify('warning', `Account Rule can't be left empty!`);
      this.dialogRef.close;
    
     }
      
  }

  onNoClick(): void {
    this.dialogRef.close();
  }

}
