<h5 mat-dialog-title>{{'Create'|translate}} {{'Line'|translate}} {{'Setup'|translate}} {{'Value'|translate}}</h5>


  <div mat-dialog-content class="columnStyling" style="max-height: 350px;">
    <mat-form-field class="mr-24" style="width: 200px;">
        <mat-label>{{'Line Setup'|translate}}</mat-label>
        <mat-select  [(value)]="selectedLineSetup">
          <mat-option *ngFor="let item of lineSetup" [value]="item.id">
            {{item.title}}
          </mat-option>
        </mat-select>
      </mat-form-field>
    <mat-form-field class="mr-24" style="width: 200px;">
        <mat-label>{{'Title'|translate}}</mat-label>
        <input type="text" matInput placeholder="Line Setup title" [(ngModel)]="title">
    </mat-form-field>
    <mat-form-field class="mr-24" style="width: 200px;">
      <mat-label>{{'Key'|translate}} {{'Name'|translate}}</mat-label>
      <input type="text" matInput placeholder="Key Name" [(ngModel)]="keyName">
  </mat-form-field>
  <mat-form-field class="mr-24" style="width: 200px;">
    <mat-label>{{'Key'|translate}} {{'Name'|translate}} FG </mat-label>
    <input type="text" matInput placeholder="Key Name FG" [(ngModel)]="keyNameFG">
</mat-form-field>
  </div>

<div mat-dialog-actions>
  <button mat-button (click)="onNoClick()">{{'Cancel'|translate}}</button>
  <button mat-button [mat-dialog-close]="" cdkFocusInitial (click)="saveLineSetupValues()">{{'Save'|translate}}</button>
</div>
