<h5 mat-dialog-title>
  {{ "Capacity" | translate }} {{ "Settings" | translate }}
</h5>
<span class="linePill">{{ line }}</span>
<form [formGroup]="formGroup">
  <div mat-dialog-content class="rowStyling mt-16">
    <div class="flexDisplay w-100-p">
      <button
        mat-mini-fab
        class="noShadow"
        color="primary"
        aria-label="subtract btn"
        (click)="decreaseCapacity()"
      >
        <mat-icon>remove</mat-icon>
      </button>
      <mat-form-field class="ml-16 mr-16 flexFull">
        <mat-label>{{ "MaximumCapacity" | translate }}</mat-label>
        <input
          type="number"
          onClick="this.select()"
          onfocus="this.select()"
          autofocus="true"
          (keypress)="prevent($event)"
          matInput
          placeholder="Ex. any number"
          formControlName="maxCapacity"
        />
      </mat-form-field>
      <button
        mat-mini-fab
        class="noShadow"
        color="primary"
        (click)="increaseCapacity()"
      >
        <mat-icon>add</mat-icon>
      </button>
    </div>
  </div>

  <div mat-dialog-content class="rowStyling mt-16">
    <div class="flexDisplay w-100-p">
      <button
        mat-mini-fab
        class="noShadow"
        color="primary"
        aria-label="subtract btn"
        (click)="decreaseTarget()"
      >
        <mat-icon>remove</mat-icon>
      </button>
      <mat-form-field class="ml-16 mr-16 flexFull">
        <mat-label>{{ "Output Target" | translate }}</mat-label>
        <input
          type="number"
          onClick="this.select()"
          onfocus="this.select()"
          autofocus="true"
          (keypress)="prevent($event)"
          matInput
          placeholder="Ex. any number"
          formControlName="outputTarget"
        />
      </mat-form-field>
      <button
        mat-mini-fab
        class="noShadow"
        color="primary"
        (click)="increaseTarget()"
      >
        <mat-icon>add</mat-icon>
      </button>
    </div>
  </div>

  <div *ngIf="manufacturingType !== 'Finished Goods'">
    <mat-label>{{ "BendingRatio" | translate }}</mat-label>
    <ng-container
      *ngIf="this.formGroup.value.bendingRatio === 0; else notBZero"
    >
      <span class="fr">{{ "Disabled" | translate }}</span>
    </ng-container>
    <ng-template #notBZero>
      <ng-container
        *ngIf="this.formGroup.value.bendingRatio === 100; else midBValue"
      >
        <span class="fr">{{ "Unlimited" | translate }}</span>
      </ng-container>
      <ng-template #midBValue>
        <span class="fr">{{ this.formGroup.value.bendingRatio + "%" }}</span>
      </ng-template>
    </ng-template>
    <br />
    <mat-slider
      class="mt-24"
      thumbLabel
      tickInterval="1"
      min="0"
      max="100"
      formControlName="bendingRatio"
    ></mat-slider>
  </div>

  <br />

  <div *ngIf="manufacturingType !== 'Finished Goods'">
    <mat-label
      >{{ "Low" | translate }} Qty. {{ "Ratio" | translate }}</mat-label
    >
    <ng-container *ngIf="this.formGroup.value.qualityRatio === 0; else notZero">
      <span class="fr">{{ "Disabled" | translate }}</span>
    </ng-container>
    <ng-template #notZero>
      <ng-container
        *ngIf="this.formGroup.value.qualityRatio === 100; else midValue"
      >
        <span class="fr">{{ "Unlimited" | translate }}</span>
      </ng-container>
      <ng-template #midValue>
        <span class="fr">{{ this.formGroup.value.qualityRatio + "%" }}</span>
      </ng-template>
    </ng-template>

    <mat-slider
      class="mt-24"
      thumbLabel
      tickInterval="1"
      min="0"
      max="100"
      formControlName="qualityRatio"
    ></mat-slider>
  </div>

  <div *ngIf="manufacturingType === 'Finished Goods'">
    <mat-label
      >{{ "Low" | translate }} Qty. {{ "Ratio" | translate }}</mat-label
    >
    <div class="mt-16">
      <mat-radio-group
        (change)="updateFinishedGoods()"
        [ngModelOptions]="{ standalone: true }"
        [(ngModel)]="lowQtyRatio"
      >
        <mat-radio-button [value]="true">Yes</mat-radio-button>
        <mat-radio-button class="ml-16" [value]="false">No</mat-radio-button>
      </mat-radio-group>
    </div>
  </div>
</form>

<div class="flexDisplay flexRow spaceBetween mt-16">
  <div>
    <mat-label>{{ "SBL" | translate }}</mat-label>
    <div class="mt-16">
      <mat-radio-group [(ngModel)]="sblNulls">
        <mat-radio-button [value]="true">Yes</mat-radio-button>
        <mat-radio-button class="ml-16" [value]="false">No</mat-radio-button>
      </mat-radio-group>
    </div>
  </div>

  <div class="mr-8">
    <mat-label>{{ "TW" | translate }}</mat-label>
    <div class="mt-16">
      <mat-radio-group [(ngModel)]="tw">
        <mat-radio-button [value]="true">Yes</mat-radio-button>
        <mat-radio-button class="ml-16" [value]="false">No</mat-radio-button>
      </mat-radio-group>
    </div>
  </div>
</div>
<div class="flexDisplay flexRow spaceBetween mt-32">
  <div>
    <mat-label>{{ "weightChangeRequired" | translate }}</mat-label>
    <div class="mt-16">
      <mat-radio-group [(ngModel)]="weightChange">
        <mat-radio-button [value]="true">Yes</mat-radio-button>
        <mat-radio-button class="ml-16" [value]="false">No</mat-radio-button>
      </mat-radio-group>
    </div>
  </div>
</div>

<div mat-dialog-actions class="mt-16">
  <button mat-button (click)="onNoClick()">{{ "Cancel" | translate }}</button>
  <button
    mat-button
    [mat-dialog-close]=""
    (click)="updateCapacitySettings()"
    cdkFocusInitial
  >
    {{ "Save" | translate }}
  </button>
</div>
