<div>
  <h4 class="mt-32 title">{{ "FinishedGoods" | translate }}</h4>
  <div class="mt-32 flexDisplay flexRow spaceBetween">
    <div class="flexDisplay flexRow">
      <div class="flexDisplay rowDisplay pt-8 pl-16 globalStats">
        <div>
          <div class="clubKPI">{{ printData.notPrintedCount }}</div>
          <div class="mt-16" style="color: #ffcc80">
            {{ "NotPrinted" | translate }}
          </div>
        </div>
        <div
          style="color: darkorange"
          class="flexDisplay flexColumn ml-16 pt-8 pr-16"
        >
          <div
            style="align-items: baseline; place-content: space-between"
            class="flexDisplay flexRow"
          >
            <span class="label">{{ "Optimal" | translate }} WIP</span>
            <div>
              <span class="ml-24 boldFont">{{ printData.wipLimit }}</span>
            </div>
          </div>

          <div
            style="align-items: baseline; place-content: space-between"
            class="flexDisplay flexRow mt-4"
          >
            <span class="label">{{ "HardGoods" | translate }}</span>
            <div>
              <span class="ml-24 boldFont">{{
                printData.totalHardGoods | number : "1.0-0"
              }}</span>
              <span class="ml-8 boldFont"
                >{{
                  (printData.totalHardGoods / printData.notPrintedCount) * 100
                    | number : "1.0-0"
                }}%</span
              >
            </div>
          </div>

          <div
            style="align-items: baseline; place-content: space-between"
            class="flexDisplay flexRow mt-4"
          >
            <span class="label">{{ "Samples" | translate }}</span>
            <div>
              <span class="ml-24 boldFont">{{
                printData.totalSamples | number : "1.0-0"
              }}</span>
              <span class="ml-8 boldFont"
                >{{
                  (printData.totalSamples / printData.notPrintedCount) * 100
                    | number : "1.0-0"
                }}%</span
              >
            </div>
          </div>
        </div>
      </div>

      <div
        class="flexDisplay flexColumn pt-8 ml-16 pl-16 globalStats"
        style="place-content: baseline"
      >
        <span class="required">{{ "RequiredContainers" | translate }}</span>

        <div
          style="
            color: darkorange;
            flex-wrap: wrap;
            width: 268px;
            font-size: 14px;
          "
          class="flexDisplay flexRow pt-8 pr-16"
        >
          <div
            style="align-items: baseline; place-content: space-between"
            class="flexDisplay flexColumn mr-16"
            *ngFor="let item of printData.containerTypes"
          >
            <span class="label">{{ item.type }}</span>
            <div>
              <span class="boldFont">{{ item.quantity }}</span>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div style="align-items: flex-end" class="flexDisplay flexColumn">
      <div>
        <mat-form-field class="printCart mr-16">
          <mat-label>{{ "Printers" | translate }}</mat-label>
          <mat-select
            disableOptionCentering
            [(value)]="selectedPrinter"
            (selectionChange)="hideNotification()"
          >
            <mat-option *ngFor="let printer of printers" [value]="printer.id">
              {{ printer.desc }}
            </mat-option>
          </mat-select>
        </mat-form-field>
        <mat-form-field class="printCart">
          <mat-label>{{ "PickZones" | translate }}</mat-label>
          <mat-select
            disableOptionCentering
            [(value)]="selectedZone"
            (selectionChange)="hideNotification()"
          >
            <mat-option *ngFor="let zone of pickZone" [value]="zone.name">
              {{ zone.name }}
            </mat-option>
          </mat-select>
        </mat-form-field>
        <mat-form-field class="printCart ml-16">
          <mat-label>{{ "ZoneNumber" | translate }}</mat-label>
          <mat-select
            disableOptionCentering
            multiple
            [(value)]="selectedZoneNumber"
            (selectionChange)="hideNotification()"
            [disabled]="selectedZone === 'All'"
          >
            <mat-option
              *ngFor="let zoneNum of zoneNumbers"
              [value]="zoneNum.number"
            >
              {{ zoneNum.number }}
            </mat-option>
          </mat-select>
        </mat-form-field>
        <mat-form-field class="printCart ml-16">
          <mat-label>{{ "Containers" | translate }}</mat-label>
          <mat-select
            disableOptionCentering
            [(value)]="selectedContainer"
            (selectionChange)="hideNotification()"
          >
            <mat-option
              *ngFor="let container of containers"
              [value]="container.value"
            >
              {{ container.viewValue }}
            </mat-option>
          </mat-select>
        </mat-form-field>
        <button
          class="ml-16"
          mat-raised-button
          color="accent"
          (click)="printFGCart()"
        >
          {{ "Print" | translate }}
        </button>
      </div>
      <div class="mt-16">
        <!--<mat-slide-toggle [(ngModel)]="isAutoRelease">{{'AutoRelease'|translate}}</mat-slide-toggle>-->
        <button
          class="ml-16"
          (click)="printSingleShipment()"
          mat-stroked-button
        >
          {{ "SingleShipment" | translate }}
        </button>
      </div>
    </div>
  </div>
  <ng-container *ngIf="dataSource !== null; else noData">
    <table mat-table [dataSource]="dataSource" class="mt-32 noBackground">
      <ng-container matColumnDef="title">
        <th mat-header-cell *matHeaderCellDef>{{ "Line" | translate }}</th>
        <td mat-cell *matCellDef="let element">{{ element.line }}</td>
      </ng-container>

      <ng-container matColumnDef="containers">
        <th mat-header-cell *matHeaderCellDef>
          {{ "Containers" | translate }}
        </th>
        <td mat-cell *matCellDef="let element">
          {{ element.containers != null ? element.containers : "- - -" }}
        </td>
      </ng-container>

      <ng-container matColumnDef="hardGoods">
        <th mat-header-cell *matHeaderCellDef>{{ "HardGoods" | translate }}</th>
        <td mat-cell *matCellDef="let element">
          {{ element.hardGoods != "" ? element.hardGoods : "- - -" }}
        </td>
      </ng-container>

      <ng-container matColumnDef="softGoods">
        <th mat-header-cell *matHeaderCellDef>{{ "Samples" | translate }}</th>
        <td mat-cell *matCellDef="let element">{{ element.samples }}</td>
      </ng-container>
      <ng-container matColumnDef="accessories">
        <th mat-header-cell *matHeaderCellDef>{{ "Other" | translate }}</th>
        <td mat-cell *matCellDef="let element">
          {{ element.accessories != "" ? element.accessories : 0 }}
        </td>
      </ng-container>

      <ng-container matColumnDef="finishedGoods">
        <th mat-header-cell *matHeaderCellDef>
          {{ "FinishedGoods" | translate }}
        </th>
        <td class="lineClubKPI" mat-cell *matCellDef="let element">
          {{ element.finishedGoods }}
        </td>
      </ng-container>

      <!-- <ng-container matColumnDef="bending">
        <th mat-header-cell *matHeaderCellDef>{{'Bending' | translate}}</th>
        <td style="font-size: 18px;" mat-cell *matCellDef="let element">
          <span class="mr-16">{{element.clubsBending}}</span>
          <span class="mr-16">|</span> 
          {{(element.clubsBending/(element.clubsQueued + element.clubsInCell))*100 | number:'1.0-0'}}%
        </td>
      </ng-container> -->

      <!-- <ng-container matColumnDef="lowQty">
        <th mat-header-cell *matHeaderCellDef>{{'Low'|translate}} Qty</th>
        <td style="font-size: 18px;" mat-cell *matCellDef="let element">
          <span class="mr-16">{{element.lowClubOrders}}</span>
          <span class="mr-16">|</span> 
          {{(element.lowClubOrders/(element.clubsQueued + element.clubsInCell))*100 | number:'1.0-0'}}%</td>
      </ng-container> -->

      <!-- <ng-container matColumnDef="stress">
        <th mat-header-cell *matHeaderCellDef>{{'Stress'|translate}}</th>
        <td mat-cell *matCellDef="let element">
          <ng-container *ngIf="((element.lowClubOrders/(element.clubsQueued + element.clubsInCell))*100 + (element.clubsBending/(element.clubsQueued + element.clubsInCell))*100) < 100; else stressAbnormal" >
            
            <span class="stress normal">{{((element.lowClubOrders/(element.clubsQueued + element.clubsInCell))*100 + (element.clubsBending/(element.clubsQueued + element.clubsInCell))*100) | number:'1.0-0'}}%</span>
            
          </ng-container>
  
          <ng-template #stressAbnormal>
            <ng-container *ngIf="((element.lowClubOrders/(element.clubsQueued + element.clubsInCell))*100 + (element.clubsBending/(element.clubsQueued + element.clubsInCell))*100) < 175; else stressDanger">
              <span class="stress warning">{{((element.lowClubOrders/(element.clubsQueued + element.clubsInCell))*100 + (element.clubsBending/(element.clubsQueued + element.clubsInCell))*100) | number:'1.0-0'}}%</span>
            </ng-container>
          </ng-template>
          <ng-template #stressDanger>
            <span class="stress danger">{{((element.lowClubOrders/(element.clubsQueued + element.clubsInCell))*100 + (element.clubsBending/(element.clubsQueued + element.clubsInCell))*100) | number:'1.0-0'}}%</span>
          </ng-template>
          </td>
      </ng-container> -->

      <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
    </table>
  </ng-container>
  <ng-template #noData>
    <div>Error</div>
  </ng-template>
</div>

<ngx-spinner
  bdColor="rgba(51, 51, 51, 0.8)"
  size="default"
  type="ball-spin-clockwise"
>
  <p style="color: white">Please Wait.</p>
  <div *ngIf="isPrinting" class="overlay-printer-center">
    <div class="mt-24">
      <p class="fs-app-13">
        Please do not close the browser until the release is finished. If the
        process is stuck, you can cancel the operation and try again:
      </p>
      <button
        class="printer-btn-cancel mat-focus-indicator mat-stroked-button mat-button-base"
        (click)="onCloseSpinner()"
      >
        <span class="mat-button-wrapper"> Cancel </span>
      </button>
    </div>
  </div>
</ngx-spinner>
