<h5 mat-dialog-title>{{'Update'|translate}} {{'Account'|translate}} {{'Rule'|translate}}</h5>


  <div mat-dialog-content class="columnStyling" style="max-height: 350px;">
    <mat-form-field class="mr-24" style="width: 200px;">
        <mat-label>{{'Ship To'|translate}}</mat-label>
        <input type="text" matInput [(ngModel)]="parsedData.shiP_TO">
    </mat-form-field>
    <mat-form-field class="mr-24" style="width: 200px;">
      <mat-label>{{'Label'|translate}} {{'Type'|translate}}</mat-label>
      <input type="text" matInput  [(ngModel)]="parsedData.labeL_TYPE">
  </mat-form-field>
  <mat-form-field class="mr-24" style="width: 200px;">
    <mat-label>{{'Store'|translate}} {{'Name'|translate}} </mat-label>
    <input type="text" matInput [(ngModel)]="parsedData.storE_NAME">
</mat-form-field>
<mat-form-field class="mr-24" style="width: 200px;">
    <mat-label>{{'Store'|translate}} {{'Code'|translate}} </mat-label>
    <input type="text" matInput [(ngModel)]="parsedData.storE_CODE">
</mat-form-field>
<mat-form-field class="mr-24" style="width: 200px;">
    <mat-label>{{'Label'|translate}} {{'Format'|translate}} </mat-label>
    <input type="text" matInput [(ngModel)]="parsedData.labeL_FORMAT">
</mat-form-field>
<mat-form-field class="mr-24" style="width: 200px;">
    <mat-label>{{'Specification'|translate}} {{'Comment'|translate}}</mat-label>
    <input type="text" matInput [(ngModel)]="parsedData.specificatioN_CMT">
</mat-form-field>
<mat-form-field class="mr-24" style="width: 200px;">
    <mat-label>{{'Label'|translate}} {{'Format'|translate}} FG</mat-label>
    <input type="text" matInput [(ngModel)]="parsedData.labeL_FORMAT_FG">
</mat-form-field>
<mat-form-field class="mr-24" style="width: 200px;">
    <mat-label>{{'Label'|translate}} {{'Format'|translate}} {{'Header'|translate }} </mat-label>
    <input type="text" matInput [(ngModel)]="parsedData.labeL_FORMAT_HEADER">
</mat-form-field>
<mat-form-field class="mr-24" style="width: 200px;">
    <mat-label>{{'Label'|translate}} {{'Format'|translate}} REG </mat-label>
    <input type="text" matInput [(ngModel)]="parsedData.labeL_FORMAT_REG">
</mat-form-field>
  </div>

<div mat-dialog-actions>
  <button mat-button (click)="onNoClick()">{{'Cancel'|translate}}</button>
  <button mat-button [mat-dialog-close]="" cdkFocusInitial (click)="saveAccountRules()">{{'Save'|translate}}</button>
</div>
