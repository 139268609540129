<h5 mat-dialog-title>{{'Add'|translate}} {{'Custom'|translate}} {{'Settings'|translate}}</h5>
<div *ngIf= "showValidationMsg" >
  <p style="color: red; font-size: small;">*Please select both values</p>
  <div *ngFor="let item of validationErrors">
    <li style="color: red; font-size: smaller;">{{item.title}}</li>
  </div>
  <br>
</div>
<form [formGroup]="customSettingsForm">
  <div formArrayName="settings" mat-dialog-content class="rowStyling" style="max-height: 318px;">
    <div class="noData" *ngIf="!settings.length">
      <i style="color: #bdbdbd; font-size: 64px;" class="fa fa-cogs mb-16"></i>
      {{'NoCustomSettings'|translate}}!    
    </div>
    <div *ngFor="let setting of customSettingData; let i=index" [formGroupName]="i" class="flexDisplay w-100-p">
      <mat-form-field class="inputStyle pr-8">
        <mat-label>{{setting.title}}</mat-label>
        <mat-select disableOptionCentering multiple formControlName="selectedvalues" (ngModelChange)="ValidateForm(i)">
          <mat-option (click)="toggleAllSelection(i)" [value]="0">All</mat-option>
          <mat-option *ngFor="let item of setting.dropdown" [value]="item.id">{{item.title}}</mat-option>
        </mat-select>
      </mat-form-field>
      <mat-form-field class="inputStyle pl-8">
        <mat-label>{{'Rule'|translate}}</mat-label>
        <mat-select disableOptionCentering formControlName="rules" (ngModelChange)="ValidateForm(i)">
          <mat-option *ngFor="let item of rulesData" [value]="item.value">{{item.viewValue}}</mat-option>
        </mat-select>
      </mat-form-field>
    </div>

  </div>
</form>
<div mat-dialog-actions>
  <button mat-button (click)="onNoClick()">{{'Cancel'|translate}}</button>
  <button *ngIf="settings.length" [mat-dialog-close]="" (click)="updateCustomSettings()" mat-button cdkFocusInitial [disabled]="disableSaveBtn">{{'Save'|translate}}</button>
</div>

<!-- <div>Details are: {{customSettingsForm.value | json}}</div> -->
