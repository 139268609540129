<div class="w-100 menu-bar">
  
    <nav>
      <ul>
        <li routerLinkActive="activePage">

          <a [href]="closeURL" style="color: white; text-underline-position: under;" class="fa fa-power-off icon-size-xx" id="closeBtnV2">
          </a>
          <a mat-button [href]="closeURL">{{'Close'|translate}}</a>
      </li>
      </ul>
    
    </nav>  

</div>