<h5 mat-dialog-title class="pl-24 pt-24 pb-16">{{'Serial'|translate}} {{'Numbers'|translate}}</h5>
<ng-container *ngIf="dataSource.length !== 0; else noData">
    <div class="pl-24 pb-24">
        <ul>
        <strong><p *ngFor="let serial of dataSource;" class="noPadding">
            {{serial}}
        </p></strong>
    </ul>
    </div>
</ng-container>
<ng-template #noData>
    <div class="pl-24 pb-24 pt-24">
        There are currently no Serials available
    </div>
</ng-template>

<div mat-dialog-actions class="noMarginBottom">
    <button class="ml-24" mat-button (click)="onNoClick()">{{'Close'|translate}}</button>
  </div>