import { Component, OnInit, Inject } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { FormBuilder, FormArray, FormGroup, Validators } from '@angular/forms';
import { HttpService } from '../http.service';
import { Overlay } from '@angular/cdk/overlay';
import { NotifierService } from "angular-notifier";
import { getMatIconFailedToSanitizeLiteralError } from '@angular/material/icon';
import { ContentObserver } from '@angular/cdk/observers';

export interface CustomSettingData {
  id: number,
  title: string;
  selectedvalues: any[];
  dropdown: object[];
  rules: string;
}

@Component({
  selector: 'app-custom-settings-popup',
  templateUrl: './custom-settings-popup.component.html',
  styleUrls: ['./custom-settings-popup.component.scss'],
})

export class CustomSettingsPopupComponent implements OnInit {

  
  disableSaveBtn: boolean;
  showValidationMsg: boolean;
  validationErrors = [];
  selectAll = false;
  rulesData = [{ viewValue: 'Strict', value: 'Strict' }, { viewValue: 'Add-on', value: 'Add-on' },{ viewValue: 'Ignore', value: 'Ignore' },{ viewValue: 'Clear Selection', value: null }];
  customSettingData: any = [];
  private readonly notifier: NotifierService;

  rows: FormArray = this.fb.array([]);
  customSettingsForm: FormGroup = this.fb.group({ 'settings': this.rows });

  get settings() {
    return this.customSettingsForm.get('settings') as FormArray;
  }

  setSetting(rowData?: CustomSettingData) {
    const row = this.fb.group({
      'id': [rowData && rowData.id ? rowData.id : null, []],
      'title': [rowData && rowData.title ? rowData.title : null, []],
      'selectedvalues': [rowData && rowData.selectedvalues ? rowData.selectedvalues.map(a => a.id) : null, []],
      'dropdown': [rowData && rowData.dropdown ? rowData.dropdown : null, [] ],
      'rules': [rowData && rowData.rules ? rowData.rules : null, []]
    })
    this.rows.push(row);
  }

  constructor(
    private fb: FormBuilder,
    private _http: HttpService,
    notifierService: NotifierService,
    public dialogRef: MatDialogRef<CustomSettingsPopupComponent>,

    @Inject(MAT_DIALOG_DATA) public data: any) {
      this.notifier = notifierService;
      this.customSettingData = data;
  }

  onNoClick(): void {
    this.dialogRef.close();
  }

  toggleAllSelection(index: any) {

    if (!this.selectAll) {
      this.selectAll = true;
      this.customSettingsForm.controls.settings["controls"][index]["controls"].selectedvalues.patchValue([...this.customSettingData[index].dropdown.map(item => item.id),0]);

    } else {
      this.selectAll = false;
      this.customSettingsForm.controls.settings["controls"][index]["controls"].selectedvalues.patchValue([]);
      this.parseForm();
    }    
    
  }

  updateCustomSettings(){
    
    let setupList = [];
    let setupListValues = [];
    
        let formData = this.customSettingsForm.value;
      //populating dynamic lineSetupList
      for(let i=0; i<formData.settings.length;i++ ){
        setupList.push({lineSetupId: formData.settings[i].id, rules: formData.settings[i].rules});
          if(formData.settings[i].selectedvalues !== null)
          setupListValues.push(...formData.settings[i].selectedvalues);
        
      }
    
      setupListValues = setupListValues.filter(value => value !== 0);

      
    let payload = {
      cellId: this.customSettingData.id,
      lineSetupList: setupList,
      lineSetupValues: setupListValues
    }

    //this.notifier.notify('success', 'Success in creating payload!!')
    
    this._http.updateCustomSettings(payload).subscribe(result=>{
      this.notifier.notify('success', 'Custom settings are updated successfully!')
    },
    err=>{
      this.notifier.notify('error', 'There was a server error. Please try later!')
    });
  }

  ValidateForm(index: any){

    let formData = this.customSettingsForm.value;  
      if((formData.settings[index].selectedvalues.length > 0 && formData.settings[index].rules == null) || (formData.settings[index].selectedvalues.length == 0 && formData.settings[index].rules != null)){
        this.disableSaveBtn = true;
        this.showValidationMsg = false;
        this.parseForm();
      }else{
        if (this.parseForm()) {
          this.disableSaveBtn = false;
        }  
      }
  }

  parseForm(){
          
    //checking the complete form
          let formData = this.customSettingsForm.value;
          let validationList = [];
          for(let i=0; i<formData.settings.length;i++ ){
            if ((formData.settings[i].selectedvalues.length > 0 && formData.settings[i].rules == null) || (formData.settings[i].selectedvalues.length == 0 && formData.settings[i].rules != null)) {
              validationList.push({lineSetupTitle: formData.settings[i].title});
            }
          }
      
          if (validationList.length == 0) {
      
            this.disableSaveBtn = false;
            this.showValidationMsg = false;
            return true;
           
          }else{
            this.disableSaveBtn = true;
            this.validationErrors = [];
            validationList.forEach(element => {
            this.validationErrors.push({title: element.lineSetupTitle});
            });
            this.showValidationMsg = true;
            return false;
          }
  }

  
  ngOnInit() {
   
    this.showValidationMsg= false;

    for(let i=0; i<this.customSettingData.length; i++){
      this._http.getDropdownData(this.customSettingData[i].id).subscribe(data=>{
        this.customSettingData[i].dropdown = data;    
      })
    }
    
    this.customSettingData.forEach((rowData: CustomSettingData) => this.setSetting(rowData));
    this.parseForm();
  }
}
