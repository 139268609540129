<mat-form-field class="mt-32 mr-16" style="width: 232px;">
    <mat-label>{{'Printers'|translate}}</mat-label>
    <mat-select disableOptionCentering [(value)]="selectedPrinter">
      <mat-option *ngFor="let printer of printers" [value]="printer.id">
        {{printer.desc}}
      </mat-option>
    </mat-select>
    </mat-form-field>
  <mat-form-field class="mt-32" style="width: 136px;">
    <mat-label>{{'Lead Time'|translate}}</mat-label>
        <mat-select disableOptionCentering [(value)]="selectedLeadTime">
        <mat-option value="any">{{'Any'|translate}}</mat-option>
        <mat-option value="at risk">{{'AtRisk'|translate}}</mat-option>
        <mat-option value="overdue">{{'Overdue'|translate}}</mat-option>
        </mat-select>
    </mat-form-field>
  
    
  <button class="mr-16 alignSelfCenter fr mt-32 successColor" (click)="exportPrintQueue()" mat-raised-button>
    <span class="material-icons mr-8"> download</span>
    {{'Export'|translate}}</button>
  <button class="mr-16 alignSelfCenter fr mt-32" (click)="getPrintQueueData()" mat-stroked-button>
    <span class="material-icons mr-8"> refresh</span>
    {{'Refresh'|translate}}</button>
    <button [disabled]="overdueList.length == 0" class="mr-16 alignSelfCenter fr mt-32 primaryColor" (click)="reprintOverdueOrders()" mat-raised-button>
        <span class="material-icons mr-8"> print</span>
        {{'Re/Print OverDue'|translate}}</button> 
    <br class="mt-16">
    <mat-form-field class="mt-16 mr-16" style="width: 96px;">
    <mat-label>{{'Records'|translate}}</mat-label>
    <mat-select disableOptionCentering [(value)]="selectedRecordNo">
        <mat-option *ngFor="let record of records" [value] = "record.value">
            {{record.viewValue}}
        </mat-option>
    </mat-select>
</mat-form-field>

<mat-form-field class="mr-16" style="width: 136px;">
    <mat-label>{{'Cell'|translate}}</mat-label>
    <mat-select multiple disableOptionCentering [(value)]="selectedContainer">
        <mat-option *ngFor="let cell of cellsData" [value]="cell.id" >{{cell.title}}</mat-option>
    </mat-select>
</mat-form-field>
<mat-form-field class="mr-16" style="width: 136px;">
    <mat-label>{{'Status'|translate}}</mat-label>
    <mat-select multiple disableOptionCentering [(value)]="selectedStatus">
        <mat-option value="printed">{{'Printed'|translate}}</mat-option>
        <mat-option value="notprinted">{{'NotPrinted'|translate}}</mat-option>
        <mat-option value="printedandscanned">{{'Printed'|translate}} {{'And'|translate}} {{'Scanned'|translate}}</mat-option>
    </mat-select>
</mat-form-field>
<mat-form-field class="mr-16" style="width: 88px;">
    <mat-label>{{'Urgent'|translate}}</mat-label>
    <mat-select disableOptionCentering [(value)]="selectedUrgency">
        <mat-option value='true'>{{'Yes'|translate}}</mat-option>
        <mat-option value='false'>{{'No'|translate}}</mat-option>
        <mat-option value='All'>All</mat-option>
    </mat-select>
</mat-form-field>
<mat-form-field class="mr-16" style="width: 136px;">
    <mat-label>{{'Printed'|translate}} {{'Date'|translate}}</mat-label>
    <input matInput readonly [matDatepicker]="picker" (dateChange)="updatePrintedDate($event)" [(value)]="visibleDate">
    <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
    <mat-datepicker #picker></mat-datepicker>
  </mat-form-field>
<mat-form-field class="mr-16" style="width: 136px;">
    <mat-label>{{'Type'|translate}}</mat-label>
    <mat-select multiple disableOptionCentering [(value)]="selectedType">
        <mat-option value='1'>{{'Custom'|translate}}</mat-option>
        <mat-option value = '2'>{{'Stock' |translate}}</mat-option>
        <mat-option value='3'>{{'FinishedGoods'|translate}}</mat-option>
    </mat-select>
</mat-form-field>
<mat-form-field class="mr-16" style="width: 88px;">
    <mat-label>{{'Bending'|translate}}</mat-label>
    <mat-select  disableOptionCentering [(value)]="selectedBending">
        <mat-option value='true'>{{'Yes'| translate}}</mat-option>
        <mat-option value='false'>{{'No'|translate}}</mat-option>
        <mat-option value='All'>All</mat-option>
    </mat-select>
</mat-form-field>
<mat-form-field class="mr-16" style="width: 88px;">
    <mat-label>{{'Single'|translate}}</mat-label>
    <mat-select disableOptionCentering [(value)]="selectedSingle">
        <mat-option value='true'>{{'Yes'|translate}}</mat-option>
        <mat-option value='false'>{{'No'|translate}}</mat-option>
        <mat-option value='All'>All</mat-option>
    </mat-select>
</mat-form-field>
<mat-form-field class="mr-16" style="width: 100px;">
    <mat-label>{{'Show'|translate}} {{'Done'|translate}}</mat-label>
    <mat-select disableOptionCentering [(value)]="selectedShowDone">
        <mat-option value='true'>{{'Yes'|translate}}</mat-option>
        <mat-option value='false'>{{'No'|translate}}</mat-option>
    </mat-select>
</mat-form-field>
<div class="fr">
    <button class="mr-8" mat-stroked-button (click)="clearFilter()" >{{'Clear'|translate}}</button>
    <button class="mt-32" mat-raised-button color="accent" (click)="filterResults()" >{{'Filter'|translate}}</button>
</div>



    <table mat-table [dataSource]="dataSource" matSort class="mt-32 noBackground mb-24">


        <ng-container matColumnDef="line">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>{{'Cell'|translate}}</th>
            <td mat-cell *matCellDef="let element"> 
                {{element.cell ? element.cell : '- - -'}} </td>
        </ng-container>

        <ng-container matColumnDef="shipment">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>{{'Shipment'|translate}}</th>
            <td mat-cell *matCellDef="let element"> 
                {{element.shipment }} </td>
        </ng-container>
    
    
        <ng-container matColumnDef="workorders">
            <th mat-header-cell *matHeaderCellDef mat-sort-header> {{'WorkOrder'|translate}} </th>
            <td mat-cell *matCellDef="let element">
                
                <ul>
                    <li *ngFor="let wo of element.workorders">
                        {{element.workorders ? wo : '- - -'}}
                    </li>
                </ul>
                
            </td>
        </ng-container>
    
        <ng-container matColumnDef="woQty">
            <th mat-header-cell *matHeaderCellDef>WO {{'Quantity'|translate}}</th>
            <td mat-cell *matCellDef="let element"> 
                {{element.wo_Qty}} </td>
        </ng-container>


        <ng-container matColumnDef="bending">
            <th mat-header-cell *matHeaderCellDef>{{'Bending'|translate}}</th>
            <td mat-cell *matCellDef="let element"> 
                {{element.bending}} </td>
        </ng-container>

        <ng-container matColumnDef="sequence">
            <th mat-header-cell *matHeaderCellDef>Seq.</th>
            <td mat-cell *matCellDef="let element"> 
                {{element.print_Sequence}} </td>
        </ng-container>
    
        <ng-container matColumnDef="printDateTime">
            <th mat-header-cell *matHeaderCellDef>{{'PrintedDate'|translate}}</th>
            <td mat-cell *matCellDef="let element"> {{element.printDateTime !== null ? (element.printDateTime | dateFormat:'DD-MM-YY, h:mm a') : '- - -' }} </td>
        </ng-container>

        <ng-container matColumnDef="LTRemaining">
            <th mat-header-cell *matHeaderCellDef>LT Remaining</th>
            <td mat-cell *matCellDef="let element">
                <span *ngIf="element.ltRemaining > 3">{{element.ltRemaining}}</span>
                <span style="color: orange;" *ngIf="element.ltRemaining >= 1 && element.ltRemaining <= 3">{{element.ltRemaining}}</span>
                <span style="color: red;" *ngIf="element.ltRemaining <= 0">{{element.ltRemaining}} - Overdue</span>


             </td>
        </ng-container>
    
    
        <ng-container matColumnDef="urgent">
            <th mat-header-cell *matHeaderCellDef> {{'Urgent'|translate}} </th>
            <td mat-cell *matCellDef="let element"> {{element.urgent ? "Yes" : "No"}} </td>
        </ng-container>
    
        <ng-container matColumnDef="printAttempts">
            <th mat-header-cell *matHeaderCellDef>{{'Print'| translate}} {{'Attempts'| translate}} </th>
            <td class="pr-48 alignNumber" mat-cell *matCellDef="let element"> {{element.printAttempts}} </td>
        </ng-container>
    
        <ng-container matColumnDef="action">
            <th mat-header-cell *matHeaderCellDef> {{'Action'|translate}} </th>
            <td style="width: 30%;" mat-cell *matCellDef="let element;">
                <button *ngIf="!element.printDateTime"  class="mr-16 alignSelfCenter primaryColor" (click)="releaseSpecificShipment(element.shipment)" mat-raised-button>
                    <span class="material-icons mr-8"> print</span>
                    {{'Release'|translate}}</button>
                <button *ngIf="element.printDateTime"  class="mr-16 alignSelfCenter" (click)="reprintShipment(element.shipment,element.printDateTime)" mat-stroked-button>
                    <span class="material-icons mr-8"> print</span>
                    {{'Reprint'|translate}}</button>    
                <button class="mr-16 alignSelfCenter" mat-stroked-button [routerLink]="'/search'"  [queryParams]="{shipment: element.shipment}">
                    <span class="material-icons mr-8">search</span>
                    {{'Details'|translate}}
                </button>
                <!-- <button *ngIf="element.urgent" class="mr-16 alignSelfCenter" mat-stroked-button (click)="markNonUrgent(element.shipment)">
                        <span class="material-icons mr-8">report</span>
                        {{'Mark'|translate}} {{'NonUrgent'|translate}}
                    </button> -->
            </td>
        </ng-container>
    
        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
    </table>
<ng-template #emptyState>
    <div class="mt-32 w-100-p">
    <!-- No Data found -->
    </div>
</ng-template>

<ngx-spinner bdColor="rgba(51, 51, 51, 0.8)" size="default" type="ball-spin-clockwise">  
    <p style="color: white">Please Wait. </p>  
    </ngx-spinner> 
