import { Component, AfterViewInit, ViewChild } from '@angular/core';
import { HttpService } from '../http.service';
import { NotifierService } from "angular-notifier";
import { NgxSpinnerService } from "ngx-spinner";
import { HttpParams } from '@angular/common/http';
import * as moment from 'moment';
import { MatDialog} from '@angular/material/dialog';
import { ReprintInfoComponentComponent } from '../reprint-info-component/reprint-info-component.component';
import {MatSort} from '@angular/material/sort';
import {MatTableDataSource} from '@angular/material/table';

export interface PrintQueue {
  shipment: string,
  workorders: string[];
  printDateTime: string;
  urgent: boolean;
  printAttempts: number;
}


@Component({
  selector: 'app-print-queue',
  templateUrl: './print-queue.component.html',
  styleUrls: ['./print-queue.component.scss']
})
export class PrintQueueComponent implements AfterViewInit {

  @ViewChild(MatSort) sort: MatSort;

  //filters
  selectedPrinter: string = '';
  selectedLeadTime: string = '';
  selectedRecordNo: string = '';
  selectedContainer: string[] = [];
  selectedStatus: string[] = [];
  selectedUrgency: string = '';
  selectedType: string[]=[];
  selectedBending: string = '';
  selectedSingle: string = '';
  selectedShowDone: string = '';
  selectedDate: string = '';
  visibleDate: string = '';

  printers: any = [];
  leadtimes: any = [];
  displayedColumns: string[] = ['line', 'shipment', 'workorders', 'woQty', 'bending', 'sequence', 'printDateTime', 'LTRemaining', 'urgent', 'printAttempts', 'action'];
  dataSource: any = [];
  printData: any;
  interval: any;
  cellsData: any = [];
  dbDateTime: string;
  exportData: any = [];
  Wo_Base_Id = [];
  overdueList = [];


  records: any = [
    { value: '15', viewValue: '15' },
    { value: '25', viewValue: '25' },
    { value: '50', viewValue: '50' },
    { value: '100', viewValue: '100' },
    { value: '250', viewValue: '250' },
    { value: 'All', viewValue: 'All' },
  ]


  private readonly notifier: NotifierService;

  constructor(
    notifierService: NotifierService,
    private _http: HttpService,
    private SpinnerService: NgxSpinnerService,
    public dialog: MatDialog 
  ) {
    this.notifier = notifierService;
  }

  ngAfterViewInit(): void {
    this.notifier.hideNewest();
    this.getPrintQueueData();
    this.getPrinters();
    this.getCells();
  }

  getPrintQueueData() {

    this.overdueList = [];
    this.SpinnerService.show();
    this._http.getPrintQueue().subscribe((data) => {
      this.dataSource = [];
      let obj = JSON.parse(JSON.stringify(data));
      this.exportData = obj;
      this.dataSource = new MatTableDataSource(obj);
      this.dataSource.sort = this.sort;
      console.log(obj);

      obj.forEach(element => {

        if(element.ltRemaining <= 0 && element.status === "Not Printed"){

          this.overdueList.push(element.shipment);
        }
        
      });
      this.SpinnerService.hide();
    },
      err => {
        this.SpinnerService.hide();
        this.notifier.notify('error', 'There was a server error. Please try later!')
      }
    );
  }

  exportPrintQueue(){

    if(this.exportData != null && this.exportData.length != 0){

        this.exportData.forEach(row => {

          var salesOrder = row.shipment.split("X",2);
          row["SalesOrder"] = salesOrder[0];


          row.workorders.forEach(row => {

          var workOrderBaseId = row.split("X",2);
          this.Wo_Base_Id.push(workOrderBaseId[0]);   

          });

          row["workOrderBaseId"] = this.Wo_Base_Id;
          this.Wo_Base_Id = [];

        });
        
        this.downloadFile(this.exportData, 'print_queue_data');

        console.log("Export Data outside foreach=>");
        console.log(this.exportData);
        
    }else{
      this.notifier.notify('warning', 'No Data available for Export. Please apply filter!!')
    }
  }

  downloadFile(data: any, filename='data') {

    let csvData = this.ConvertToCSV(data, ['bending','cell','orderType','printDateTime','shipment','SalesOrder','urgent','wo_Qty','workorders','workOrderBaseId']);
    let blob = new Blob(['\ufeff' + csvData], { type: 'text/csv;charset=utf-8;' });
    let dwldLink = document.createElement("a");
    let url = URL.createObjectURL(blob);
    dwldLink.setAttribute("href", url);
    dwldLink.setAttribute("download", filename + ".csv");
    dwldLink.style.visibility = "hidden";
    document.body.appendChild(dwldLink);
    dwldLink.click();
    document.body.removeChild(dwldLink);
    this.notifier.notify('success', 'Downloading Print Queue Data');   
}

ConvertToCSV(objArray, headerList) {
     let array = typeof objArray != 'object' ? JSON.parse(objArray) : objArray;
     let str = '';
     let row = 'S.No,';

     for (let index in headerList) {
         row += headerList[index] + ',';
     }
     row = row.slice(0, -1);
     str += row + '\r\n';
     for (let i = 0; i < array.length; i++) {
         let line = (i+1)+'';
         for (let index in headerList) {
            let head = headerList[index];

             line += ',' + array[i][head];
         }
         str += line + '\r\n';
     }
     return str;
 }

  getPrinters() {
    this._http.getPrintersDropdownData().subscribe((results) => {
      this.printers = results;
    },
      err => {
        this.SpinnerService.hide();
        this.notifier.notify('error', 'There was a server error. Please try later!')
      });
  }

  getCells() {
    this._http.getCells().subscribe((results) => {
      this.cellsData = results;
    },
      err => {
        this.notifier.notify('error', 'There was a server error. Please try later!')
      })
  }

  releaseSpecificShipment(shipmentID){
    if(this.selectedPrinter != ""){

      let payload = {
        id: shipmentID,
        viaWarePrinter: this.selectedPrinter,
        site: $ENV.SITE_ABBREVIATION,
      }

      this.SpinnerService.show();

      this._http.printSpecificOrder(payload).subscribe((results) => {
        let obj = JSON.parse(JSON.stringify(results));
        if (obj.message === "Success") {
          this.SpinnerService.hide();
          this.notifier.notify("success", shipmentID + " released successfully!!");
          //this.getPrintQueueData();
        }
        else
          this.notifier.notify("error", "Something went wrong. Please try again!");
  
      });      
    }else{
      this.notifier.notify("warning", "Please choose a printer to proceed!");
    }
  }

  reprintShipment(shipmentID,printdate:string) {
    var dbtime;
    var dbSplit;
    var dbDateFlag = false;
    
    if(this.selectedPrinter !== ""){
    
    //create payload for reprint
    let payload = {
      id: shipmentID,
      viaWarePrinter: this.selectedPrinter,
      site: $ENV.SITE_ABBREVIATION,
    }

    //Getting Current Date from DB
    this._http.getDbDateTime().subscribe((results) => {
      let res = JSON.parse(JSON.stringify(results));
      this.dbDateTime =  res.date;
      //console.log("Databse Date Time");
      //console.log(this.dbDateTime);
      dbSplit = this.dbDateTime.split("T",2);
      dbtime = dbSplit[1].split(":",3);
      dbDateFlag = true;
      var minutesDiff = 0;
      var printDateSplit = printdate.split("T",2);
      var printDateTime = printDateSplit[1].split(":",3);

    if (dbDateFlag) {
      //if date and time (hour) matches the printed DateTime
    if ( dbSplit[0] == printDateSplit[0] && dbtime[0] == printDateTime[0]) {
      minutesDiff = dbtime[1] -  parseInt(printDateTime[1]); 
      //console.log("You printed " + minutesDiff + " minutes ago");

    if (minutesDiff >= 0 && minutesDiff <= 5 ) {
      const dialogRef = this.dialog.open(ReprintInfoComponentComponent, {
        width: '496px',
        data: payload,
        panelClass: 'custom-modal',
      });
    
     dialogRef.afterClosed().subscribe(result => {
      //this.filterResults();
      this.SpinnerService.hide(); 
        //console.log('The reprint dialog was closed');
      });
    }else{
      //console.log("More than 5 minutes passed in last print.. you can reprint now");
      this.SpinnerService.show();
      this._http.reprintShipment(payload).subscribe((results) => {
        let obj = JSON.parse(JSON.stringify(results));
        if (obj.message === 'Success') {
         this.filterResults();
         this.SpinnerService.hide();
          this.notifier.notify("success", "Reprint request has been sent successfully!");
        }else{
          this.SpinnerService.hide();
          //this.filterResults();
          this.notifier.notify("error", "Something went wrong with re-printing. Please try again!");
        }
      },
      err=>{
        this.SpinnerService.hide();
        this.notifier.notify("error", "Something went wrong with re-printing. Please try again!");
      })
    }
  }else{
    //The Print DateTime is older than Current DateTime
    //console.log("The Print DateTime is older than Current DateTime.... Can Reprint ");
    this.SpinnerService.show();
    this._http.reprintShipment(payload).subscribe((results) => {
      let obj = JSON.parse(JSON.stringify(results));
      if (obj.message === 'Success') {
        this.filterResults();
       this.SpinnerService.hide();
        this.notifier.notify("success", "Reprint request has been sent successfully!");
      }else{
        this.SpinnerService.hide();
        this.filterResults();
        this.notifier.notify("error", "Something went wrong with re-printing. Please try again!");
      }
    },
    err=>{
      this.SpinnerService.hide();
      this.notifier.notify("error", "Something went wrong with re-printing. Please try again!");
    })
  } 
    }else{
      //console.log("DB date Flag not set " + dbDateFlag);
    }
  },
    err=>{
      this.SpinnerService.hide();
      this.notifier.notify("error", "Something went wrong with fetching server DateTime, cannot Reprint. Please try again!");
    });
    
}
    else{
      this.notifier.notify("warning", "Please choose a printer to proceed!");
    }
}

  markUrgent(shipmentID) {
    this._http.markShipmentUrgent(shipmentID).subscribe((results) => {
      let obj = JSON.parse(JSON.stringify(results));
      if (obj.message === "Success") {
        this.notifier.notify("success", "Shipment has been marked urgent successfully!");
        this.getPrintQueueData();
      }
      else
        this.notifier.notify("error", "Something went wrong. Please try again!");

    })
  }

  markNonUrgent(shipmentID) {
    this._http.markShipmentNonUrgent(shipmentID).subscribe((results) => {
      let obj = JSON.parse(JSON.stringify(results));
      if (obj.message === "Success") {
        this.notifier.notify("success", "Shipment has been marked non urgent successfully!");
        this.getPrintQueueData();
      }
      else
        this.notifier.notify("error", "Something went wrong. Please try again!");
    })
  }

  filterResults() {
    let params = new HttpParams();

    if (this.selectedRecordNo !== '')
      params = params.append('records', this.selectedRecordNo);

    if (this.selectedContainer.length !== 0)
      params = params.append('cellsid', this.selectedContainer.join());

    if (this.selectedStatus.length !== 0)
      params = params.append('status', this.selectedStatus.join());

    if (this.selectedUrgency !== '')
      params = params.append('urgent', this.selectedUrgency);

    if (this.selectedDate !== '')
      params = params.append('printDate', this.selectedDate);

    if (this.selectedType.length !== 0)
      params = params.append('manufacturingtypes', this.selectedType.join());

    if (this.selectedBending !== '')
      params = params.append('bending', this.selectedBending);

    if (this.selectedSingle !== '')
      params = params.append('single', this.selectedSingle);

    if (this.selectedShowDone !== '')
      params = params.append('showdone', this.selectedShowDone);  
    
    if (this.selectedLeadTime !== '')
      params = params.append('leadTime', this.selectedLeadTime);

    
      this.SpinnerService.show();
      this.overdueList = [];
      this._http.getPrintQueue(params).subscribe((data) => {
        this.dataSource = [];
        this.dataSource = data;
        this.exportData = [];
        this.exportData = data;

        this.dataSource.forEach(element => {

          if(element.ltRemaining <= 0 && element.status === "Not Printed"){

            this.overdueList.push(element.shipment);
          }
          
        });
        this.SpinnerService.hide();
      },
        err => {
          this.SpinnerService.hide();
          this.notifier.notify('error', 'There was a server error. Please try later!')
        }
      )
  }

  reprintOverdueOrders(){

    if(this.selectedPrinter !== "")
    {

      let payload = {
        idList: this.overdueList,
        viaWarePrinter: this.selectedPrinter,
      }
  
      this.SpinnerService.show();
            this._http.reprintMultipleShipments(payload).subscribe((results) => {
              let obj = JSON.parse(JSON.stringify(results));
              if (obj.message === 'Success') {
               this.SpinnerService.hide();
               this.notifier.notify("success", "Reprint request has been sent successfully!");
              }else{
                this.SpinnerService.hide();
                this.notifier.notify("error", "Something went wrong with re-printing. Please try again!");
              }
            },
            err=>{
              this.SpinnerService.hide();
              this.notifier.notify("error", "Something went wrong with re-printing. Please try again!");
            })

    } else{
      this.notifier.notify("warning", "Please choose a printer to proceed!");
    }
    
  }

  clearFilter(){
    this.selectedPrinter = '';
    this.selectedLeadTime = '';
    this.selectedRecordNo = '';
    this.selectedContainer = [];
    this.selectedStatus= [];
    this.selectedUrgency='';
    this.selectedType = [];
    this.selectedBending = '';
    this.selectedSingle = '';
    this.selectedShowDone = '';
    this.selectedDate = '';
    this.visibleDate= '';
    this.overdueList = [];

    this.getPrintQueueData();
  }

  updatePrintedDate(event){
    this.visibleDate = event.value;
    this.selectedDate = moment(event.value).format('YYYY-MM-DD');
  }
}
