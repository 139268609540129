import { Component, OnInit } from "@angular/core";
import { HttpService } from "../http.service";
import { NgxSpinnerService } from "ngx-spinner";
import { NotifierService } from "angular-notifier";
import { TranslateService } from "@ngx-translate/core";

@Component({
  selector: "app-line-settings",
  templateUrl: "./line-settings.component.html",
  styleUrls: ["./line-settings.component.scss"],
})
export class LineSettingsComponent implements OnInit {
  private readonly notifier: NotifierService;

  customLowQty: any;
  customReleaseQty: any;
  customOptimalValue: any;
  customBuffer: any;
  customOverflow: any;
  fgLowQty: any;
  fgReleaseQty: any;
  fgOptimalValue: any;
  fgBuffer: any;
  fgOverflow: any;
  releaseSettings: any;
  removeAlternateLogic: any;
  sortValue: Boolean;
  sortOrder: Boolean;
  printSortId: any;
  aql: any;
  GInspectionLevel: any;
  cartQuantity: any;
  ignoreBending: any;
  printSort: any;
  sensorRetainDays: any;
  SiteLeadTime: any;
  swCalculation: any;
  curingTimer: any;
  doneButton: any;
  qcCriteriaFull: any;
  fgReleaseSequence: any;
  clubsMaxQty: any;
  clubQtyValue: any;
  clubQtyLogic = [
    { viewValue: "None", value: "none" },
    { viewValue: "Equal", value: "equal" },
    { viewValue: "Greater Than", value: "greaterThan" },
    { viewValue: "Lower Than", value: "lowerThan" },
  ];
  maintenanceMessages: boolean;
  isGlueStartingPoint: boolean;
  // PLATINUM
  platinumOnOrOff: boolean;
  platinumPrinter: string;
  platinumLine: number;
  printers: any = [];
  lines: any = [];
  errorMsg: string = "";

  constructor(
    private _http: HttpService,
    private SpinnerService: NgxSpinnerService,
    notifierService: NotifierService,
    private translate: TranslateService
  ) {
    this.notifier = notifierService;
    this.translate.get("SomeMandatoryFieldsAreNotInputYet").subscribe((v) => {
      this.errorMsg = v;
    });
  }

  ngOnInit(): void {
    this.getPrinters();
    this.getCells();
    this.getGlobalSetup();
    this.getPrintSort();
    this.customLowQty = 0;
  }

  getGlobalSetup() {
    this._http.getGlobalSetupValuesVersion().subscribe((results) => {
      let obj = JSON.parse(JSON.stringify(results));
      this.customLowQty = obj.customlowQtyValue;
      this.customBuffer = obj.customBuffer;
      this.customOptimalValue = obj.customOptimalValue;
      this.customOverflow = obj.customOverflow;
      this.customReleaseQty = obj.customReleaseQty;

      this.fgBuffer = obj.fgBuffer;
      this.fgOptimalValue = obj.fgOptimalValue;
      this.fgOverflow = obj.fgOverflow;
      this.fgReleaseQty = obj.fgReleaseQty;
      this.fgLowQty = obj.fGlowQtyValue;

      this.releaseSettings = obj.customAlternateLogic;
      this.printSortId = obj.printSortID;
      this.sortOrder = obj.sortAscending;
      this.aql = String(obj.aql);
      this.GInspectionLevel = obj.gInspectionLevel.replace(/\s/g, "");
      this.cartQuantity = obj.cartQuantity;
      this.ignoreBending = obj.ignoreBending;
      this.sensorRetainDays = obj.sensorRetainDays;
      this.swCalculation = obj.swCalculation;
      this.curingTimer = obj.curingTimerCheck;
      this.qcCriteriaFull = obj.qcCriteriaFull;
      this.doneButton = obj.doneButton;
      this.fgReleaseSequence = obj.fgReleaseSequence;
      this.clubQtyValue = obj.clubsQtyLogic;
      this.clubsMaxQty = obj.clubsMaxQty;
      this.removeAlternateLogic = obj.removeAlternateLogic;
      this.SiteLeadTime = obj.siteLeadTime;
      this.maintenanceMessages = obj.maintenanceMessages;
      this.isGlueStartingPoint =
        obj.curingTimerStartingPoint === "Curing" ? false : true;

      this.platinumOnOrOff = obj.autoPrint;
      this.platinumPrinter = obj.autoPrinter;
      this.platinumLine = obj.platinumLine;
    });
  }

  getPrintSort() {
    this._http.getPrintSortValues().subscribe(
      (results) => {
        this.printSort = results;
      },
      (err) => {
        this.notifier.notify(
          "error",
          "There was a server error. Please try later!"
        );
      }
    );
  }

  saveSettings() {
    if (!this.isPassPlatinum()) {
      this.scrollPlatinum();
      this.notifier.notify("error", this.errorMsg);
      return;
    }

    let payload = {
      customlowQtyValue: this.customLowQty,
      customOptimalValue: this.customOptimalValue,
      customBuffer: this.customBuffer,
      customReleaseQty: this.customReleaseQty,
      customOverflow: this.customOverflow,

      fGlowQtyValue: this.fgLowQty,
      fgOptimalValue: this.fgOptimalValue,
      fgBuffer: this.fgBuffer,
      fgReleaseQty: this.fgReleaseQty,
      fgOverflow: this.fgOverflow,

      customAlternateLogic: this.releaseSettings,
      fifo: this.sortValue,
      sortAscending: this.sortOrder,
      GInspectionLevel: this.GInspectionLevel,
      aql: parseFloat(this.aql),
      CartQuantity: this.cartQuantity,
      printsortID: this.printSortId,
      ignoreBending: this.ignoreBending,
      sensorRetainDays: this.sensorRetainDays,
      siteLeadTime: this.SiteLeadTime,
      swCalculation: this.swCalculation,
      curingTimerCheck: this.curingTimer,
      qcCriteriaFull: this.qcCriteriaFull,
      doneButton: this.doneButton,
      maintenanceMessages: this.maintenanceMessages,
      fgReleaseSequence: this.fgReleaseSequence,
      clubsQtyLogic: this.clubQtyValue,
      clubsMaxQty: this.clubsMaxQty,
      removeAlternateLogic: this.removeAlternateLogic,
      curingTimerStartingPoint: this.isGlueStartingPoint ? "Gluing" : "Curing",
      autoPrint: this.platinumOnOrOff,
      autoPrinter: this.platinumPrinter,
      platinumLine: this.platinumLine,
    };

    if (
      this.customLowQty !== null &&
      this.customReleaseQty !== null &&
      this.customOptimalValue !== null &&
      this.customBuffer !== null &&
      this.customOverflow !== null &&
      this.fgLowQty !== null &&
      this.fgReleaseQty !== null &&
      this.fgOptimalValue !== null &&
      this.fgBuffer !== null &&
      this.fgOverflow !== null &&
      this.GInspectionLevel !== null &&
      this.aql !== null &&
      this.cartQuantity != null &&
      this.printSortId != null &&
      this.ignoreBending != null &&
      this.sensorRetainDays != null &&
      this.SiteLeadTime != null &&
      this.swCalculation != null &&
      this.curingTimer != null &&
      this.doneButton != null &&
      this.qcCriteriaFull != null &&
      this.fgReleaseSequence != null &&
      this.clubQtyValue != null &&
      this.clubsMaxQty != null &&
      this.removeAlternateLogic !== null
    ) {
      this.SpinnerService.show();
      this._http.updateLineSettingsVersion(payload).subscribe((results) => {
        if (results) {
          this.notifier.notify(
            "success",
            "Settings have been updated successfully!"
          );
          this.SpinnerService.hide();
        }
      });
    } else this.notifier.notify("warning", `Settings can't be left empty!`);
  }

  isPassPlatinum() {
    if (!this.platinumOnOrOff) return true;

    if (
      this.platinumPrinter &&
      this.platinumPrinter != null &&
      this.platinumPrinter.length > 0 &&
      this.platinumLine &&
      this.platinumLine > 0
    )
      return true;

    return false;
  }

  scrollPlatinum() {
    window.scroll({
      top: 500,
      left: 0,
      behavior: "smooth",
    });
  }

  getPrinters() {
    this._http.getPrintersDropdownData().subscribe(
      (response) => {
        this.printers = response;
      },
      (err) => {
        this.notifier.notify(
          "error",
          "There was a server error. Please try later!"
        );
      }
    );
  }

  getCells() {
    this._http.getCells().subscribe(
      (response) => {
        this.lines = response;
      },
      (err) => {
        this.notifier.notify(
          "error",
          "There was a server error. Please try later!"
        );
      }
    );
  }
}
