import { Component, OnInit } from '@angular/core';
import { HttpService } from '../http.service';
import { NotifierService } from "angular-notifier";
import { NgxSpinnerService } from "ngx-spinner";
import { queue } from 'rxjs/internal/scheduler/queue';

interface printCart {
  value: string;
  viewValue: string;
}


export interface PrintOrder {
  id: number,
  title: string;
  clubsInCell: number;
  clubsQueued: number;
  clubsCompleted: number;
  workOrder: string;
  shipment: string;
  clubs: number;
}
@Component({
  selector: 'app-prints',
  templateUrl: './prints.component.html',
  styleUrls: ['./prints.component.scss']
})
export class PrintsComponent implements OnInit {

  shipments: printCart[] = [
    { value: '1', viewValue: '1' },
    { value: '2', viewValue: '2' },
    { value: '3', viewValue: '3' },
    { value: '4', viewValue: '4' },
    { value: '5', viewValue: '5' },
    { value: '6', viewValue: '6' },
    { value: '7', viewValue: '7' },
    { value: '8', viewValue: '8' },
    { value: '9', viewValue: '9' },
    { value: '10', viewValue: '10' },
  ];

  count = 0;
  selectedPrinter: string = '';
  selectedCart: string = '';
  printers: any = [];
  displayedColumns: string[] = ['title', 'shipment', 'workOrder', 'clubsQueued', 'bending', 'lowQty', 'stress'];
  dataSource: any = [];
  printData: any;
  isAutoRelease = false;
  isLoading = true;


  private readonly notifier: NotifierService;

  constructor(
    private _http: HttpService,
    notifierService: NotifierService,
    private SpinnerService: NgxSpinnerService
  ) {
    this.notifier = notifierService;
    this.printData = {};
  }

  ngOnInit() {

    this.notifier.hideNewest();
    this.SpinnerService.show();
    this.getPrinters();

    this._http.getPrintTable().subscribe(
      (data) => {
        this.printData = JSON.parse(JSON.stringify(data));
        this.dataSource = this.printData.list;
        this.SpinnerService.hide();
      },
      err=>{
        this.SpinnerService.hide();
        this.notifier.notify('error', 'There was a server error. Please try later!')
      },
    );
  }

  getPrinters() {
    this._http.getPrintersDropdownData().subscribe((results) => {
      this.printers = results;
    },
    err=>{
      this.notifier.notify('error', 'There was a server error. Please try later!')
    },
    );
  }

  hideNotification(){
    this.notifier.hideNewest();
  }

  printSingleShipment(){
    if (this.selectedPrinter === '') {
      this.notifier.notify("warning", "Please select Printer and Print Cart");
    }

    else{
      let payload = {
        quantity: 1,
        viaWarePrinter: this.selectedPrinter,
        site: $ENV.SITE_ABBREVIATION
      }

      this.SpinnerService.show();
      this._http.printCart(payload).subscribe((results) => {
        let obj = JSON.parse(JSON.stringify(results));

        if (obj.message === 'Success') {
          this._http.getPrintTable().subscribe(
            (data) => {
              this.SpinnerService.hide();
              this.notifier.notify("success", "Cart has been successfully printed!");
            },
            err=>{
              this.SpinnerService.hide();
              this.notifier.notify('error', 'There was a server error. Please try later!')
            });

        }

        else {
          this.SpinnerService.hide();
          this.notifier.notify("error", obj.message);
        }
      })
    }
  }


  printCart() {

    if (this.selectedCart === '' || this.selectedPrinter === '') {
      this.notifier.notify("warning", "Please select Printer and Print Cart");
    }

    else {

      let payload = {
        quantity: parseInt(this.selectedCart),
        viaWarePrinter: this.selectedPrinter,
        site: $ENV.SITE_ABBREVIATION
      }

      this.SpinnerService.show();

      this._http.printCart(payload).subscribe((results) => {

        let obj = JSON.parse(JSON.stringify(results));

        if (obj.message === 'Success') {
          this._http.getPrintTable().subscribe(
            (data) => {
              this.printData = [];
              this.printData = JSON.parse(JSON.stringify(data));
              this.dataSource = [];
              this.dataSource = this.printData.list;
              this.SpinnerService.hide();
              this.notifier.notify("success", "Cart has been successfully printed!");
            },
            err=>{
              this.SpinnerService.hide();
              this.notifier.notify('error', 'There was a server error. Please try later!')
            });

        }

        else {
          this.SpinnerService.hide();
          this.notifier.notify("error", obj.message);
        }
      })

    }
  }

  totalClubs(element){
    console.log(this.count++);
    return element.clubsQueued + element.clubsInCell;
  }


  calculateBendingRatio(element){
    return (element.clubsBending/this.totalClubs(element))*100;
  }

  calculateStressLevel(element){

    return element.clubsQueued;
  }

}
