import { Component, OnInit } from '@angular/core';
import { HttpService } from '../http.service';
import { NotifierService } from "angular-notifier";
import { NgxSpinnerService } from "ngx-spinner";
import { MatDialog } from '@angular/material/dialog';
import { Overlay } from '@angular/cdk/overlay';
import { LineSetupUpddatePopupComponent } from '../line-setup-upddate-popup/line-setup-upddate-popup.component';
import { LineSetupDeletePopupComponent } from '../line-setup-delete-popup/line-setup-delete-popup.component';
import { LineSetupCreatePopupComponent } from '../line-setup-create-popup/line-setup-create-popup.component';

@Component({
  selector: 'app-line-setup-settings',
  templateUrl: './line-setup-settings.component.html',
  styleUrls: ['./line-setup-settings.component.scss']
})
export class LineSetupSettingsComponent implements OnInit {

  displayedColumns: string[] = ['ID', 'title', 'keyName', 'keyNameFG','status', 'action'];
  selectedLineSetup: string = '';

  dataSource: any = [];
  lineSetup: any = [];
  singleLineSetup: any = [];
  refreshData: boolean = false;
  isProcessing: Boolean = false;

  private readonly notifier: NotifierService;
  constructor(
    notifierService: NotifierService,
    public dialog: MatDialog,
    public overlay: Overlay,
    private _http: HttpService,
    private SpinnerService: NgxSpinnerService
  ) {
    this.notifier = notifierService;
  }


  ngOnInit(): void {

    this.notifier.hideNewest();
    this.getAllLineSetupValue();
    this.getLineSetup();
    //this.dataSource.paginator = this.paginator;
    if (this.refreshData) {
      this.getAllLineSetupValue();
    }
}

 getLineSetup() {
    this._http.getLineSetupData().subscribe((results) => {
     this.lineSetup = results;
      
    },
      err => {
        this.SpinnerService.hide();
        this.notifier.notify('error', 'There was a server error. Please try later!')
      });
  }

  getAllLineSetupValue() {
    this.SpinnerService.show();
    this._http.getLineSetupValue().subscribe((data) => {
      this.dataSource = [];
      this.dataSource = data;
      this.SpinnerService.hide();
    },
      err => {
        this.SpinnerService.hide();
        this.notifier.notify('error', 'There was a server error. Please try later!')
      }
    );
  }

  changeStatus(status, id) {
    this.isProcessing = true;
    this._http.activateLineSetupValue(id, status).subscribe(data => {
      this.isProcessing = false;
      this.getAllLineSetupValue();
    },
    err=>{
      this.SpinnerService.hide();
      this.notifier.notify('error', 'There was a server error. Please try later!')
    });
  }

  filterResults(){
  if (this.selectedLineSetup !== ''){
    this.SpinnerService.show();
    this._http.getDropdownData(this.selectedLineSetup).subscribe((data) => {
      this.dataSource = [];
      this.dataSource = data;
      this.SpinnerService.hide();

      if(this.dataSource.length === 0){

        this.notifier.notify('error', 'No data available for this Line Setup');
        this.getAllLineSetupValue();
      }

    },
      err => {
        this.SpinnerService.hide();
        this.notifier.notify('error', 'There was a server error. Please try later!')
      }
    )
  }else{
    this.getAllLineSetupValue();
  }
}


deleteLineSetupValue(id){
  const dialogRef = this.dialog.open(LineSetupDeletePopupComponent, {
    width: '496px',
    scrollStrategy: this.overlay.scrollStrategies.noop(),
    data: id,
    panelClass: 'custom-modal',
  });

  dialogRef.afterClosed().subscribe(result => {
    console.log('The dialog was closed');
    setTimeout(() => {
      this.filterResults()
    }, 1000);
  });
}

CreateLineSetup(){
  const dialogRef = this.dialog.open(LineSetupCreatePopupComponent, {
    width: '496px',
    scrollStrategy: this.overlay.scrollStrategies.noop(),
    panelClass: 'custom-modal',
  });

  dialogRef.afterClosed().subscribe(result => {
    console.log('The dialog was closed');
    setTimeout(() => {
      this.filterResults()
    }, 1000);
  });
}

clearFilter(){
  this.selectedLineSetup = "";
  this.getAllLineSetupValue();
}

openLineSetupupdateDialog(element: any) {

  let id = element.id;
  this._http.getLineSetupValuebyId(id).subscribe(data => {
    this.singleLineSetup = data;
    const dialogRef = this.dialog.open(LineSetupUpddatePopupComponent, {
      width: '496px',
      scrollStrategy: this.overlay.scrollStrategies.noop(),
      data: this.singleLineSetup,
      panelClass: 'custom-modal',
    });

    dialogRef.afterClosed().subscribe(result => {
      console.log('The dialog was closed');
      setTimeout(()=>{ this.filterResults() }, 1000);
    });
  })
}

}
