<h5 mat-dialog-title class="pl-24 pt-24 pb-16">{{'LineHistory'|translate}}</h5>
<ng-container *ngIf="dataSource.length !== 0; else noData">
<table mat-table [dataSource]="dataSource" class="w-100-p noBackground">
    <!-- <ng-container matColumnDef="line">
        <th mat-header-cell *matHeaderCellDef>{{'Line'|translate}}</th>
        <td mat-cell *matCellDef="let element"> Line 1 </td>
    </ng-container> -->

    <ng-container matColumnDef="station">
        <th mat-header-cell *matHeaderCellDef> {{'Station'|translate}} </th>
        <td mat-cell *matCellDef="let element">

            <span style="color:#00a2e8" *ngIf="element.station == 'Order Printed'; else normal">{{element.station}}</span>
            <ng-template #normal>
                <span>{{element.station}}</span>
            </ng-template>
        </td>
    </ng-container>

    <ng-container matColumnDef="start">
        <th mat-header-cell *matHeaderCellDef> {{'Start'|translate}} {{'Time'|translate}} </th>
        <td mat-cell *matCellDef="let element"> {{element.entered | dateFormat:'DD/MM/YY, h:mm:ss a'}} </td>
    </ng-container>

    <ng-container matColumnDef="end">
        <th mat-header-cell *matHeaderCellDef> {{'End'|translate}} {{'Time'|translate}} </th>
        <td mat-cell *matCellDef="let element"><span *ngIf="element.ok; else pending">{{element.ok | dateFormat:'DD/MM/YY, h:mm:ss a'}}</span>
        <ng-template #pending>
            <span *ngIf="element.station !== 'Order Printed'; else base">pending</span>
        </ng-template>
        <ng-template #base>
            <span></span>
        </ng-template>
    </td>
    </ng-container>
    

    <ng-container matColumnDef="user">
        <th mat-header-cell *matHeaderCellDef> {{'User'|translate}} </th>
        <td mat-cell *matCellDef="let element"> {{element.useriD}} </td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>

</table>
</ng-container>
<ng-template #noData>
    <div class="pl-24 pb-24 pt-24">
        There is currently no line history available
    </div>
</ng-template>

<div mat-dialog-actions class="noMarginBottom">
    <button class="ml-24" mat-button (click)="onNoClick()">{{'Close'|translate}}</button>
  </div>