<h5 mat-dialog-title class="pl-24 pt-24 pb-16">Specifications</h5>
<ng-container *ngIf="specifications.length !== 0; else noData">
<div class="flexDisplay p-24 flexWrap ">
    <div class="w-48-p mr-8 flexDisplay flexColumn">
        <h5 class="boldFont">Grip Wraps</h5>
        <span class="textColor">
            {{specifications.gripWraps}}
        </span>
    </div>
    <div class="w-48-p ml-8 flexDisplay flexColumn">
        <h5 class="boldFont">Grip Logo</h5>
        <span class="textColor">
            {{specifications.gripLogo}}
        </span>
    </div>
    <div class="w-48-p mr-8 mt-16 flexDisplay flexColumn">
        <h5 class="boldFont">Shaft Templates</h5>
        <span class="textColor">
            {{specifications.shaftTemplateTip}}
        </span>
        <span class="textColor">
            {{specifications.shaftTemplateButt}}
        </span>
    </div>
    <div class="w-48-p ml-8 mt-16 flexDisplay flexColumn">
        <h5 class="boldFont">Shaft Adjustments</h5>
        <span class="textColor">
            {{specifications.shaftAdjustments}}
        </span>
    </div>
    <div class="w-48-p mr-8 mt-16 flexDisplay flexColumn">
        <h5 class="boldFont">Shaft Length</h5>
        <span class="textColor">
            {{specifications.shaftLengthTip}}
        </span>
        <span class="textColor">
            {{specifications.shaftLengthButt}}
        </span>
    </div>
    <div class="w-48-p ml-8  mt-16 flexDisplay flexColumn">
        <h5 class="boldFont">Shaft Model</h5>
        <span class="textColor">
            {{specifications.shaftModel}}
        </span>
        <span class="textColor">
            {{specifications.shaftSBL}}
        </span>
        <span class="textColor">
            {{specifications.shaftLogo}}
        </span>
    </div>
    <div class="w-48-p mr-8 mt-16 flexDisplay flexColumn">
        <h5 class="boldFont">Head Lie and Loft</h5>
        <span class="textColor">
            {{specifications.headLie}}
        </span>
        <span class="textColor">
            {{specifications.headloft}}
        </span>
    </div>
    <div class="w-48-p ml-8 mt-16 flexDisplay flexColumn">
        <h5 class="boldFont">Heac FCT</h5>
        <span class="textColor">
            {{specifications.headFCT}}
        </span>
    </div>
 
</div>
</ng-container>
<ng-template #noData>
    <div class="pl-24 pb-24 pt-24">
        Currently there no results for specifications
    </div>
</ng-template>

<div mat-dialog-actions class="noMarginBottom">
    <button class="ml-24" mat-button (click)="onNoClick()">Close</button>
  </div>