import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { LineManagementComponent } from './line-management/line-management.component';
import { LineSettingsComponent } from './line-settings/line-settings.component';
import { PrintsComponent } from './prints/prints.component';
import { PrintQueueComponent } from './print-queue/print-queue.component';
import { HomeComponent } from './home/home.component';
import { SearchComponent } from './search/search.component';
import { LoginComponent } from './login/login.component';

import { AuthGuard } from '../auth.guard';
import { PrivateLayoutComponent } from './layouts/private-layout/private-layout.component';
import { PublicLayoutComponent } from './layouts/public-layout/public-layout.component';
import { FinishedGoodsComponent } from './finished-goods/finished-goods.component';
import { CustomGoodsComponent } from './custom-goods/custom-goods.component';
import { FinishedGoodsV2Component } from './finished-goods-v2/finished-goods-v2.component';
import { DatabaseSettingsComponent } from './database-settings/database-settings.component';
import { LineSetupSettingsComponent } from './line-setup-settings/line-setup-settings.component';
import { AccountRuleSettingsComponent } from './account-rule-settings/account-rule-settings.component';


const routes: Routes = [

      { path: 'line-management', component: LineManagementComponent},
      { path: 'line-settings', component: LineSettingsComponent},
      { path: 'prints', component: PrintsComponent},
      { path: 'print-queue', component: PrintQueueComponent},
      { path: 'search', component: SearchComponent },
      { path: 'finished-goods', component: FinishedGoodsComponent },
      { path: 'finished-goods-v2', component: FinishedGoodsV2Component },
      { path: 'custom-goods', component: CustomGoodsComponent },
      { path: '', redirectTo: '/line-management', pathMatch: 'full'},
      { path: 'line-setup-settings', component: LineSetupSettingsComponent},
      { path: 'account-rule-settings', component: AccountRuleSettingsComponent}
    
      

      //login functionality
  // {
  //   path: '/',
  //   redirectTo: '/line-management',
  //   // component: PrivateLayoutComponent,
  //   // canActivate: [AuthGuard],
  //   children: [
  //     { path: 'line-management', component: LineManagementComponent},
  //     { path: 'prints', component: PrintsComponent},
  //     { path: 'print-queue', component: PrintQueueComponent},
  //     { path: 'search', component: SearchComponent },
  //   ]
  // },
  // {
  //   path: '',
  //   component: PublicLayoutComponent,
  //   children: [
  //     { path: 'login', component: LoginComponent},

  //   ]
  // },

];

@NgModule({
  imports: [RouterModule.forRoot(routes, {useHash: true})],
  exports: [RouterModule]
})
export class AppRoutingModule { }
