import { Component, OnInit } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { LineHistoryPopupComponent } from '../line-history-popup/line-history-popup.component';
import { HttpService } from '../http.service';
import { Overlay } from '@angular/cdk/overlay';
import { SpecificationPopupComponent } from '../specification-popup/specification-popup.component';
import { NotifierService } from "angular-notifier";
import { CancelShipmentPopupComponent } from '../cancel-shipment-popup/cancel-shipment-popup.component';
import { TranslateService } from '@ngx-translate/core';
import { ActivatedRoute } from '@angular/router';
import { NgxSpinnerService } from "ngx-spinner";
import { ReprintInfoComponentComponent } from '../reprint-info-component/reprint-info-component.component';
import { WoScannedSerialPopupComponent } from '../wo-scanned-serial-popup/wo-scanned-serial-popup.component';
import { CloseOrderPopupComponent } from '../close-order-popup/close-order-popup.component';


export interface WorkOrderDetails {
  line: string;
  station: string;
  dateTime: string;
  user: string;
}

export class cancelPayload {
  data: any = [];
  type: string = "";
};


@Component({
  selector: 'app-search',
  templateUrl: './search.component.html',
  styleUrls: ['./search.component.scss']
})
export class SearchComponent implements OnInit {

  displayedColumns: string[] = ['station', 'dateTime', 'user', 'action'];
  dataSource: any = [
    {station: 'Picking', dateTime:'3/20/2020 15:50', user: 'Joe Willis'}
  ];

  
  


  selectedPrinter: string = '';
  selectedAction: string = 'Export-Serials';
  printers: any = [];
  searchResults: any = [];
  searchValue: string = ''; 
  specialInstructions: string = '';
  printedNotScanned = [];
  isSearch: boolean = true;
  isScanned: boolean = false;
  specifications: any = [];
  shipmentCancelled: boolean = false;
  selectedLang: string = '';
  dbDateTime: string;
  WoCount: any = 0;
  notFound: boolean = false;
  selectedCompleteShipments : any = [];
  selectedWorkOrderList: any = [];
  selectedShipmentList: any = [];
  allSelected : boolean = false;
  wOSelected : boolean = false;
  disableOptions: boolean = true;
  WMSMsg: string;
  WMSFlag: boolean = false;
  disableView: boolean = false;
  UIflag: boolean = false;
  facility: any = [];
  urlImg : any = [];
  region : string;




  private readonly notifier: NotifierService;
  
  constructor(
    public dialog: MatDialog,
    public overlay: Overlay,
    private _http: HttpService,
    notifierService: NotifierService,
    public translate: TranslateService,
    private route: ActivatedRoute,
    private SpinnerService: NgxSpinnerService
    
  ) { 
    translate.addLangs(['en', 'es', 'ja']);
    translate.setDefaultLang('en');
    this.notifier = notifierService;
  }

  ngOnInit(): void {
    this.notifier.hideNewest();
    this.region = $ENV.SITE_ABBREVIATION;
    //this.region = "BAS";
    console.log("search SITE_ABBREVIATION: "+ this.region);

    this.selectedLang = this.route.snapshot.queryParams['lang'];

    if(this.selectedLang !== undefined){
      this.translate.use(this.selectedLang)
    }

    if(this.route.snapshot.queryParams['shipment'] != undefined)
    {
       this.searchValue = this.route.snapshot.queryParams['shipment'];
       this.refreshSearch();
    } 

    if(this.route.snapshot.queryParams['view'] != undefined)
    {
       var view = this.route.snapshot.queryParams['view'];
       if(view == "Limited")
       {
        this.disableView = true;
       }
    } 

    this.getPrinters();
  }

  selectAllShipment(shipment: any){
    
    if(!this.wOSelected){

      if(shipment != null){

        if(shipment.workorders.length > 0)
        {
  
          //select current shipment
          if(this.selectedShipmentList.find(x => x === shipment.shipment) === shipment.shipment){
              
            this.selectedShipmentList = this.selectedShipmentList.filter(obj => obj !== shipment.shipment);
            this.selectedCompleteShipments = this.selectedCompleteShipments.filter(obj => obj.shipment !== shipment.shipment);

            if(this.selectedShipmentList.length === 0){
              this.allSelected = false;
            }
            
  
          }else{
  
            this.selectedShipmentList.push(shipment.shipment);
            this.selectedCompleteShipments.push(shipment);
            this.allSelected = true;
  
          } 
        }else{
  
          this.notifier.notify('warning', 'Cannot select a shipment with {0} work orders!!');
        }
       }

    }else{

      this.notifier.notify('warning', 'Deselect individual work orders to enable shipment selection!!');
    }
     
  }

  markselectedWO(workorder: any){

    if(!this.allSelected){

    if(this.selectedWorkOrderList.find(x => x === workorder) === workorder){
            
      this.selectedWorkOrderList = this.selectedWorkOrderList.filter(obj => obj !== workorder);

      if(this.selectedWorkOrderList.length === 0){

        this.wOSelected = false;

      }

    }else{

      this.selectedWorkOrderList.push(workorder);
      this.wOSelected = true;

    }          

  }else{
    this.notifier.notify('warning', 'Deselect Shipment/s to enable individual selection!!');
  }

  }

  getPrinters() {
    this._http.getPrintersDropdownData().subscribe((results) => {
      this.printers = results;
    },
      err => {
        this.SpinnerService.hide();
        this.notifier.notify('error', 'There was a server error. Please try later!')
      });
  }

  performAction(){
    if(this.selectedAction != null){
      if(this.selectedAction == "Re-print"){
          this.reprintShipment();
      }else if(this.selectedAction == "Export-Serials"){
         this.exportSerials();
      }else if(this.selectedAction == "prioritize-all"){
        this.markShipmentUrgentAll();
      }else if(this.selectedAction == "prioritize-selected"){
        this.markShipmentUrgentSelected();
      }else if(this.selectedAction == "un-prioritize-all"){
        this.markShipmentnotUrgentAll();
      }else if(this.selectedAction == "un-prioritize-selected"){
        this.markShipmentnotUrgentSelected();
      }else if(this.selectedAction == 'cancel-selected'){
        this.openCancelShipment();
      }else if(this.selectedAction == 'cancel-all'){
        this.cancellAllShipments();
      }else if(this.selectedAction == 'close-all'){
        this.closelAllShipments();
      }else if(this.selectedAction == 'close-selected'){
        this.openCloseShipmentPopup();
      }
    }
  }

  reprintShipment() {
    var dbtime;
    var dbSplit;
    var dbDateFlag = false;
    

    if(this.selectedShipmentList.length > 0)
    {
      if(this.selectedPrinter !== ""){
    
        //create payload for reprint
        let payload = {
          idList: this.selectedShipmentList,
          viaWarePrinter: this.selectedPrinter,
        }

        if(this.selectedShipmentList.length === 1){

          var tempShip = this.searchResults.find(x=> x.shipment === this.selectedShipmentList[0]);

          if(tempShip.printed !== false && tempShip.printDate !== null){

            var printdate = tempShip.printDate;
          console.log("=>printDate: " + printdate);

          //Getting Current Date from DB
        this._http.getDbDateTime().subscribe((results) => {
          let res = JSON.parse(JSON.stringify(results));
          this.dbDateTime =  res.date;
          //console.log("Databse Date Time");
          //console.log(this.dbDateTime);
          dbSplit = this.dbDateTime.split("T",2);
          dbtime = dbSplit[1].split(":",3);
          dbDateFlag = true;
          var minutesDiff = 0;
          var printDateSplit = printdate.split("T",2);
          var printDateTime = printDateSplit[1].split(":",3);
    
        if (dbDateFlag) {
          //if date and time (hour) matches the printed DateTime
        if ( dbSplit[0] == printDateSplit[0] && dbtime[0] == printDateTime[0]) {
          minutesDiff = dbtime[1] -  parseInt(printDateTime[1]); 
          //console.log("You printed " + minutesDiff + " minutes ago");
    
        if (minutesDiff >= 0 && minutesDiff <= 5 ) {
          const dialogRef = this.dialog.open(ReprintInfoComponentComponent, {
            width: '496px',
            data: payload,
            panelClass: 'custom-modal',
          });
        
         dialogRef.afterClosed().subscribe(result => {
          this.SpinnerService.hide(); 
          this.refreshSearch();
            //console.log('The reprint dialog was closed');
          });
        }else{
          //console.log("More than 5 minutes passed in last print.. you can reprint now");
          this.SpinnerService.show();
          this._http.reprintMultipleShipments(payload).subscribe((results) => {
            let obj = JSON.parse(JSON.stringify(results));
            if (obj.message === 'Success') {
             this.SpinnerService.hide();
             this.refreshSearch();
             this.notifier.notify("success", "Reprint request has been sent successfully!");
            }else{
              this.SpinnerService.hide();
              this.refreshSearch();
              this.notifier.notify("error", "Something went wrong with re-printing. Please try again!");
            }
          },
          err=>{
            this.SpinnerService.hide();
            this.notifier.notify("error", "Something went wrong with re-printing. Please try again!");
          })
        }
      }else{
        //The Print DateTime is older than Current DateTime
        //console.log("The Print DateTime is older than Current DateTime.... Can Reprint ");
        this.SpinnerService.show();
        this._http.reprintMultipleShipments(payload).subscribe((results) => {
          let obj = JSON.parse(JSON.stringify(results));
          if (obj.message === 'Success') {
           this.SpinnerService.hide();
           this.refreshSearch();
            this.notifier.notify("success", "Reprint request has been sent successfully!");
          }else{
            this.SpinnerService.hide();
            this.refreshSearch();
            this.notifier.notify("error", "Something went wrong with re-printing. Please try again!");
          }
        },
        err=>{
          this.SpinnerService.hide();
          this.notifier.notify("error", "Something went wrong with re-printing. Please try again!");
        })
      } 
        }else{
          //console.log("DB date Flag not set " + dbDateFlag);
        }
      },
        err=>{
          this.SpinnerService.hide();
          this.notifier.notify("error", "Something went wrong with fetching server DateTime, cannot Reprint. Please try again!");
        });

          }else{
            //do something
            this.notifier.notify("warning", "Please PRINT this shipment first!!");

          }
          

        }else if(this.selectedShipmentList.length > 1)
        {

        this.SpinnerService.show();
        this._http.reprintMultipleShipments(payload).subscribe((results) => {
          let obj = JSON.parse(JSON.stringify(results));
          if (obj.message === 'Success') {
           this.SpinnerService.hide();
           this.refreshSearch();
            this.notifier.notify("success", "Reprint request has been sent successfully!");
          }else{
            this.SpinnerService.hide();
            this.refreshSearch();
            this.notifier.notify("error", "Something went wrong with re-printing. Please try again!");
          }
        },
        err=>{
          this.SpinnerService.hide();
          this.notifier.notify("error", "Something went wrong with re-printing. Please try again!");
        })

        }
        
    }
        else{
          this.notifier.notify("warning", "Please choose a printer to proceed!");
        }
    }else{
      this.notifier.notify("warning", "Please select shipments to proceed!")
    }
    
}

  openLineHistoryDialog(workorder): void {
    const dialogRef = this.dialog.open(LineHistoryPopupComponent, {
      width: '592px',
      maxHeight: '372px',
      scrollStrategy: this.overlay.scrollStrategies.noop(),
      data: workorder,
      panelClass: 'lineHistoryPopup',
    });

    dialogRef.afterClosed().subscribe(result => {
     // console.log('The dialog was closed');
    });
  }

  openWoSerialDialog(workorder): void {
    const dialogRef = this.dialog.open(WoScannedSerialPopupComponent, {
      width: '592px',
      maxHeight: '372px',
      scrollStrategy: this.overlay.scrollStrategies.noop(),
      data: workorder,
      panelClass: 'lineHistoryPopup',
    });

    dialogRef.afterClosed().subscribe(result => {
     // console.log('The dialog was closed');
    });
  }


  openSpecificationDialog(workorder): void {
    const dialogRef = this.dialog.open(SpecificationPopupComponent, {
      width: '592px',
      maxHeight: '512px',
      scrollStrategy: this.overlay.scrollStrategies.noop(),
      data: workorder,
      panelClass: 'lineHistoryPopup',
    });

    dialogRef.afterClosed().subscribe(result => {
      //console.log('The dialog was closed');
    });
  }

  refreshSearch(){
    
    this.allSelected = false;
    this.wOSelected = false;
    this.selectedWorkOrderList = [];
    this.selectedShipmentList = [];
    this.selectedCompleteShipments = [];
    this.WoCount = 0;
    this.notFound = false;

    this.SpinnerService.show();
    this.searchlogic();

  }

  searchlogic(){

    this._http.searchByShipmentId(this.searchValue).subscribe((results)=>{
      let obj = JSON.parse(JSON.stringify(results));
      if(obj.length !== 0){
        this.WMSFlag = false;
        this.notFound = false;
        this.disableOptions = false;
          
          this.UIflag = false;
          for(let i = 0; i < obj.length; i++)
          {
            if(obj[i].searchRegion !== null)
            {
              if(this.region == "KOR" && obj[i].searchRegion == "CMP"){
                this.UIflag = false;
              }
              else if (obj[i].searchRegion !== this.region ){
                this.UIflag = true;
              }
              this.facility[i] = obj[i].searchRegion;
              this.urlImg[i] = '../../assets/images/';
              switch (obj[i].searchRegion) 
              {
                case 'TJA':
                  this.urlImg[i] += 'TJA.svg';
                  break;
          
                case 'BAS':
                  this.urlImg[i] += 'BAS.svg';
                  break;

                case 'BST':
                  this.urlImg[i] += 'BAS.svg';
                  break;
          
                case 'AUS':
                  this.urlImg[i] += 'AUS.svg';
                  break;
          
                case 'J21':
                  this.urlImg[i] += 'J21.svg';
                  break;
          
                case 'KOR':
                  this.urlImg[i] += 'KOR.svg';
                  break;

                case 'CMP':
                  this.urlImg[i] += 'KOR.svg';
                  break;
                
                default:       
                  this.urlImg[i] += 'TJA.svg';
                  break;
              }
              console.log(this.urlImg);
            }
              
          }
        
        if(this.UIflag)
        {
          this.disableView = true;
        }
        else{
          this.disableView = false;
        }

        //console.log(obj[0].searchRegion);
        //console.log(this.region);
        //console.log(this.facility);

      }
      else{
        this.disableView = false;
        this.notFound = true;
        this.disableOptions = true;
        this.selectedAction = "Export-Serials";
        this.WMSFlag = true;
        this.searchWMS();
      }   
      this.searchResults = results;

      console.log(this.searchResults);

      this.searchResults.forEach(shipment => {
        if(shipment.customerShipToNumber != '' && shipment.customerShipToNumber != null){
          shipment.customerShipToNumber = shipment.customerShipToNumber.split('-',2);
          }
        
          this.WoCount += shipment.workorders.length;            

      });
      this.SpinnerService.hide();

    },
    err=>{
      this.SpinnerService.hide();
      this.notifier.notify('error', 'There was a server error. Please try later!')
    },
    )  
  }



  searchShipmentById(event){
    if(event.keyCode === 13){

      this.allSelected = false;
      this.wOSelected = false;
      this.selectedWorkOrderList = [];
      this.selectedShipmentList = [];
      this.selectedCompleteShipments = [];
      this.WoCount = 0;
      this.notFound = false;

      this.SpinnerService.show();
      this.searchlogic();  
    }
  }

  searchWMS(){

    this.WMSMsg = "Checking";
    this.SpinnerService.show();
    this._http.searchWMS(this.searchValue).subscribe((result)=>{
      let response = JSON.parse(JSON.stringify(result));
      this.WMSMsg = response.message;

      this.SpinnerService.hide();
    },
    err=>{
      this.SpinnerService.hide();
      this.notifier.notify('error', 'There was a server error. Please try later!')
      this.WMSFlag = false;
    },
    )
  }

  clearResults(){
    this.disableOptions = true;
    this.selectedAction = "Export-Serials";
    this.allSelected = false;
    this.wOSelected = false;
    this.selectedWorkOrderList = [];
    this.selectedShipmentList = [];
    this.selectedCompleteShipments = [];
    this.WoCount = 0;
    this.notFound = false;
    this.WMSFlag = false;
    this.searchValue = '';
    this.searchResults = [];
    this.shipmentCancelled = false;
  }


  markShipmentUrgentAll(){
    
    let payload = [];

    this.searchResults.forEach(shipment => {
      
      if(shipment.printed === false && shipment.workorders.length > 0){

        payload.push(shipment.shipment);

      }      
    });

    if(payload.length > 0){

      this._http.markUrgentMultipleShipments(payload).subscribe((results)=>{
        let obj = JSON.parse(JSON.stringify(results));
        if(obj.message === "Success"){
          this.notifier.notify("success", "un printed shipments has been prioritized successfully!");
          this.refreshSearch();
        }
      },
      err=>{
        this.notifier.notify('error', 'There was a server error. Please try later!')
      }
      )

    }

  }

  markShipmentUrgentSelected(){


    if(this.selectedShipmentList.length > 0)
    {
      let payload = [];

      this.selectedCompleteShipments.forEach(shipment => {
        
        if(shipment.printed === false && shipment.workorders.length > 0){
  
          payload.push(shipment.shipment);
  
        }else{
          this.notifier.notify('warning',"Cannot prioritize a printed shipment: " + shipment.shipment )
        }      
      });
  
      if(payload.length > 0){
  
        this._http.markUrgentMultipleShipments(payload).subscribe((results)=>{
          let obj = JSON.parse(JSON.stringify(results));
          if(obj.message === "Success"){
            this.notifier.notify("success", "un printed shipments has been prioritized successfully!!");
            this.refreshSearch();
          }
        },
        err=>{
          this.notifier.notify('error', 'There was a server error. Please try later!')
        }
        )
  
      }
  }else{
    this.notifier.notify('warning', 'Please select a shipment to proceed!!');
  }
  }

  markShipmentnotUrgentAll(){
    
    let payload = [];

    this.searchResults.forEach(shipment => {
      
      if(shipment.printed === false && shipment.workorders.length > 0){

        payload.push(shipment.shipment);

      }      
    });

    if(payload.length > 0){

      this._http.marknotUrgentMultipleShipments(payload).subscribe((results)=>{
        let obj = JSON.parse(JSON.stringify(results));
        if(obj.message === "Success"){
          this.notifier.notify("success", "un printed shipments has been un-prioritized successfully!");
          this.refreshSearch();
        }
      },
      err=>{
        this.notifier.notify('error', 'There was a server error. Please try later!')
      }
      )

    }

  }

  markShipmentnotUrgentSelected(){

    if(this.selectedShipmentList.length > 0)
    {
      let payload = [];

      this.selectedCompleteShipments.forEach(shipment => {
        
        if(shipment.printed === false && shipment.workorders.length > 0){
  
          payload.push(shipment.shipment);
  
        }else{
          this.notifier.notify('warning',"Cannot un-prioritize a printed shipment: " + shipment.shipment )
        }      
      });
  
      if(payload.length > 0){
  
        this._http.marknotUrgentMultipleShipments(payload).subscribe((results)=>{
          let obj = JSON.parse(JSON.stringify(results));
          if(obj.message === "Success"){
            this.notifier.notify("success", "un printed shipments has been un-prioritized successfully!!");
            this.refreshSearch();
          }
        },
        err=>{
          this.notifier.notify('error', 'There was a server error. Please try later!')
        }
        )
  
      }
  }else{
    this.notifier.notify('warning', 'Please select a shipment to proceed!!');
  }
  }


  updateComment(shipmentID: any){

    if(this.specialInstructions !== ''){
      let payload = {
        shipmentid: shipmentID,
        comment: this.specialInstructions
      }
          this._http.updateShipment(payload).subscribe((results)=>{
        let obj = JSON.parse(JSON.stringify(results));
        if(obj.message === "Success"){
          this.specialInstructions = "";
          this.notifier.notify("success", "Special Instructions have been sent successfully!");
          this.refreshSearch();
        }
      },
      err=>{
        this.notifier.notify('error', 'There was a server error. Please try later!')
      })
    }else{
      this.notifier.notify('warning', 'Please Enter a comment for Shipment: ' + shipmentID);
    }
 
  }

  closelAllShipments(){

    let payload : cancelPayload = new cancelPayload();
    payload.type = "shipment";

    this.searchResults.forEach(shipment => {
      
        payload.data.push(shipment.shipment);
    });

      const dialogRef = this.dialog.open(CloseOrderPopupComponent,{
        data: payload
      });
  
      dialogRef.afterClosed().subscribe(result => {
        if(result.event == 'Close'){
          setTimeout(() => {
            this.refreshSearch()
          }, 1000);
        }
  
        else if(result.event == 'closed')
        setTimeout(() => {
          this.refreshSearch()
        }, 1000);
      });

  }

  openCloseShipmentPopup(){

    var openPopup = false;   
    let payload : cancelPayload = new cancelPayload();

    if(this.selectedShipmentList.length > 0){

      this.selectedShipmentList.forEach(element => {        
        payload.data.push(element);
      });
      
      if(payload.data.length > 0){
        openPopup = true;
        payload.type = "shipment";
      }
      

    }else if(this.selectedWorkOrderList.length > 0){

      this.selectedWorkOrderList.forEach(element => {
        payload.data.push(element);
      });

      if(payload.data.length > 0){
        openPopup = true; 
        payload.type = "workOrder";
      }
      

    }else{
      this.notifier.notify('warning', 'Please select shipments or work orders to proceed!!');
    }

    if( (this.selectedShipmentList.length > 0 || this.selectedWorkOrderList.length > 0) && payload != null && openPopup){

      const dialogRef = this.dialog.open(CloseOrderPopupComponent,{
        data: payload
      });
  
      dialogRef.afterClosed().subscribe(result => {
        if(result.event == 'Close'){
          setTimeout(() => {
            this.refreshSearch()
          }, 1000);
        }
  
        else if(result.event == 'Closed')
        setTimeout(() => {
          this.refreshSearch()
        }, 1000);
      });

    }
  }
  

  openCancelShipment(){

    var openPopup = false;   
    let payload : cancelPayload = new cancelPayload();

    if(this.selectedShipmentList.length > 0){

      this.selectedShipmentList.forEach(element => {

        //var tempShipment = this.searchResults.find(x => x.shipment === element);
        
        payload.data.push(element);


        // if(tempShipment.printed === false && tempShipment.workorders.length > 0){

        //   payload.data.push(element);
        // }else{
        //   this.notifier.notify('warning',"Cannot cancel a printed shipment: " + element);
        // }

      });
      
      if(payload.data.length > 0){
        openPopup = true;
        payload.type = "shipment";
      }
      

    }else if(this.selectedWorkOrderList.length > 0){

      this.selectedWorkOrderList.forEach(element => {

        //var tempShipment = this.searchResults.find(x => x.workorders.find(w => w.workorder === element));

        payload.data.push(element);


        // if(tempShipment.printed === false && tempShipment.workorders.length > 0){
        //   payload.data.push(element);
        // }else{
        //   this.notifier.notify('warning',"Cannot cancel a printed work order: " + element);
        // }

      });

      if(payload.data.length > 0){
        openPopup = true; 
        payload.type = "workOrder";
      }
      

    }else{
      this.notifier.notify('warning', 'Please select shipments or work orders to proceed!!');
    }

    if( (this.selectedShipmentList.length > 0 || this.selectedWorkOrderList.length > 0) && payload != null && openPopup){

      const dialogRef = this.dialog.open(CancelShipmentPopupComponent,{
        data: payload
      });
  
      dialogRef.afterClosed().subscribe(result => {
        if(result.event == 'Close'){
          setTimeout(() => {
            this.refreshSearch()
          }, 1000);
        }
  
        else if(result.event == 'Cancelled')
        setTimeout(() => {
          this.refreshSearch()
        }, 1000);
      });

    }
  }

  cancellAllShipments(){

    let payload : cancelPayload = new cancelPayload();
    payload.type = "shipment";

    this.searchResults.forEach(shipment => {
      
      //if(shipment.printed === false && shipment.workorders.length > 0){

        payload.data.push(shipment.shipment);

      //}      
    });

   // if(payload.data.length > 0){

      const dialogRef = this.dialog.open(CancelShipmentPopupComponent,{
        data: payload
      });
  
      dialogRef.afterClosed().subscribe(result => {
        if(result.event == 'Close'){
          setTimeout(() => {
            this.refreshSearch()
          }, 1000);
        }
  
        else if(result.event == 'Cancelled')
        setTimeout(() => {
          this.refreshSearch()
        }, 1000);
      });

    // }else{
    //   this.notifier.notify('warning', 'No un-printed shipment found for cancellation!!');
    // }

  }

  exportSerials(){

    this._http.getAllSerials().subscribe((results)=>{

      let obj = JSON.parse(JSON.stringify(results));
      if(obj.length != 0){
        this.downloadFile(obj, 'Serials_Data');
      }
    },
    err=>{
      this.notifier.notify('error', 'There was a server error. Please try later!');
    })
  }

  downloadFile(data: any, filename='data') {

    let csvData = this.ConvertToCSV(data, ['id','workOrder', 'sku', 'componentDescription', 'scannedSerialCode','scannedOn','stationId', 'userId', 'isVerified', 'verifiedOn','activeFlag','parentId']);
    let blob = new Blob(['\ufeff' + csvData], { type: 'text/csv;charset=utf-8;' });
    let dwldLink = document.createElement("a");
    let url = URL.createObjectURL(blob);
    dwldLink.setAttribute("href", url);
    dwldLink.setAttribute("download", filename + ".csv");
    dwldLink.style.visibility = "hidden";
    document.body.appendChild(dwldLink);
    dwldLink.click();
    document.body.removeChild(dwldLink);
    this.notifier.notify('success', 'Downloading All Serial Numbers');   
}

ConvertToCSV(objArray, headerList) {
     let array = typeof objArray != 'object' ? JSON.parse(objArray) : objArray;
     let str = '';
     let row = 'S.No,';

     for (let index in headerList) {
         row += headerList[index] + ',';
     }
     row = row.slice(0, -1);
     str += row + '\r\n';
     for (let i = 0; i < array.length; i++) {
         let line = (i+1)+'';
         for (let index in headerList) {
            let head = headerList[index];

             line += ',' + array[i][head];
         }
         str += line + '\r\n';
     }
     return str;
 }




}





/*  old version of search component class
export class SearchComponent implements OnInit {

  displayedColumns: string[] = ['station', 'dateTime', 'user', 'action'];
  dataSource: any = [
    {station: 'Picking', dateTime:'3/20/2020 15:50', user: 'Joe Willis'}
  ];


  selectedPrinter: string = '';
  printers: any = [];
  searchResults: any = [];
  searchValue: string = ''; 
  specialInstructions: string = '';
  printedNotScanned = [];
  isSearch: boolean = true;
  isScanned: boolean = false;
  specifications: any = [];
  shipmentCancelled: boolean = false;
  selectedLang: string = '';
  dbDateTime: string;
  stName: string = '';
  stNum: string = '';


  private readonly notifier: NotifierService;
  
  constructor(
    public dialog: MatDialog,
    public overlay: Overlay,
    private _http: HttpService,
    notifierService: NotifierService,
    public translate: TranslateService,
    private route: ActivatedRoute,
    private SpinnerService: NgxSpinnerService
    
  ) { 
    translate.addLangs(['en', 'es', 'ja']);
    translate.setDefaultLang('en');
    this.notifier = notifierService;
  }

  ngOnInit(): void {
    this.notifier.hideNewest();
    this.selectedLang = this.route.snapshot.queryParams['lang'];

    if(this.selectedLang !== undefined){
      this.translate.use(this.selectedLang)
    }

    this.getPrinters();
  }

  getPrinters() {
    this._http.getPrintersDropdownData().subscribe((results) => {
      this.printers = results;
    },
      err => {
        this.SpinnerService.hide();
        this.notifier.notify('error', 'There was a server error. Please try later!')
      });
  }

  reprintShipment() {
    var dbtime;
    var dbSplit;
    var dbDateFlag = false;
    var printdate = this.searchResults.printDate;
    
    if(this.selectedPrinter !== ""){
    
    //create payload for reprint
    let payload = {
      id: this.searchResults.shipment,
      viaWarePrinter: this.selectedPrinter,
      site: $ENV.SITE_ABBREVIATION,
    }

    //Getting Current Date from DB
    this._http.getDbDateTime().subscribe((results) => {
      let res = JSON.parse(JSON.stringify(results));
      this.dbDateTime =  res.date;
      //console.log("Databse Date Time");
      //console.log(this.dbDateTime);
      dbSplit = this.dbDateTime.split("T",2);
      dbtime = dbSplit[1].split(":",3);
      dbDateFlag = true;
      var minutesDiff = 0;
      var printDateSplit = printdate.split("T",2);
      var printDateTime = printDateSplit[1].split(":",3);

    if (dbDateFlag) {
      //if date and time (hour) matches the printed DateTime
    if ( dbSplit[0] == printDateSplit[0] && dbtime[0] == printDateTime[0]) {
      minutesDiff = dbtime[1] -  parseInt(printDateTime[1]); 
      //console.log("You printed " + minutesDiff + " minutes ago");

    if (minutesDiff >= 0 && minutesDiff <= 5 ) {
      const dialogRef = this.dialog.open(ReprintInfoComponentComponent, {
        width: '496px',
        data: payload,
        panelClass: 'custom-modal',
      });
    
     dialogRef.afterClosed().subscribe(result => {
      this.SpinnerService.hide(); 
      this.refreshSearch();
        //console.log('The reprint dialog was closed');
      });
    }else{
      //console.log("More than 5 minutes passed in last print.. you can reprint now");
      this.SpinnerService.show();
      this._http.reprintShipment(payload).subscribe((results) => {
        let obj = JSON.parse(JSON.stringify(results));
        if (obj.message === 'Success') {
         this.SpinnerService.hide();
         this.refreshSearch();
         this.notifier.notify("success", "Reprint request has been sent successfully!");
        }else{
          this.SpinnerService.hide();
          this.refreshSearch();
          this.notifier.notify("error", "Something went wrong with re-printing. Please try again!");
        }
      },
      err=>{
        this.SpinnerService.hide();
        this.notifier.notify("error", "Something went wrong with re-printing. Please try again!");
      })
    }
  }else{
    //The Print DateTime is older than Current DateTime
    //console.log("The Print DateTime is older than Current DateTime.... Can Reprint ");
    this.SpinnerService.show();
    this._http.reprintShipment(payload).subscribe((results) => {
      let obj = JSON.parse(JSON.stringify(results));
      if (obj.message === 'Success') {
       this.SpinnerService.hide();
       this.refreshSearch();
        this.notifier.notify("success", "Reprint request has been sent successfully!");
      }else{
        this.SpinnerService.hide();
        this.refreshSearch();
        this.notifier.notify("error", "Something went wrong with re-printing. Please try again!");
      }
    },
    err=>{
      this.SpinnerService.hide();
      this.notifier.notify("error", "Something went wrong with re-printing. Please try again!");
    })
  } 
    }else{
      //console.log("DB date Flag not set " + dbDateFlag);
    }
  },
    err=>{
      this.SpinnerService.hide();
      this.notifier.notify("error", "Something went wrong with fetching server DateTime, cannot Reprint. Please try again!");
    });
    
}
    else{
      this.notifier.notify("warning", "Please choose a printer to proceed!");
    }
}

  openLineHistoryDialog(workorder): void {
    const dialogRef = this.dialog.open(LineHistoryPopupComponent, {
      width: '592px',
      maxHeight: '372px',
      scrollStrategy: this.overlay.scrollStrategies.noop(),
      data: workorder,
      panelClass: 'lineHistoryPopup',
    });

    dialogRef.afterClosed().subscribe(result => {
     // console.log('The dialog was closed');
    });
  }

  openWoSerialDialog(workorder): void {
    const dialogRef = this.dialog.open(WoScannedSerialPopupComponent, {
      width: '592px',
      maxHeight: '372px',
      scrollStrategy: this.overlay.scrollStrategies.noop(),
      data: workorder,
      panelClass: 'lineHistoryPopup',
    });

    dialogRef.afterClosed().subscribe(result => {
     // console.log('The dialog was closed');
    });
  }


  openSpecificationDialog(workorder): void {
    const dialogRef = this.dialog.open(SpecificationPopupComponent, {
      width: '592px',
      maxHeight: '512px',
      scrollStrategy: this.overlay.scrollStrategies.noop(),
      data: workorder,
      panelClass: 'lineHistoryPopup',
    });

    dialogRef.afterClosed().subscribe(result => {
      //console.log('The dialog was closed');
    });
  }

  refreshSearch(){

    this._http.searchByShipmentId(this.searchValue).subscribe((results)=>{
      let obj = JSON.parse(JSON.stringify(results));
     
      this.searchResults = results;
      this.specialInstructions = obj.comments;
      var temp = this.searchResults.customerShipToNumber.split('-',2);
        this.stNum = temp[0];
        this.stName = temp[1];
    },
    err=>{
      this.notifier.notify('error', 'There was a server error. Please try later!')
    },
    ) 

  }



  searchShipmentById(event){
    if(event.keyCode === 13){
      this._http.searchByShipmentId(this.searchValue).subscribe((results)=>{
        let obj = JSON.parse(JSON.stringify(results));
        console.log(results);
        this.searchResults = results;
        this.specialInstructions = obj.comments;
        var temp = this.searchResults.customerShipToNumber.split('-',2);
        this.stNum = temp[0];
        this.stName = temp[1];

      },
      err=>{
        this.notifier.notify('error', 'There was a server error. Please try later!')
      },
      )  
    }
  }

  clearResults(){
    this.searchValue = '';
    this.searchResults = [];
    this.shipmentCancelled = false;
  }

  cancelShipment(){
    this._http.cancelShipment(this.searchResults.shipment).subscribe((results)=>{
      let obj = JSON.parse(JSON.stringify(results));
      if(obj.message === "Success"){
        this.notifier.notify("success", "Shipment has been canceled successfully!");
        this.clearResults();
      }
      else{
        this.notifier.notify("error", "Unable to cancel shipment!");
      }
    })
  }

  markShipmentUrgent(){
    this._http.markShipmentUrgent(this.searchResults.shipment).subscribe((results)=>{
      let obj = JSON.parse(JSON.stringify(results));
      if(obj.message === "Success"){
        this.notifier.notify("success", "Shipment has been marked urgent successfully!");
      }
    },
    err=>{
      this.notifier.notify('error', 'There was a server error. Please try later!')
    }
    )
  }

  updateComment(){
    let payload = {
      shipmentid: this.searchResults.shipment,
      comment: this.specialInstructions
    }
    this._http.updateShipment(payload).subscribe((results)=>{
      let obj = JSON.parse(JSON.stringify(results));
      if(obj.message === "Success"){
        this.notifier.notify("success", "Special Instructions have been sent successfully!");
      }
    },
    err=>{
      this.notifier.notify('error', 'There was a server error. Please try later!')
    })
  }

  openCancelShipment(){
    const dialogRef = this.dialog.open(CancelShipmentPopupComponent,{
      data: this.searchResults.shipment
    });

    dialogRef.afterClosed().subscribe(result => {
      if(result.event == 'Close'){
        this.shipmentCancelled = result.data;
      }

      else if(result.event == 'Cancelled')
      this.shipmentCancelled = result.data;
    });
  }

  exportSerials(){

    this._http.getAllSerials().subscribe((results)=>{

      let obj = JSON.parse(JSON.stringify(results));
      console.log(obj);
      if(obj.length != 0){
        this.downloadFile(obj, 'Serials_Data');
      }
    },
    err=>{
      this.notifier.notify('error', 'There was a server error. Please try later!');
    })
  }

  downloadFile(data: any, filename='data') {

    let csvData = this.ConvertToCSV(data, ['id','workOrder', 'sku', 'componentDescription', 'scannedSerialCode','scannedOn','stationId', 'userId', 'isVerified', 'verifiedOn','activeFlag','parentId']);
    let blob = new Blob(['\ufeff' + csvData], { type: 'text/csv;charset=utf-8;' });
    let dwldLink = document.createElement("a");
    let url = URL.createObjectURL(blob);
    dwldLink.setAttribute("href", url);
    dwldLink.setAttribute("download", filename + ".csv");
    dwldLink.style.visibility = "hidden";
    document.body.appendChild(dwldLink);
    dwldLink.click();
    document.body.removeChild(dwldLink);
    this.notifier.notify('success', 'Downloading All Serial Numbers');   
}

ConvertToCSV(objArray, headerList) {
     let array = typeof objArray != 'object' ? JSON.parse(objArray) : objArray;
     let str = '';
     let row = 'S.No,';

     for (let index in headerList) {
         row += headerList[index] + ',';
     }
     row = row.slice(0, -1);
     str += row + '\r\n';
     for (let i = 0; i < array.length; i++) {
         let line = (i+1)+'';
         for (let index in headerList) {
            let head = headerList[index];

             line += ',' + array[i][head];
         }
         str += line + '\r\n';
     }
     return str;
 }




}
*/
