

<div class="mt-32">
<!-- <span id="example-radio-group-label">{{'Sort'|translate}}</span>
<br>
<div class="mt-16">
  <mat-radio-group (change)="updateGlobalSetup()" [(ngModel)]="sortValue">
    <mat-radio-button [value]=true >FIFO</mat-radio-button>
    <mat-radio-button class="ml-16" [value]=false >Value</mat-radio-button>
  </mat-radio-group>
</div> -->

</div>

<div class="managementlayout mb-24">

  <ng-container *ngIf="this.rows.length != 0">
<form [formGroup]="form" class="mt-16" *ngIf="this.rows.length != 0" id="line-management-form">
  <table mat-table [dataSource]="dataSource" formArrayName="lines" style="background: none;" id="line-management-table">
    <!-- Row definitions -->
    <tr mat-header-row *matHeaderRowDef="displayColumns"></tr>
    <tr mat-row *matRowDef="let row; let i = index; columns: displayColumns;"></tr>

    <!-- Column definitions -->
    <ng-container matColumnDef="title">
      <th mat-header-cell *matHeaderCellDef id="Line">{{'Line'|translate}}</th>
      <td mat-cell *matCellDef="let row; let index = index"> 
        {{formControl.lines.value[index].title}}
      </td>
    </ng-container>

    <ng-container matColumnDef="manufacturingType">
      <th mat-header-cell *matHeaderCellDef id="manufacturingType">{{'ManufacturingType'|translate}}</th>
      <td mat-cell *matCellDef="let row; let index = index" [formGroupName]="index">
        <mat-form-field class="inputStyle pl-8">
          <mat-select formControlName="manufacturingType" (selectionChange)="updateManufacturingType(formControl.lines.value[index].id, index)">
            <mat-option  *ngFor="let item of manufacturingTypeData" [value]="item.title">{{item.title}}</mat-option>
          </mat-select>
        </mat-form-field>


      </td>
    </ng-container>

    <ng-container  matColumnDef="maxCapacity">
      <th mat-header-cell *matHeaderCellDef id="maxCapacity"> {{'Capacity'|translate}} </th>
      <td mat-cell *matCellDef="let row; let index = index"  [formGroupName]="index"> 
        <button class="mr-16 alignSelfCenter" mat-stroked-button  (click)="openCapacityDialog(formControl.lines.value[index].id, formControl.lines.value[index].title, formControl.lines.value[index].manufacturingType)">
          <span class="material-icons mr-8">zoom_out_map</span>
          {{'Capacity'|translate}}
        </button>
      </td>
    </ng-container>

    <ng-container  matColumnDef="isActive">
      <th mat-header-cell *matHeaderCellDef id="status"> {{'Status'|translate}} </th>
      <td class="pr-32" mat-cell *matCellDef="let row; let index = index"  [formGroupName]="index"> 
        <div>
        {{formControl.lines.value[index].isActive === true ? ('Active'|translate) : ('Inactive'|translate)}}
      </div>
      </td>
    </ng-container>

    <ng-container matColumnDef="action">
      <th mat-header-cell *matHeaderCellDef id="Action"> {{'Action'|translate}} </th>
      <td mat-cell *matCellDef="let row; let index = index"  [formGroupName]="index">
        
        <button *ngIf="formControl.lines.value[index].isCustom" class="mr-16 alignSelfCenter" id="CustomSettings" mat-stroked-button  (click)="openCustomSettingsDialog(formControl.lines.value[index].id)">
          <span class="material-icons mr-8">settings</span>
          {{'CustomSettings'|translate}}
        </button> 
        <button *ngIf="!formControl.lines.value[index].isCustom" class="mr-16 alignSelfCenter" id="defaultSettings" mat-stroked-button (click)="openDefaultSettingsDialog(formControl.lines.value[index].id)">
          <span class="material-icons mr-8">settings</span>
          {{'Default'|translate}} {{'Settings'|translate}}
        </button> 
        <button [disabled]="isProcessing" mat-raised-button *ngIf="formControl.lines.value[index].isActive===true" color="warn" (click)="changeStatus(false , formControl.lines.value[index].id)" id="deactivate">{{'Deactivate'|translate}}</button>
        <button [disabled]="isProcessing" mat-raised-button class="successColor" *ngIf="formControl.lines.value[index].isActive!==true" (click)="changeStatus(true , formControl.lines.value[index].id)" style="width: 98px;" id="activate">{{'Activate'|translate}}</button>
      </td>
    </ng-container>


  </table>

</form>
</ng-container>

</div>


<ngx-spinner bdColor="rgba(51, 51, 51, 0.8)" size="default" type="ball-spin-clockwise">  
  <p style="color: white">Please Wait. </p>  
  </ngx-spinner> 
