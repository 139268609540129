import { Component, OnInit, Inject } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { FormBuilder, FormArray, FormGroup } from '@angular/forms';
import { HttpService } from '../http.service';
import { Overlay } from '@angular/cdk/overlay';
import { NotifierService } from "angular-notifier";
import { isNull } from '@angular/compiler/src/output/output_ast';
import { NgxSpinnerService } from 'ngx-spinner';
import { stringify } from '@angular/compiler/src/util';


@Component({
  selector: 'app-line-setup-upddate-popup',
  templateUrl: './line-setup-upddate-popup.component.html',
  styleUrls: ['./line-setup-upddate-popup.component.scss']
})
export class LineSetupUpddatePopupComponent implements OnInit {

  private readonly notifier: NotifierService;
  parsedData: any;

 
  constructor(private fb: FormBuilder,
    private _http: HttpService,
    notifierService: NotifierService,
    private SpinnerService: NgxSpinnerService,
    public dialogRef: MatDialogRef<LineSetupUpddatePopupComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any) { 
      this.notifier = notifierService;
      this.parsedData = data;
  }

  ngOnInit(): void {
  
  }

  saveLineSetupValues(){

    let payload = {
      "id": this.parsedData.id,
      "title": this.parsedData.title,
      "lineSetupID": this.parsedData.lineSetupID,
      "keyName": this.parsedData.keyName,
      "keyNameFG": this.parsedData.keyNameFG,
      "Active": true
    }
    
    if (this.parsedData != null) {

      if ((this.parsedData.keyName != null && this.parsedData.keyName !== "") &&
        (this.parsedData.title != null && this.parsedData.title !== "")){

       this.SpinnerService.show();
      this._http.updateLineSetupValue(payload).subscribe((results)=>{
        if(results){
          this.notifier.notify('success', 'Line Setup have been updated successfully!');
          this.SpinnerService.hide();
          this.dialogRef.close;
        }
      },
      err => {
        this.SpinnerService.hide();
        this.notifier.notify('error', 'There was a server error. Please try later!')
      });
    }

    else
      this.notifier.notify('warning', `Line Setup can't be left empty!`);
      this.dialogRef.close;
    
     }
      
  }

  onNoClick(): void {
    this.dialogRef.close();
  }
}

