import { Component, OnInit, Inject } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { AbstractControl, FormGroup, FormControl, FormBuilder, Validators, FormArray } from '@angular/forms';
import { TableData } from './line-management-table';
import { BehaviorSubject, Observable } from 'rxjs';
import { CustomSettingsPopupComponent } from '../custom-settings-popup/custom-settings-popup.component';
import { CapacityPopupComponent } from '../capacity-popup/capacity-popup.component';
import { HttpService } from '../http.service';
import { Overlay } from '@angular/cdk/overlay';
import { throws } from 'assert';
import { NgxSpinnerService } from "ngx-spinner";
import { NotifierService } from "angular-notifier";
import { ActivatedRoute } from '@angular/router';
import { environment } from 'src/environments/environment';
import { TranslateService } from '@ngx-translate/core';



@Component({
  selector: 'app-line-management',
  templateUrl: './line-management.component.html',
  styleUrls: ['./line-management.component.scss']
})




export class LineManagementComponent implements OnInit {

  data: any = [];
  customSettingData: any = [];
  defaultSettingData: any = [];
  manufacturingTypeData: any = [];
  sortValue: Boolean;
  isProcessing: Boolean = false;
  selectedLang: string = '';

  dataSource = new BehaviorSubject<AbstractControl[]>([]);
  displayColumns = ['title', 'manufacturingType', 'maxCapacity', 'isActive', 'action'];
  rows: FormArray = this.fb.array([]);
  form: FormGroup = this.fb.group({ 'lines': this.rows });
  formControl: any;

  private readonly notifier: NotifierService;

  constructor(
    notifierService: NotifierService,
    private fb: FormBuilder,
    public translate: TranslateService,
    public dialog: MatDialog,
    public overlay: Overlay,
    private _http: HttpService,
    private route: ActivatedRoute,
    private SpinnerService: NgxSpinnerService) {
    this.notifier = notifierService;
    this.formControl = this.form.controls;

    translate.addLangs(['en', 'es', 'ja']);
    translate.setDefaultLang('en');
    
  }

  ngOnInit() {
    this.selectedLang = this.route.snapshot.queryParams['lang'];
    
    if(this.route.snapshot.queryParams['lineId'] != undefined)
    {
      this.openCustomSettingsDialog(this.route.snapshot.queryParams['lineId']);
    } 
    
    
    if(this.selectedLang !== undefined){
      this.translate.use(this.selectedLang)
    }
    
    this.notifier.hideNewest();
    this.getGlobalSetup();
    this.getManufacturingData();
  }

  getTableData()  {
        this.SpinnerService.show();
        this._http.getLines(this.sortValue).subscribe(
          (data) => {
          this.data = [];
          this.data = data;
          this.data.forEach((d: TableData) => this.addRow(d, false));
          this.updateView();
          this.SpinnerService.hide();
        },
        err=>{
          this.SpinnerService.hide();
          this.notifier.notify('error', 'There was a server error. Please try later!')
        }
        );    
  }

  emptyTable() {
    while (this.rows.length !== 0) {
      this.rows.removeAt(0);
    }

    if(this.rows.length === 0){
      return true;
    }

    else{
      false
    }

  }

  getManufacturingData() {
    this._http.getManufacturingTypeData().subscribe(result => {
      this.manufacturingTypeData = result;
    },
    err=>{
      this.notifier.notify('error', 'There was a server error. Please try later!')
    }
    )
  }

  getGlobalSetup() {
    this._http.getGlobalSetupValues().subscribe((results) => {
      let obj = JSON.parse(JSON.stringify(results));
      this.sortValue = obj[0].fifo;
      this.getTableData();
    })
  }

  // updateGlobalSetup() {

  //   this._http.updateGlobalValues(this.sortValue).subscribe((results) => {
  //     let isEmpty = this.emptyTable();
  //     if(isEmpty)
  //       this.updateTable();
  //   },
  //   err=>{
  //     this.notifier.notify('error', 'There was a server error. Please try later!')
  //   });
  // }

  updateManufacturingType(id, index) {
    let query = this.rows.value[index].manufacturingType;
    let results = this.manufacturingTypeData.filter(obj => {
      return obj.title === query
    })


    let payload = {
      "cellId": id,
      "cellTypeID": results[0].id
    }

    this._http.updateManufacturingType((payload)).subscribe(result => {
      console.log(result)
    },
    err=>{
      this.notifier.notify('error', 'There was a server error. Please try later!')
    });
  }


  updateTable() {
    this.SpinnerService.show();
    this._http.getLines(this.sortValue).subscribe(data => {
      this.data = data;
      this.data.forEach((d: TableData) => this.addRow(d, false));
      this.updateView();
      this.SpinnerService.hide();
    },
    err=>{
      this.SpinnerService.hide();
      this.notifier.notify('error', 'There was a server error. Please try later!')
    });
  }


  changeStatus(status, id) {
    this.isProcessing = true;
    this._http.activateLines(id, status).subscribe(data => {
      this.emptyTable();
      this.isProcessing = false;
      this.updateTable();
    },
    err=>{
      this.SpinnerService.hide();
      this.notifier.notify('error', 'There was a server error. Please try later!')
    });
  }


  saveData() {
    console.log(this.rows);
  }


  addRow(d?: TableData, noUpdate?: boolean) {

    const row = this.fb.group({
      'id': [d && d.id ? d.id : null, []],
      'title': [d && d.title ? d.title : null, []],
      'manufacturingType': [d && d.manufacturingType ? d.manufacturingType : null, []],
      'isCustom': [d && d.isCustom ? d.isCustom : false, []],
      'maxCapacity': [d && d.maxCapacity ? d.maxCapacity : null, []],
      'isActive': [d && d.isActive ? d.isActive : false, []],
    });

    this.rows.push(row);
    if (!noUpdate) { this.updateView(); }
  }

  updateView() {
    this.dataSource.next(this.rows.controls);
  }


  openCustomSettingsDialog(id): void {

    this._http.getCustomSettings(id).subscribe(data => {
      this.customSettingData = data;
      this.customSettingData.id = parseInt(id);
      const dialogRef = this.dialog.open(CustomSettingsPopupComponent, {
        width: '496px',
        scrollStrategy: this.overlay.scrollStrategies.noop(),
        data: this.customSettingData,
        panelClass: 'custom-modal',
      });

      dialogRef.afterClosed().subscribe(result => {
        console.log('The dialog was closed');
      });
    })
  }

  openDefaultSettingsDialog(id): void {

    this._http.getDefaultSettings().subscribe(data => {
      this.defaultSettingData = data;
      this.defaultSettingData.id = id;
      const dialogRef = this.dialog.open(CustomSettingsPopupComponent, {
        width: '496px',
        scrollStrategy: this.overlay.scrollStrategies.noop(),
        data: this.defaultSettingData,
        panelClass: 'custom-modal',
      });

      dialogRef.afterClosed().subscribe(result => {
        console.log('The dialog was closed');
      });
    })
  }

  openCapacityDialog(id, line, manufacturingType): void {
    let obj = {id, line, manufacturingType};
    const dialogRef = this.dialog.open(CapacityPopupComponent, {
      width: '496px',
      scrollStrategy: this.overlay.scrollStrategies.noop(),
      data: obj,
      panelClass: 'custom-modal',
    });

    dialogRef.afterClosed().subscribe(result => {
      console.log('The dialog was closed');
    });
  }

}

